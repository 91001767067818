import { useEffect, useRef, useMemo, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import ReadOnlyEditor from 'react-froala-wysiwyg/FroalaEditorView';
import PropTypes from 'prop-types';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { editorActionData } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { HAS_HEADER } from 'features/resolutionPanel/constants';
import { RICH_TEXT_EDITOR_TYPES, DEFAULT_CONFIG, TAG_NAMES } from './constants';
import { useInitialContent } from './hooks/messageHooks';
import useAttachmentControls from './hooks/useAttachmentControls';
import Attachments from './attachments';
import RichTextEditor from './RichTextEditor';
import { StyledGridContainer, StyledRichTextEditorWrapper } from './styles';
import FilesPreview from '../../thumbnail/FilesPreview';
import { createAttachmentDataFromImage, updateSrcDomain } from './utils';

import 'tributejs/dist/tribute.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const RichTextWrapper = forwardRef((props, ref) => {
  const {
    sourceId,
    type,
    disabled,
    editorText,
    attachmentsList,
    isReadOnly,
    shouldRenderDefaultUI,
    customDefaultUI,
    shouldTriggerDeleteApi,
    activeUploads,
    onChangeActiveUploads,
    isExpanded,
    isFullSize,
    isRichTextTruncated,
    onImageUpload,
    onFileAttach,
    onAttachmentDeleted,
    onChangeUploadImageProgress,
    onChangeUploadFileProgress,
    editorType,
    deleteAttachmentEnabled,
    dataTestId,
    editorElementsClassName,
    shouldShowAttachments = true,
  } = props;

  const configMap = {
    [RICH_TEXT_EDITOR_TYPES.TEXT]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile'],
    },
    [RICH_TEXT_EDITOR_TYPES.EMAIL]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile', 'cannedResponses'],
    },
    [RICH_TEXT_EDITOR_TYPES.TABLE]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention'],
    },
    [RICH_TEXT_EDITOR_TYPES.TEMPLATE]: {
      toolbarButtons: ['insertLink', 'mention'],
    },
  };

  const { toolbarButtons = [] } = configMap[type];
  const isAttachmentsEnabled = ['insertImage', 'insertFile'].some((feature) => toolbarButtons.includes(feature));
  const editorElementClassName = editorActionData[editorType]?.editorElementClassName || 'editor-element';
  const isTemplatePage = type === RICH_TEXT_EDITOR_TYPES.TEMPLATE;

  const editorWrapRef = useRef();
  const richTextEditorRef = useRef(null);
  const editorRef = useRef(null);
  const readOnlyEditorRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      richTextEditorRef.current?.focus();
    },
  }));

  const [imagePreviewConfig, setImagePreviewConfig] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [editorId, setEditorId] = useState('');
  const [isUploadFromMenuOpened, setIsUploadFromMenuOpened] = useState(false);
  const [uploadFromMenuId, setUploadFromMenuId] = useState(null);
  const [isUploadFormAttachmentsMenuOpened, setIsUploadFormAttachmentsMenuOpened] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const onChangeErrorMessage = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const { handlePasteAttachment, handleAttachmentDrop, handleImageUpload, handleNewAttachment, handleUploadRetry } =
    useAttachmentControls({
      onChangeActiveUploads,
      onChangeUploadImageProgress,
      onChangeErrorMessage,
      onChangeUploadFileProgress,
      onImageUpload,
      onFileAttach,
      editorRef,
    });

  const height = useMemo(() => {
    if (isExpanded) {
      return isFullSize ? '300px' : '120px';
    }
    return editorText ? 'auto' : '24px';
  }, [isExpanded, isFullSize, editorText]);

  const initialContent = useInitialContent(editorType, sourceId);
  const signature = initialContent?.data?.emailSignature;
  const prevSignature = usePreviousValue(signature);

  const editorContent = useMemo(() => {
    let content = editorText;
    // iterate inline images and change src's domain if needed for the images can be seen properly by custom URLs
    if (readOnlyEditorRef?.current && editorText) {
      const contentElement = readOnlyEditorRef?.current;
      const inlineImages = Array.from(contentElement?.querySelectorAll('img'));
      for (const image of inlineImages) {
        const updatedSrc = updateSrcDomain(image.src);
        image.src = updatedSrc;
      }
      content = contentElement?.innerHTML;
    }

    if (signature) {
      const getSignatureEl = (currentSignature) => `<div class='email-signature'>${currentSignature}</div>`;
      // Changing signature
      if (signature !== prevSignature && editorText.includes('email-signature')) {
        content.replace(getSignatureEl(prevSignature), getSignatureEl(signature));
        // Adding signature only initially
      } else if (editorText?.length === 0) {
        content = `${editorText}${getSignatureEl(signature)}`;
      }
    }

    return content;
  }, [editorText, prevSignature, signature]);

  const getImagesFromEditor = useCallback(() => {
    if (readOnlyEditorRef.current) {
      const contentElement = readOnlyEditorRef.current;
      const inlineImages = Array.from(contentElement.querySelectorAll('img'));
      const filesData = [];
      for (const image of inlineImages) {
        const data = createAttachmentDataFromImage(image);
        filesData.push(data);
      }
      setImageList(filesData);
    }
  }, [readOnlyEditorRef]);

  useEffect(() => {
    if (editorContent !== null && editorContent !== undefined) {
      getImagesFromEditor();
    }
  }, [editorContent, getImagesFromEditor]);

  const handleDisplayPreview = (index) => {
    setSelectedImageIndex(index);
  };

  const handleImageRemove = () => {
    setSelectedImageIndex(null);
  };

  const handleClickContent = (e) => {
    if (e.target.tagName === TAG_NAMES.IMAGE_TAG) {
      const imageIndex = imageList.findIndex((img) => img.src === e.target.src);
      handleDisplayPreview(imageIndex);
    }
  };

  const readOnlyEditor = isReadOnly ? (
    <StyledRichTextEditorWrapper className="readonly-editor" onClick={handleClickContent} ref={readOnlyEditorRef}>
      <ReadOnlyEditor
        editorRef={editorRef}
        model={editorContent}
        config={{
          key: process.env.REACT_APP_FROALA_API_KEY,
          ...DEFAULT_CONFIG,
        }}
      />
      {selectedImageIndex !== null && (
        <FilesPreview
          filesData={imageList}
          deleteFileEnabled={false}
          selectedFileIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(null)}
          onFileRemove={handleImageRemove}
        />
      )}
      <div>{customDefaultUI}</div>
    </StyledRichTextEditorWrapper>
  ) : null;

  const richTextEditor = (
    <StyledRichTextEditorWrapper shouldRenderDefaultUI={shouldRenderDefaultUI} className="rich-text-editor-wrapper">
      <RichTextEditor
        {...props}
        ref={richTextEditorRef}
        toolbarButtons={toolbarButtons}
        isAttachmentsEnabled={isAttachmentsEnabled}
        editorWrapRef={editorWrapRef}
        editorRef={editorRef}
        editorText={editorContent}
        setEditorId={setEditorId}
        editorElementsClassName={editorElementsClassName}
        onAttachmentDrop={handleAttachmentDrop}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        isUploadFormAttachmentsMenuOpened={isUploadFormAttachmentsMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        setUploadFromMenuId={setUploadFromMenuId}
        handlePasteAttachment={handlePasteAttachment}
      />
      {customDefaultUI}
    </StyledRichTextEditorWrapper>
  );

  const attachmentsWrapper = shouldShowAttachments && isAttachmentsEnabled && (
    // Attachment of richtext
    <div className={`${editorElementClassName} attachments-wrapper`}>
      <Attachments
        isExpanded={isExpanded}
        editorRef={editorRef}
        imagePreviewConfig={imagePreviewConfig}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        attachments={attachmentsList}
        onAttachmentDeleted={onAttachmentDeleted}
        activeUploads={activeUploads}
        onChangeActiveUploads={onChangeActiveUploads}
        isReadOnly={Boolean(isReadOnly)}
        onImageUpload={handleImageUpload}
        onFileAttach={handleNewAttachment}
        errorMessage={errorMessage}
        onChangeErrorMessage={onChangeErrorMessage}
        onUploadRetry={handleUploadRetry}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        uploadFromMenuId={uploadFromMenuId}
        setUploadFromMenuId={setUploadFromMenuId}
        isMinimizedSize
      />
    </div>
  );

  return (
    <StyledGridContainer
      isTemplatePage={isTemplatePage}
      isExpanded={isExpanded}
      height={height}
      maximized={isFullSize.toString()}
      disabled={disabled}
      ref={editorWrapRef}
      isScrollable={attachmentsList?.length + activeUploads?.length > 3}
      hasAttachments={!!attachmentsList?.length}
      isReadOnly={isReadOnly}
      isRichTextTruncated={isRichTextTruncated}
      editorContent={editorContent}
      hasHeader={HAS_HEADER.includes(editorType)}
      id={`richtext-wrap-${editorId}`}
      data-testid={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
      data-cy={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
    >
      {readOnlyEditor || richTextEditor}
      {attachmentsWrapper}
    </StyledGridContainer>
  );
});

RichTextWrapper.propTypes = {
  type: PropTypes.number,
  charCounterMax: PropTypes.number,
  showToolbar: PropTypes.bool,
  disabled: PropTypes.bool,
  editorText: PropTypes.string,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  CustomFooter: PropTypes.element,
  attachmentsList: PropTypes.array,
  isUploadImageProgress: PropTypes.bool,
  isUploadFileProgress: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
  activeUploads: PropTypes.array,
  onChangeActiveUploads: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  handleClickAway: PropTypes.func,
  handleExpanded: PropTypes.func,
  toggleFullSize: PropTypes.func,
  onImageUpload: PropTypes.func,
  onFileAttach: PropTypes.func,
  onChangeUploadImageProgress: PropTypes.func,
  onChangeUploadFileProgress: PropTypes.func,
  isExpanded: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isRichTextTruncated: PropTypes.bool,
  hasFullSize: PropTypes.bool,
  shouldTriggerDeleteApi: PropTypes.bool,
  shouldRenderDefaultUI: PropTypes.bool,
  customDefaultUI: PropTypes.element,
  dataTestId: PropTypes.string,
};

RichTextWrapper.defaultProps = {
  type: 1,
  charCounterMax: DEFAULT_CONFIG.defaultCharCounterMax,
  showToolbar: true,
  isExpanded: false,
  isFullSize: false,
  hasFullSize: true,
  disabled: false,
  isReadOnly: false,
  shouldTriggerDeleteApi: true,
  shouldRenderDefaultUI: false,
  isUploadImageProgress: false,
  isUploadFileProgress: false,
  customDefaultUI: null,
  editorText: '',
  placeholder: '',
  dataTestId: '',
  CustomFooter: null,
  attachmentsList: [],
  activeUploads: [],
  onAttachmentDeleted: () => {},
  onChangeActiveUploads: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  handleExpanded: () => {},
  handleClickAway: () => {},
  toggleFullSize: () => {},
  onImageUpload: () => {},
  onFileAttach: () => {},
  onChangeUploadImageProgress: () => {},
  onChangeUploadFileProgress: () => {},
};

export default RichTextWrapper;
