import { datadogRum } from '@datadog/browser-rum';
import { LICENSE_CHECK } from 'constants/licenseCheckMonitor';
import { useCallback } from 'react';

interface DataObject {
  [key: string]: any;
}

interface EventObject {
  eventName: string;
  host: string;
  message: string;
  timestamp: string;
  error?: Error | null;
}

interface UseLicenseCheckMonitorReturn {
  licenseCheckFailedReport: (data: DataObject, error: Error | null) => void;
}

const useLicenseCheckMonitor = (): UseLicenseCheckMonitorReturn => {
  const createEvent = useCallback((data: DataObject, error: Error | null): EventObject => {
    const event: EventObject = {
      eventName: '',
      message: '',
      host: window.location.hostname,
      timestamp: new Date().toISOString(),
      error,
    };

    if (error) {
      event.eventName = LICENSE_CHECK.EVENT_NAME.LICENSE_CHECK_ERROR;
      event.message = `${LICENSE_CHECK.MESSAGE.LICENSE_CHECK_ERROR}${error.message}`;
    }

    if (data.instance === false) {
      event.eventName = LICENSE_CHECK.EVENT_NAME.INVALID_INSTANCE;
      event.message = LICENSE_CHECK.MESSAGE.INVALID_INSTANCE;
    }

    if (data.validAccount === false) {
      event.eventName = LICENSE_CHECK.EVENT_NAME.INVALID_ACCOUNT;
      event.message = LICENSE_CHECK.MESSAGE.INVALID_ACCOUNT;
    }

    if (data.validLicense === false) {
      event.eventName = LICENSE_CHECK.EVENT_NAME.LICENSE_EXPIRED;
      event.message = LICENSE_CHECK.MESSAGE.LICENSE_EXPIRED;
    }
    return event;
  }, []);

  const licenseCheckFailedReport = useCallback(
    (data: DataObject, error: Error | null): void => {
      const event = createEvent(data, error);
      if (event) {
        datadogRum.addAction(event.eventName, {
          message: event.message,
          timestamp: event.timestamp,
          host: event.host,
          error: event.error,
          ...data,
        });
      }
    },
    [createEvent],
  );

  return { licenseCheckFailedReport };
};

export default useLicenseCheckMonitor;
