import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import FieldSearchableDropdown from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown';
import CustomScroll from 'common/components/customScroll';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';
import { useFieldAttributes, useGetRecentlyCreatedSrs } from 'remote-state/ticketServiceHooks';
import { setToasterMessage } from 'store/globalSlice';

import { StyledDropdownItem } from '../linkItemProcess/style';
import { StyledRelationshipItemDropdowns, StyledDropdown, StyledDropDownMenu, StyledTextField } from './style';
import useTexts from '../hooks/useTexts';
import {
  useSrCustomBackendQueryConfig,
  useIsSrChildOrParent,
  useRelatedAssetItemsDropdownColumns,
  useRelatedCiItemsDropdownColumns,
  useRelatedSrItemsDropdownColumns,
  useAssetCustomBackendQueryConfig,
  useGetRecentCis,
  useGetRecentAssets,
  useCiCustomBackendQueryConfig,
} from '../hooks/useRelatedItems';
import {
  FIELD_ATTRIBUTES,
  ITEM_TYPES,
  LOCATION_FIELD_ATTRIBUTES,
  MAX_LINKED_ITEMS,
  MAX_LINKED_ITEMS_FOR_PARENT,
  PARENT_RELATIONSHIPS,
  RELATIONSHIP_TYPES,
} from '../constants';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  autoHeightMax: 230,
  renderThumbVerticalStyle: { width: '4px' },
  renderTrackVerticalStyle: { width: '0px', padding: 0 },
  renderViewStyle: { flex: '1' },
  dataset: { 'data-testid': 'relationship-item-dropdown-scrollbar' },
};

const RelationshipItemDropdowns = ({ data, relatedItemsToLink, handleChange, srId, itemType, srRelatedItemsCount }) => {
  const {
    componentTexts: {
      relationshipText,
      itemText,
      recentItemsText,
      searchSrPlaceholder,
      searchAssetPlaceholder,
      searchCiPlaceholder,
      limitReachedToasterMessage,
      limitReached,
      moreLimitReachedToasterMessage,
      selectText,
    },
    getRelationshipText,
  } = useTexts();

  const relatedSrItemsDropdownColumns = useRelatedSrItemsDropdownColumns();
  const relatedAssetItemsDropdownColumns = useRelatedAssetItemsDropdownColumns();
  const relatedCiItemsDropdownColumns = useRelatedCiItemsDropdownColumns();
  const [relationshipMenuOpen, setRelationshipMenuOpen] = useState(false);
  const [itemsDropdownData, setItemsDropdownData] = useState([]);
  const ref = useRef();
  const { relationship: srRelationType } = useIsSrChildOrParent(srId);
  const {
    data: { values: locationList },
  } = useFieldAttributes(LOCATION_FIELD_ATTRIBUTES, false, srId)?.[0] || { data: { values: [] } };
  const { data: recentlyCreatedSrs } = useGetRecentlyCreatedSrs(srId, itemType);
  const { data: recentAssets } = useGetRecentAssets(srId, itemType, locationList);
  const { data: recentCis } = useGetRecentCis(srId, itemType, locationList);
  const dispatch = useDispatch();

  const customSrBackendQueryConfig = useSrCustomBackendQueryConfig;
  const customAssetBackendQueryConfig = useAssetCustomBackendQueryConfig;
  const customCiBackendQueryConfig = useCiCustomBackendQueryConfig;
  const pendoTrackEvents = usePendoTrackEvents();

  useEffect(() => {
    if (itemType === ITEM_TYPES.SR.id) {
      setItemsDropdownData(recentlyCreatedSrs);
    } else if (itemType === ITEM_TYPES.ASSET.id) {
      setItemsDropdownData(recentAssets);
    } else if (itemType === ITEM_TYPES.CI.id) {
      setItemsDropdownData(recentCis);
    }
  }, [itemType, recentlyCreatedSrs, recentAssets, recentCis]);

  const handleOpenMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setRelationshipMenuOpen(true);
    },
    [setRelationshipMenuOpen],
  );

  const handleCloseMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setRelationshipMenuOpen(false);
    },
    [setRelationshipMenuOpen],
  );

  const handleSelect = useCallback(
    ({ type, payload, selectedValues, isSingleChipCleared }) => {
      let values = payload;
      if (selectedValues && isSingleChipCleared) {
        // delete single chip when dropdown is closed
        let idsArray = selectedValues?.split(',');
        if (itemType === ITEM_TYPES.SR.id || itemType === ITEM_TYPES.CI.id) {
          idsArray = idsArray?.map(Number);
        } else {
          idsArray = idsArray?.map(String);
        }
        values = itemsDropdownData?.filter((option) => idsArray.includes(option.id));
      }
      //handle limit items
      const isParentRelationship = PARENT_RELATIONSHIPS?.includes(relatedItemsToLink?.relationship?.id);
      const itemsLimitNumber = isParentRelationship ? MAX_LINKED_ITEMS_FOR_PARENT : MAX_LINKED_ITEMS;
      const toasterMessage = isParentRelationship ? moreLimitReachedToasterMessage : limitReachedToasterMessage;
      const hasReachedLimit = values?.length + srRelatedItemsCount > itemsLimitNumber;
      if (hasReachedLimit) {
        dispatch(
          setToasterMessage({
            type: 'warning',
            message: toasterMessage.replace('__limitReached!__', `<b>${limitReached}</b>`),
            showFor: 15000,
            styleDialogOverrides: {
              '& .MuiPaper-root': {
                maxWidth: '625px!important',
              },
              '& .MuiDialogActions-root': {
                '> *': {
                  margin: 0,
                },
              },
            },
          }),
        );
        return;
      }
      // when selecting 'Child Of' relationship, only one item can be selected
      if (type === 'items' && relatedItemsToLink?.relationship?.id === RELATIONSHIP_TYPES.CHILD && values?.length > 1) {
        return;
      }
      handleChange({ type, payload: values });
      if (type === 'relationship') {
        pendoTrackEvents(PENDO_TRACK_EVENTS.Choosing_relationship_type_in_related_items_box, {
          relationshipId: values?.id,
          relationshipType: values?.name,
        });
        setRelationshipMenuOpen(false);
      }
    },
    [
      handleChange,
      itemsDropdownData,
      relatedItemsToLink?.relationship,
      itemType,
      srRelatedItemsCount,
      pendoTrackEvents,
      dispatch,
      limitReachedToasterMessage,
      limitReached,
      moreLimitReachedToasterMessage,
    ],
  );

  const getColumns = useCallback(() => {
    switch (itemType) {
      case ITEM_TYPES.SR.id:
        return relatedSrItemsDropdownColumns;
      case ITEM_TYPES.ASSET.id:
        return relatedAssetItemsDropdownColumns;
      case ITEM_TYPES.CI.id:
        return relatedCiItemsDropdownColumns;
      default:
        return relatedSrItemsDropdownColumns;
    }
  }, [itemType, relatedAssetItemsDropdownColumns, relatedSrItemsDropdownColumns, relatedCiItemsDropdownColumns]);

  const getSearchPlaceholder = useCallback(() => {
    switch (itemType) {
      case ITEM_TYPES.SR.id:
        return searchSrPlaceholder;
      case ITEM_TYPES.ASSET.id:
        return searchAssetPlaceholder;
      case ITEM_TYPES.CI.id:
        return searchCiPlaceholder;
      default:
        return searchSrPlaceholder;
    }
  }, [itemType, searchSrPlaceholder, searchAssetPlaceholder, searchCiPlaceholder]);

  const getCustomBackendQueryConfig = useCallback(() => {
    switch (itemType) {
      case ITEM_TYPES.SR.id:
        return customSrBackendQueryConfig;
      case ITEM_TYPES.ASSET.id:
        return customAssetBackendQueryConfig;
      case ITEM_TYPES.CI.id:
        return customCiBackendQueryConfig;
      default:
        return customSrBackendQueryConfig;
    }
  }, [itemType, customAssetBackendQueryConfig, customSrBackendQueryConfig, customCiBackendQueryConfig]);

  const columns = useMemo(() => getColumns(), [getColumns]);
  const searchPlaceholder = useMemo(() => getSearchPlaceholder(), [getSearchPlaceholder]);
  const customBackendQueryConfig = useMemo(() => getCustomBackendQueryConfig(), [getCustomBackendQueryConfig]);

  return (
    <StyledRelationshipItemDropdowns>
      <StyledDropdown>
        <FieldName required name={relationshipText} />
        <StyledTextField
          id="relationship-input"
          placeholder={selectText}
          value={getRelationshipText(relatedItemsToLink?.relationship?.name) || ''}
          onClick={handleOpenMenu}
          readOnly
          name="relationship"
          autoComplete="off"
          type="text"
          ref={ref}
          isOpen={relationshipMenuOpen}
          hasValue={relatedItemsToLink?.relationship?.name}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            autoComplete: 'off',
            endAdornment: <ArrowDownIcon />,
          }}
        />
        <StyledDropDownMenu
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={relationshipMenuOpen}
          onClose={handleCloseMenu}
        >
          <CustomScroll {...customScrollProps}>
            {data?.relationships
              ?.filter(
                (relationship) =>
                  relationship.id !== RELATIONSHIP_TYPES.MERGED_TO &&
                  relationship.id !== RELATIONSHIP_TYPES.MERGED_FROM,
              )
              ?.map((relationship) => (
                <MenuItem
                  key={relationship.id}
                  onClick={() => handleSelect({ type: 'relationship', payload: relationship })}
                  // disable parent option when sr is child of another ticket as it can't be parent and child
                  // or disable child option if sr is parent of another tickets
                  disabled={
                    (relationship.id === RELATIONSHIP_TYPES.PARENT && srRelationType === RELATIONSHIP_TYPES.CHILD) ||
                    (relationship.id === RELATIONSHIP_TYPES.CHILD && srRelationType === RELATIONSHIP_TYPES.PARENT)
                  }
                  data-testid={`relationship-item-${relationship.id}`}
                >
                  <StyledDropdownItem>{getRelationshipText(relationship.name)}</StyledDropdownItem>
                </MenuItem>
              ))}
          </CustomScroll>
        </StyledDropDownMenu>
      </StyledDropdown>
      <StyledDropdown isDirty={relatedItemsToLink?.items?.length} isFullWidth>
        <FieldSearchableDropdown
          field={FIELD_ATTRIBUTES}
          fieldValue={relatedItemsToLink?.items?.map((item) => item.id)}
          onChange={(values, selectedObjects, isSingleChipCleared) =>
            handleSelect({ type: 'items', payload: selectedObjects, selectedValues: values, isSingleChipCleared })
          }
          skipValidation
          isSelectAllHidden
          listLabel={itemType === ITEM_TYPES?.SR?.id && recentItemsText}
          values={itemsDropdownData}
          displayName={itemText}
          required
          isMultiple
          chipCaptionKey="chipCaption"
          searchPlaceholder={searchPlaceholder}
          columns={columns}
          selectedValuesAsObjects
          customBackendQueryConfig={customBackendQueryConfig}
          dataCy="items-dropdown"
          noChipFixedWidth
        />
      </StyledDropdown>
    </StyledRelationshipItemDropdowns>
  );
};

export default RelationshipItemDropdowns;
