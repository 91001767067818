import Tooltip from 'common/components/tooltip';
import useTexts from '../../useTexts';

const MissingTemplateTooltip = ({ placement = "top", text = "" }) => {
  const { notExistInTemplateMessage } = useTexts();
  const tooltipText = text || notExistInTemplateMessage;

  return (
    <Tooltip
      placement={placement}
      text={tooltipText}
      disableTooltip={tooltipText === notExistInTemplateMessage}
      isTruncatedText
    />
  );
};

export default MissingTemplateTooltip;
