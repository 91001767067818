import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSettingsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

export const StyledMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',
  background: theme.components.main.background,
  width: theme.components.main.width,
  height: theme.components.main.height,
  borderRadius: theme.components.main.borderRadius,
  overflowX: theme.components.main.overflowX,
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.components.header.margin,
}));

export const HeaderTitle = styled('span')(({ theme }) => ({
  color: theme.palette.text.txt_default,
  fontFamily: theme.fonts.robotoLight,
  fontSize: theme.components.headerTitle.fontSize,
  lineHeight: theme.components.headerTitle.lineHeight,
}));

export const StyledSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '100%',
  position: 'sticky',
  zIndex: 1,
  background: theme.components.main.background,
  top: 0,
  minHeight: '120px',
}));

export const StyledSearchField = styled(Box)(() => ({
  width: '570px',
  height: '48px',

  '&& .SearchField': {
    width: '100%',
    margin: '0',
    height: '100%',
    borderRadius: '60px',

    '.MuiOutlinedInput-root': {
      width: '100%',
      height: '100%',
    },
  },
  '.MuiInputAdornment-root': {
    marginRight: '-5px !important',
  },
}));

export const StyledMenuItemsContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  rowGap: '40px',
  '.menu_items_wrapper': {
    padding: '0 36px 56px',
    '.menu-items': {
      display: 'flex',
      columnGap: '9px',
      flexWrap: 'wrap',
      '@media only screen and (max-width: 1440px)': {
        columnGap: '10px',
      },
      '.menu-item': {
        width: '270px',
        '@media only screen and (max-width: 1440px)': {
          width: '230px',
        },
      },
    },
  },
  '.title-link-group': {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '21px',
  },
}));

export const StyledMenuButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  marginTop: '50px',
  width: '222px',

  '.title': {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '48px',
  },

  '.links-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    cursor: 'pointer',

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    '.link-menu-title': {
      padding: '2px 6px',
      fontFamily: theme.fonts.robotoRegular,
      fontSize: theme.fontSize.main,
      lineHeight: '20px',
      background: 'transparent',
      border: 'none',
      textAlign: 'start',
      borderRadius: '4px',
      ':hover': {
        color: theme?.palette?.text?.title_txt,
        backgroundColor: theme.palette.grey.bt_alt_grey_reg,
      },
    },
  },
  '&.copilot-menu-disable, &.copilot-menu-unavailable': {
    cursor: 'default',
    color: theme.palette.text.disabled,
    '> svg path': {
      fill: theme.palette.text.disabled,
    },
    '*': {
      cursor: 'default',
    },
    '.link-menu-title': {
      ':hover': {
        color: 'inherit',
        backgroundColor: 'transparent',
      },
    },
  },
  '&.copilot-menu-unavailable': {
    position: 'relative',
    height: 'fit-content',
    padding: '10px 10px 20px',
    borderRadius: '16px',
    border: '2px solid transparent',
    boxShadow: `0 4px 4px 0 ${theme.palette.background.boxShadow}`,
    background:
      'linear-gradient(white, white) padding-box, linear-gradient(to right bottom, #B395F5, #B395F5, #3EE0E0, #8C50EE) border-box',
    marginRight: '10px',
    maxWidth: '260px',
    '@media only screen and (max-width: 1440px)': {
      maxWidth: '220px',
    },
    '.copilot-badge': {
      position: 'absolute',
      top: '-22px',
      right: '-20px',
      display: "flex",
      gap: "10px",
      alignItems: "center",
      border: `10px solid ${theme.palette.generic.primary}`,
      borderRadius: '20px',
      background: `linear-gradient(90deg, ${theme.palette.copilotBadge.backgroundColor_1} 4.64%, ${theme.palette.copilotBadge.backgroundColor_2} 100%)`,
      color: theme.palette.copilotBadge.color,
      fontFamily: theme.fonts.robotoMedium,
      fontSize: theme.fontSize.small,
      lineHeight: 1.7,
      padding: '2px 12px',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

export const StyledTitle = styled(Box)(({ theme, highlightColor }) => ({
  '.highlight': {
    color: highlightColor(theme),
  },
}));
