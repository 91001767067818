import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';

import { useRbTexts } from 'remote-state/applicationHooks';

const useShowNoEditPermissionPopup = (): { showNoPermissionPopup: () => void } => {
  const dispatch = useDispatch();
  const texts = useRbTexts();
  const noEditingPermission = useMemo(
    () => texts?.['spaces.field.error.noEditingPermission'],
    [texts],
  );

  const showNoPermissionPopup = () => {
    dispatch(
      setToasterMessage({
        message: noEditingPermission,
        showFor: 5000,
        styleDialogOverrides: {
          '& .MuiPaper-root': {
            maxWidth: '500px!important',
          },
        },
      }),
    );
  };

  return { showNoPermissionPopup };
};

export default useShowNoEditPermissionPopup;
