import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { PlaceholderValues } from 'common/utils/constants';
import { InlineField } from '../../../../../common/components/controls/InlineField';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from '../../../../../store/fieldValidationsSlice';

export const TextAreaEditor = memo((props) => {
  const {
    text,
    limit,
    source,
    ticket,
    onFocus,
    disabled,
    required,
    fullwidth,
    fieldName,
    isNewField,
    placeholder,
    hoverEffect,
    delayedHover,
    tooltipClassName,
  } = props;

  const {
    CustomFieldComponent,
    handleSaveValue,
    handleDirty,
    updateFieldValidation,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(text ?? '');
  const forceShowError = useSelector(isNewField ? selectForceFloatingTicketShowValidationError : selectForceShowValidationError);
  const [isError, setIsError] = useState(false);
  const inputRef = useRef();
  const [isOverflow, setIsOverflow] = useState(inputRef?.current?.scrollWidth > inputRef?.current?.offsetWidth);
  const characterCount = useRef(currentValue?.length || 0);
  const maxLength = 5000;

  const checkIsError = useCallback(() => {
    const isInvalid = (typeof currentValue !== 'string' || (!currentValue.trim() || currentValue === PlaceholderValues.NotAvailable)) && required;
    return isNewField ? isInvalid && forceShowError : isInvalid;
  }, [forceShowError, currentValue, isNewField, required]);

  const checkAndUpdateValidationStatus = useCallback(() => {
    const isValid = !checkIsError();
    setIsError(!isValid);
    updateFieldValidation?.(fieldName, required, isValid);
    return isValid;
  }, [fieldName, required, updateFieldValidation, checkIsError]);

  useEffect(() => {
    inputRef.current.focus();
    setCurrentValue(text ?? '');
  }, [text]);

  const handleChangeValue = (event) => {
      setCurrentValue(event.target.value || '');
      characterCount.current = event.target.value.length;
  };

  useEffect(() => {
    checkAndUpdateValidationStatus();
  }, [checkAndUpdateValidationStatus, currentValue, required]);

  const handleSave = (value) => {
    if (!fieldName) {
      console.error('[TextAreaEditor] No name found for the property that is being edited (text field)');
      return;
    }
    handleDirty?.({ [fieldName]: value });
    handleSaveValue({ [fieldName]: value });
  };

  const commonProps = {
    value: currentValue,
    onChange: handleChangeValue,
    text,
    handleSave,
    isError,
    setIsError,
    disabled,
    required,
  };

  return (
    <div className="textarea-wrap">
      <InlineField
        {...rest}
        {...commonProps}
        placeholder={placeholder}
        source={source}
        fullwidth={fullwidth && 'true'}
        inputRef={inputRef}
        limit={limit}
        ticket={ticket}
        delayedHover={delayedHover}
        hoverEffect={hoverEffect}
        isOverflow={isOverflow}
        setIsOverflow={setIsOverflow}
        name={fieldName}
        isNewField={isNewField}
        onFocus={onFocus}
        tooltipClassName={tooltipClassName}
        helperText= {
           <Box
              component="span"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "5px",
              }}>
            <span>
              {`${characterCount.current}/${
                maxLength
              }`}
            </span>
           </Box>
        }
      />
    </div>
  );
});