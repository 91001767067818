import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    settingsText: texts?.['spaces.menu.settings'],
    listPageTitleText: texts?.['spaces.templateBuilder.listPageTitle'],
    newTemplateText: texts?.['spaces.templateBuilder.newTemplate'],
    searchTemplatePlaceholderText: texts?.['spaces.templateBuilder.searchTemplatePlaceholder'],
    srTypeIncidentText: texts?.['spaces.srType.incident'],
    srTypeRequestText: texts?.['spaces.srType.request'],
    srTypeProblemText: texts?.['spaces.srType.problem'],
    srTypeChangeText: texts?.['spaces.srType.change'],
    categoryDrivenTemplatesText: texts?.['spaces.template.categoryDrivenTemplates.text'],
    categoryDrivenTemplatesTooltipText: texts?.['spaces.template.categoryDrivenTemplates.tooltipText'],
    listHeaderIdText: texts?.['spaces.templateBuilder.listPageHeader.id'] || 'ID',
    listHeaderNameText: texts?.['spaces.templateBuilder.listPageHeader.name'] || 'Name',
    listHeaderTypeText: texts?.['spaces.templateBuilder.listPageHeader.type'] || 'Type',
    listHeaderCreateDateText: texts?.['spaces.templateBuilder.listPageHeader.createDate'] || 'Create Date',
    listHeaderVisibleInSSPText: texts?.['spaces.templateBuilder.listPageHeader.VisibleInSSP'] || 'Visible in SSP',
    listHeaderCreatedByText: texts?.['spaces.templateBuilder.listPageHeader.createdBy'] || 'Created By',
    listHeaderActionsText: texts?.['spaces.templateBuilder.listPageHeader.actions'] || 'Actions',
    listHeaderDefault: texts?.['spaces.templateBuilder.listPageHeader.default'] || 'default',
    incident: texts?.['spaces.srType.incident'],
    request: texts?.['spaces.srType.request'],
    problem: texts?.['spaces.srType.problem'],
    change: texts?.['spaces.srType.change'],
  };
  return componentTexts;
}
