import { styled } from '@mui/material/styles';

export const IdFieldWrapperStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CellComponentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'isSelector',
})(({ fullWidth = false, isSelector = false }) => {
  const customWidth = isSelector ? 'calc(100% - 45px)' : '100%';
  return {
    maxWidth: '100%',
    ...(fullWidth && { width: customWidth }),
  }
});
