import { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { MenuItem } from '@mui/material';
import CustomScroll from 'common/components/customScroll';
import DropDownHeader from 'common/components/dropdownSelect/dropDownHeader';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import { StyledDropdownItem } from 'features/resolutionPanel/middlePanel/relatedItems/linkItemProcess/style';
import { StyledDropdown, StyledIntegrationTextField, StyledDropDownMenu } from '../integrations.styles';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  autoHeightMax: 230,
  renderThumbVerticalStyle: { width: '4px' },
  renderTrackVerticalStyle: { width: '0px', padding: 0 },
  renderViewStyle: { flex: '1' },
};

const IntegrationSelectField = ({ field, options, onChange }) => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const ref = useRef();

  // TODO useTexts  
  const searchPlaceholderText = 'Search';
  const clearText = 'Clear';

  useEffect(() => {
    setValue(null);
  }, [options]);

  const handleOpenMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setDropdownMenuOpen(true);
    },
    [setDropdownMenuOpen],
  );

  const handleCloseMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setDropdownMenuOpen(false);
    },
    [setDropdownMenuOpen],
  );

  const handleSelect = (option) => {
    setValue(option);
    setDropdownMenuOpen(false);
    setSearchString('');
    onChange(option);
  };

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
  };
  const onSearchClear = () => {
    setSearchString('');
  };
  const onSelectClear = () => handleSelect(null);

  useEffect(() => {
    if (searchString) {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(searchString.toLowerCase()));
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchString, options]);

  return (<>
      <StyledDropdown>
        <FieldName required={field.required} name={field.displayName} />
        <StyledIntegrationTextField
          id={field.name}
          data-testid={field.name}
          placeholder="--"
          value={value ? value.label : ''}
          onClick={handleOpenMenu}
          readOnly
          name=""
          autoComplete="off"
          type="text"
          ref={ref}
          isOpen={dropdownMenuOpen}
          hasValue={value}
        />
        <StyledDropDownMenu
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={dropdownMenuOpen}
          onClose={handleCloseMenu}
          containerWidth={ref?.current?.offsetWidth}
        >
          <CustomScroll {...customScrollProps}>
            {!isEmpty(options) && (
              <DropDownHeader
                isSearchVisible
                searchFieldProps={{
                  value: searchString,
                  placeholderText: searchPlaceholderText,
                  onChange: onSearchChange,
                  onClear: onSearchClear,
                  inputProps: {
                    'data-testid': `${field.name}-search-field`,
                  },
                  autoFocus: true,
                }}
                isActionsVisible
                actionsProps={{
                  isMultiSelect: false,
                  isDirty: false,
                  isClearHidden: field.required,
                  isClearDisabled: isEmpty(value),
                  isSelectAllHidden: true,
                  clearText,
                  clearValueHandler: onSelectClear,
                }}
                isDividerVisible
                listLabel
              />
            )}
            {filteredOptions?.map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => handleSelect(option)}
              >
                <StyledDropdownItem>{option.label}</StyledDropdownItem>
              </MenuItem>
            ))}
          </CustomScroll>
        </StyledDropDownMenu>
      </StyledDropdown>
    </>
  );
};

export const IntegrationFieldComponent = ({ field, options, onChange }) => {
  switch (field.type) {
    case 'select':
      return <IntegrationSelectField field={field} options={options} onChange={onChange}/>
    default:
      break;
  }
};
