import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PlaceholderValues } from 'common/utils/constants';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import { DropdownSelectWrapper, StyledSelectedOption, StyledLabel } from '../style';

const Select = ({
  options,
  title,
  dataTestId,
  keyValue,
  keyCaption,
  required,
  isMultiple,
  selection,
  onChange,
  dataCy,
}) => {
  const { anchor, handleOpen, handleClose } = useDropdownControl();

  const handleChange = useCallback(
    (valueSelected) => {
      onChange(keyValue, keyCaption, valueSelected);
      handleClose();
    },
    [onChange, keyValue, keyCaption, handleClose],
  );

  const selectionMemo = useMemo(() => [selection], [selection]);

  return (
    <DropdownSelectWrapper data-testid="dropdown-select" data-cy={`${dataTestId}-box`}>
      <StyledLabel>
        {title}
        {required && <span className="mandatory-asterisk"> *</span>}
      </StyledLabel>
      <StyledSelectedOption
        onClick={handleOpen}
        onKeyDown={handleOpen}
        isEmpty={!selection}
        data-testid={dataTestId}
        data-cy={dataCy}
      >
        {selection || PlaceholderValues.NotAvailable}
      </StyledSelectedOption>
      <DropdownSelect
        className={keyValue}
        title={title}
        options={options}
        selection={selectionMemo}
        anchor={anchor}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleChange={handleChange}
        isMultiple={isMultiple}
        stylingVariant="status"
      />
    </DropdownSelectWrapper>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  keyValue: PropTypes.string.isRequired,
  keyCaption: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isMultiple: PropTypes.bool,
  selection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  required: false,
  isMultiple: false,
  selection: '',
};

export default Select;
