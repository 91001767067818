import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useMediaQuery } from 'react-responsive';
import { getTicketLockStatus } from 'services/ticketService';
import {
  useFieldAttributes,
  useHandleTicketUnlock,
  useLinkedSrLists,
  useMigrationFailedHandler,
  useSaveSR,
  useSR,
  useTicketLockStatus,
  useTicketPermissions,
} from 'remote-state/ticketServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useAuditLog';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import Header from 'features/header/header';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { editorActionData } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { APP_CONSTANTS, PAGE_VALIDATION_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import { ERROR_TYPES } from 'constants/common';
import FieldsNotSavedPrompt from 'common/components/fieldsNotSavedPrompt';
import CircularProgress from 'common/components/circularProgress';
import StatusMessage from 'common/components/statusMessage';
import { ReactComponent as PreviewIcon } from 'images/icons/PreviewSign.svg';
import { setError } from 'store/globalSlice';
import {
  resetFieldValidationStatus,
  selectFieldValidations,
  selectSrStatusValue,
  updateSrStatusValue,
} from 'store/fieldValidationsSlice';
import { selectSRStatus, updateEscalationPrompt, updatePreviousSRStatus } from 'store/srSlice';
import { removePrimaryCategories, removeSecondaryCategories, removeThirdLevelCategories } from 'store/categorySlice';
import {
  resetAuditLog,
  selectActionLine,
  selectJourneyFilters,
  updateActionLineData,
  updateActionLineIsEditorDirty,
  updateEditorToDirty,
} from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { useResolution } from 'features/resolutionPanel/middlePanel/auditLog/actionLine/solutionResolution/useResolution';
import { setPageValidations } from 'store/pageValidationSlice';
import {
  resetSrSaved,
  resetSrSaveFieldsPermissionError,
  selectSrIsSaved,
  selectSrIsSaveFieldsPermissionError,
} from 'store/saveSrValidationSlice';
import { clearActiveUploads } from 'store/attachmentsSlice';
import { selectActiveUser } from 'store/userSlice';
import { setSrDetailsForSummary } from 'store/ticketSummarySlice';
import { useStatusClass } from 'common/utils/hooks/useStatusClass';
import { useTabs } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useTabs';
import { MIDDLE_PANEL_TAB_NAMES } from 'features/resolutionPanel/middlePanel/constants';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import usePerformanceMeasure from 'common/utils/hooks/usePerformanceMeasure';
import { CategoryProvider } from 'store/CategoryContext';
import { selectIsNavBarCollapsed } from '../loggedInRoutesPanel/slice';
import useValidation from '../../common/utils/hooks/useValidation';
import useTexts from './useTexts';
import { getIsExistingSr, getIsPreviewSr, shouldShowDeEscalationPrompt } from '../../common/utils/srUtils';
import { SR_PANEL_CONSTANTS, STATUS_FIELD_ID } from '../srPanel/consts';
import { RESOLUTION_PANEL_CONSTANTS } from '../resolutionPanel/constants';
import SrPanel from '../srPanel';
import ResolutionPanel from '../resolutionPanel';
import { StyledCircularProgress, StyledTicketPanel } from './styledTicketPanel';
import { StyledHeaderContainer } from './styledHeaderContainer';
import { StyledMainTicketPanel } from './styledMainTicketPanel';
import { StyledPreviewModeMessageWrapper } from './styledPreviewModeMessage';
import { useTicketWebSocketDataSync } from './useTicketWebSocketDataSync';
import ExternalModal from '../externalModal';
import { useSrArchivingEnabled } from '../../remote-state/accountSettingsServiceHooks';

const NOTIFICATIONS = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Notifications');
const MESSAGES = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Messages');
const SUMMARY = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Summary');
const RESOLUTION = 'resolution';

const TicketPanelContainer = memo(() => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { modal, notSavedModalMessage, previewMode, notSavedByPermissionMessage, archivedBannerText } = useTexts();
  const { classicSRUrl, handleMigrationFailed, handleModalClose } = useMigrationFailedHandler();

  const {
    srId,
    error,
    sr: { data: srObject, isFetched: isSrFetched, refetch: refetchSR },
    template,
  } = useSR();

  const { data: templateData } = template || {};
  const { performanceMeasureEventReport } = usePerformanceMeasure();
  useTicketWebSocketDataSync({ insertTime: srObject?.insertTime });
  const userAccount = useSelector(selectActiveUser);
  const srStatus = useSelector(selectSRStatus);
  const srStatusValue = useSelector(selectSrStatusValue);

  const isSaveFieldsPermissionError = useSelector(selectSrIsSaveFieldsPermissionError);
  const { isEditorDirty } = useSelector(selectActionLine);
  const fieldValidations = useSelector(selectFieldValidations);
  const isNavBarCollapsed = useSelector(selectIsNavBarCollapsed);
  const isSrSaved = useSelector(selectSrIsSaved);
  const journeyFilters = useSelector(selectJourneyFilters);
  const [isFieldsAreTouched, setAreFieldsChanged] = useState(false);
  // // Here we have two breakpoints as required:
  // // The first breakpoint is when the navigation bar is collapsed, which is 1023px
  // // The second one is when the navigation bar is expanded, which is 1210px
  const isNavBarClosedHorizontalView = useMediaQuery({ query: '(min-width: 1024px)' });
  const isNavBarOpenHorizontalView = useMediaQuery({ query: '(min-width: 1280px)' });

  const { resolutionAuditLog } = useResolution();
  const { handleEditorFocus, handleActionLineOperation, toggleRichTextEdited, handleEditorChange } = useEditorActions();
  const { handleActiveTab, handleActiveFilter, updateAuditLogs } = useAuditLog();
  const isArchivingEnabled = useSrArchivingEnabled();

  const isArchived = isArchivingEnabled && srObject?.archive === 1;

  const fields = useMemo(() => {
    const headerFields = template?.data?.header || [];
    const sectionFields =
      template?.data?.sections?.flatMap((section) => section.sectionRows.flatMap((row) => row.fields)) || [];

    return [...headerFields, ...sectionFields];
  }, [template?.data?.header, template?.data?.sections]);

  let hasEditPermissions = true;
  const { data } = useTicketPermissions('edit', srId === 'new' ? null : srId);
  if (srId !== 'new') {
    hasEditPermissions = data;
  }

  const auditLogsRef = useRef([]);
  const showTicketAsTab =
    (!isNavBarClosedHorizontalView && isNavBarCollapsed) || (!isNavBarOpenHorizontalView && !isNavBarCollapsed);
  const { tabs: ticketTabs } = useTabs({ srId, showTicketAsTab });

  const focusOnLastResolution = useCallback(
    (statusElement) => {
      const editorActionSettings = editorActionData[editorActionTypes.RESOLUTION];
      const updatedStatus = {
        [editorActionSettings.statusCaption]: statusElement.value,
        [editorActionSettings.status]: statusElement.id,
        manualStatusChanged: true,
      };
      const isCoreSolution = resolutionAuditLog?.logInformation?.isDisabledJourneyLog;
      if (resolutionAuditLog && !isCoreSolution) {
        if (srStatus.valueClass !== SR_PANEL_CONSTANTS.CLOSED_CLASS) {
          const { id: auditLogId, logInformation } = resolutionAuditLog;
          if (journeyFilters.filter === NOTIFICATIONS || journeyFilters.filter === MESSAGES) {
            handleActiveFilter(SUMMARY);
          }
          auditLogsRef?.current?.[auditLogId]?.scrollIntoView();
          if (hasEditPermissions) {
            handleActionLineOperation({
              actionName: 'edit',
              type: editorActionTypes.RESOLUTION,
              logInformation: { ...logInformation, ...updatedStatus },
              auditLogId,
              handleClickEdit: toggleRichTextEdited({ auditLogId }),
              manualStatusChanged: true,
            });
          }
        }
      } else if (isEditorDirty) {
        handleEditorFocus({
          type: editorActionTypes.RESOLUTION,
          isEditorChanged: true,
          isNewEditor: true,
          logInformation: updatedStatus,
        });
      } else if (isCoreSolution) {
        const { logInformation } = resolutionAuditLog;
        handleEditorFocus({
          type: editorActionTypes.RESOLUTION,
          isNewEditor: true,
          isEditorChanged: false,
          logInformation: { ...logInformation, ...updatedStatus },
        });
      } else {
        handleEditorFocus({
          type: editorActionTypes.RESOLUTION,
          isNewEditor: true,
          isEditorChanged: false,
          logInformation: updatedStatus,
        });
      }
      const journeyTabIndex = ticketTabs.findIndex((tab) => tab.name === MIDDLE_PANEL_TAB_NAMES.JOURNEY);
      handleActiveTab(null, journeyTabIndex, RESOLUTION);
      if (!resolutionAuditLog || (resolutionAuditLog && srStatus.valueClass !== SR_PANEL_CONSTANTS.CLOSED_CLASS)) {
        dispatch(updateEditorToDirty());
        dispatch(updateActionLineIsEditorDirty({ [editorActionSettings.status]: true }));
      }
    },
    [
      resolutionAuditLog,
      isEditorDirty,
      ticketTabs,
      handleActiveTab,
      srStatus.valueClass,
      journeyFilters.filter,
      hasEditPermissions,
      handleActiveFilter,
      handleActionLineOperation,
      toggleRichTextEdited,
      handleEditorFocus,
      dispatch,
    ],
  );

  const onTicketStatusChange = useCallback(
    (updateStatus) => {
      dispatch(updatePreviousSRStatus(srStatus));
      if (updateStatus.valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS) {
        focusOnLastResolution(updateStatus);
        dispatch(updateActionLineData({ manualStatusChanged: true }));
        const editorActionSettings = editorActionData[editorActionTypes.RESOLUTION];
        handleEditorChange(editorActionSettings.statusCaption, {
          [editorActionSettings.statusCaption]: updateStatus.value,
        });
        handleEditorChange(editorActionSettings.status, { [editorActionSettings.status]: updateStatus.valueKey });
      }
    },
    [dispatch, focusOnLastResolution, handleEditorChange, srStatus],
  );

  const [getStatusFieldData] = useStatusClass();

  const { isClosedStatusClass, valueClass, statusValueText, selectedStatusId } = getStatusFieldData(
    srId,
    srStatusValue,
  );

  useEffect(() => {
    if (isClosedStatusClass) {
      onTicketStatusChange({
        valueClass,
        value: statusValueText,
        id: selectedStatusId,
      });
      dispatch(updateSrStatusValue({ srId, srStatusValue: null }));
    }
  }, [srId, dispatch, valueClass, statusValueText, selectedStatusId, isClosedStatusClass, onTicketStatusChange]);

  useEffect(() => {
    if (userAccount?.username && srId && typeof srId === 'number') {
      getTicketLockStatus({ username: userAccount?.username, srId }); // temporary solution to subscribe user to viewers on backend until we seperate the call
    }
  }, [userAccount?.username, srId]);

  const srIdRef = useRef(srId);

  const updateSrIdRef = useCallback(() => {
    srIdRef.current = srId;
  }, [srId]);

  const { data: lockingDetails } = useTicketLockStatus(srIdRef.current);

  const isLocked = lockingDetails?.isLocked;
  const lockingUser = lockingDetails?.lockingUser;
  const { mutate: unlockSR } = useHandleTicketUnlock(srIdRef.current, updateSrIdRef); // we are using ref here because we call handleTicketUnlock when we navigate away from ticket page. as a result the srId may get the context of the next page and the ref keeps the original context

  const handleTicketUnlock = useCallback(() => {
    if (router.latestLocation.pathname === ROUTES_PATHS.TICKET_URL && !router.latestLocation.search.id) {
      router.navigate({ to: ROUTES_PATHS.BASE_PATH });
      return;
    }
    if (isLocked && lockingUser === userAccount?.username) {
      unlockSR({ username: userAccount?.username, srId: srIdRef.current });
    } else {
      updateSrIdRef();
    }
  }, [isLocked, lockingUser, userAccount?.username, srIdRef, unlockSR, updateSrIdRef, router]);

  const handleBeforeUnload = useCallback(() => {
    handleTicketUnlock();
  }, [handleTicketUnlock]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const { createTime } = router.latestLocation.search;

  const [forceReload, setForceReload] = useState(false);
  const [timeStamp, setTimeStamp] = useState(createTime || 0);

  useEffect(() => {
    if (createTime !== timeStamp) {
      dispatch(resetFieldValidationStatus());
      setTimeStamp(createTime);
      setForceReload(true);
    }
  }, [timeStamp, createTime, dispatch]);

  useEffect(() => {
    if (forceReload) {
      setForceReload(false);
    }
  }, [forceReload, dispatch]);

  const [checkRequiredFieldsValidation] = useValidation();
  const [isValidTicket, setIsValidTicket] = useState();

  const reportValidationIssue = useCallback(
    (isValidProp) => {
      const isValid = isFieldsAreTouched ? isValidProp : true;
      dispatch(
        setPageValidations({
          pageKey: ROUTES_PATHS.TICKET_URL,
          component: `${PAGE_VALIDATION_CONSTANTS.TICKET_PANEL}`,
          validationDetails: {
            isValid,
            isDescriptionShouldExist: false,
            isIconShouldExist: false,
            promptTexts: !isValid && {
              title: modal.title,
              description: modal.description,
              btnOkText: modal.btnOkText,
              btnCancelText: modal.btnCancelText,
            },
          },
        }),
      );
    },
    [dispatch, isFieldsAreTouched, modal.title, modal.description, modal.btnOkText, modal.btnCancelText],
  );

  const updateValidationStatus = useCallback(() => {
    const isValid = checkRequiredFieldsValidation(srId);
    setIsValidTicket(isValid);
    reportValidationIssue(isValid);
  }, [srId, checkRequiredFieldsValidation, reportValidationIssue]);

  useEffect(() => {
    if (fieldValidations?.fieldSrList?.[srId]) {
      updateValidationStatus();
    }
  }, [updateValidationStatus, srId, fieldValidations?.fieldSrList]);

  const prevLocation = usePreviousValue(router.latestLocation.href);

  if (prevLocation && prevLocation !== router.latestLocation.href && prevLocation.includes(ROUTES_PATHS.TICKET_URL)) {
    handleTicketUnlock();
  }

  useEffect(
    () => () => {
      const isValid = checkRequiredFieldsValidation(srId);
      reportValidationIssue(isValid);
    },
    [srId, checkRequiredFieldsValidation, reportValidationIssue],
  );

  useEffect(() => {
    if (error?.status === 422) {
      handleMigrationFailed(srId);
    } else if (error?.status === 403) {
      dispatch(setError(ERROR_TYPES.NO_VIEW_PERMISSIONS_TICKET));
    } else if ([404, 500].includes(error?.status)) {
      dispatch(setError(ERROR_TYPES.PAGE_NOT_FOUND));
    }
  }, [error, dispatch, srId, handleMigrationFailed]);

  const [srPanelCollapsed, setSrPanelCollapsed] = useState(false);
  const isHorizontalView = isNavBarCollapsed ? isNavBarClosedHorizontalView : isNavBarOpenHorizontalView;

  useEffect(() => {
    if (!isHorizontalView && srPanelCollapsed) setSrPanelCollapsed(false);
  }, [isHorizontalView, srPanelCollapsed]);

  const toggleSrPanelCollapse = useCallback(() => {
    setSrPanelCollapsed((prev) => !prev);
  }, []);

  const isExistingSR = getIsExistingSr(srId);
  const isPreviewSR = getIsPreviewSr(srId);

  useEffect(() => {
    if (isExistingSR && srObject?.id) {
      dispatch(setSrDetailsForSummary({ srId: srObject.id, title: srObject.title }));
    }
  }, [dispatch, isExistingSR, srObject?.id, srObject?.title]);

  const { mutateAsync: saveSR, isLoading: isSaveSrLoading } = useSaveSR(srId);
  const {
    data: { deescalationPromptEnabled, manualPriorityChangesEnabled },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  const fieldAttributesInput = useMemo(() => [{ fieldId: STATUS_FIELD_ID }], []);
  const statusList = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;

  const saveExistingSR = useCallback(
    async (objectToUpdate, reasonOfUpdate) => {
      if (objectToUpdate.status) {
        const statusElement = statusList.find((el) => el.id === objectToUpdate.status);
        const srStatusElement = statusList.find((el) => el.id === srObject.status);
        const statusClass = statusElement?.valueClass;

        if (statusClass === SR_PANEL_CONSTANTS.CLOSED_CLASS) {
          focusOnLastResolution(statusElement);
          if (
            !resolutionAuditLog ||
            (resolutionAuditLog && srStatusElement.valueClass !== SR_PANEL_CONSTANTS.CLOSED_CLASS)
          ) {
            if ('status' in objectToUpdate) {
              delete objectToUpdate.status;
              objectToUpdate.journeyLogsDisable = true;
            }
          }
        }
      }
      const newAuditLogRes = await saveSR(
        { id: srId, requestParams: objectToUpdate, reason: reasonOfUpdate },
        {
          onSuccess: ({ data: updateData }, rest) => {
            if (typeof updateData === 'object' && updateData !== null) {
              if (!updateData.sr?.category) {
                //Setting up a "zombie" category
                if (updateData.sr) {
                  updateData.sr.category = {
                    primaryCategory: updateData.sr?.primaryCategory,
                    secondaryCategory: updateData.sr?.secondaryCategory,
                    thirdLevelCategory: updateData.sr?.thirdLevelCategory,
                  };
                }
              }

              const shouldDeescalate = shouldShowDeEscalationPrompt(
                updateData.sr,
                srObject,
                objectToUpdate,
                deescalationPromptEnabled,
                manualPriorityChangesEnabled,
              );
              if (shouldDeescalate) {
                dispatch(updateEscalationPrompt({ show: true, srId: srObject.id }));
              }
              queryClient.setQueryData(['srId', srId], (oldTicket) => {
                const updatedTicket = {
                  ...rest?.requestParams,
                  ...oldTicket,
                  ...updateData.sr,
                  deescalation: shouldDeescalate,
                };
                return updatedTicket;
              });
              updateAuditLogs({ log: updateData.newAuditLogRecords });
            }
          },
          onError: () => {
            // TODO: handle error
            refetchSR();
          },
        },
      );

      return newAuditLogRes;
    },
    [
      saveSR,
      srId,
      statusList,
      focusOnLastResolution,
      resolutionAuditLog,
      dispatch,
      srObject,
      deescalationPromptEnabled,
      manualPriorityChangesEnabled,
      queryClient,
      updateAuditLogs,
      refetchSR,
    ],
  );

  const handleSaveSR = useCallback(
    async (updateObj, reasonOfUpdate) => {
      setAreFieldsChanged(true);
      if (!isSaveSrLoading) {
        await saveExistingSR(updateObj, reasonOfUpdate);
      }
    },
    [isSaveSrLoading, saveExistingSR],
  );

  const handleOkClick = () => {
    if (!isSrSaved) {
      dispatch(resetSrSaved());
    }
    if (isSaveFieldsPermissionError) {
      dispatch(resetSrSaveFieldsPermissionError());
      refetchSR();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.stopImmediatePropagation();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    dispatch(removePrimaryCategories());
    dispatch(removeSecondaryCategories());
    dispatch(removeThirdLevelCategories());
    const cleanup = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);

      dispatch(resetAuditLog());
      dispatch(clearActiveUploads());
      dispatch(resetFieldValidationStatus());
    };
    return cleanup;
  }, [dispatch]);

  const scrollToAuditLogRecord = useCallback((id) => {
    auditLogsRef?.current?.[id]?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }, []);

  const srPanelComponent = useMemo(
    () => (
      <SrPanel
        sr={srObject}
        isNavBarCollapsed={isNavBarCollapsed}
        srPanelCollapsed={srPanelCollapsed}
        toggleSrPanelCollapse={toggleSrPanelCollapse}
        handleSaveSR={handleSaveSR}
        template={templateData}
        hasEditPermissions={hasEditPermissions}
        isArchived={isArchived}
      />
    ),
    [
      srObject,
      isNavBarCollapsed,
      srPanelCollapsed,
      toggleSrPanelCollapse,
      handleSaveSR,
      templateData,
      hasEditPermissions,
      isArchived,
    ],
  );

  const isLoading = !isSrFetched || forceReload;

  useEffect(() => {
    if (!isLoading) {
      performance.mark(PERFORMANCE_MEASURE.TICKET.OPEN_FORM);
      const startTime = performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.OPEN_EXISTING_TICKET)[0]?.startTime;
      const endTime = performance.getEntriesByName(PERFORMANCE_MEASURE.TICKET.OPEN_FORM)[0]?.startTime;
      const duration = endTime - startTime;
      performanceMeasureEventReport(PERFORMANCE_MEASURE.EVENTS.OPEN_EXISTING_TICKET_FORM, {
        tenantId: localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
        duration,
      });
    }
  }, [isLoading, performanceMeasureEventReport]);

  useLinkedSrLists({
    sr: srObject,
    fields,
    dependantFields: template?.data?.dependantFields,
    updateSrFunc: handleSaveSR,
  });

  return isLoading ? (
    <StyledCircularProgress>
      <CircularProgress size="40px" />
    </StyledCircularProgress>
  ) : (
    <>
      {srObject && (
        <StyledTicketPanel data-testid="ticket-panel" isArchived={isArchived}>
          {isArchived && <div className="archived-banner">{archivedBannerText}</div>}
          {!isSrSaved && (
            <FieldsNotSavedPrompt
              onOkClick={handleOkClick}
              message={isSaveFieldsPermissionError ? notSavedByPermissionMessage : notSavedModalMessage}
            />
          )}
          <StyledHeaderContainer isArchived={isArchived}>
            <Header
              isHorizontalView={isHorizontalView}
              srObject={srObject}
              srTypeValue={srObject?.srType}
              handleSaveSR={handleSaveSR}
              template={templateData}
              isNewSR={!isExistingSR}
              isValidTicket={isValidTicket}
              hasEditPermissions={hasEditPermissions}
              onTicketStatusChange={onTicketStatusChange}
              isTicketPanel
              isArchived={isArchived}
            />
          </StyledHeaderContainer>
          {showTicketAsTab ? (
            <ResolutionPanel
              srType={srObject?.srType}
              srPanelCollapsed={srPanelCollapsed}
              showTicketAsTab={showTicketAsTab}
              scrollToAuditLogRecord={scrollToAuditLogRecord}
              srPanelComponent={srPanelComponent}
              auditLogsRef={auditLogsRef}
              isArchived={isArchived}
            />
          ) : (
            <StyledMainTicketPanel isNavBarCollapsed={isNavBarCollapsed}>
              {srPanelComponent}
              <ResolutionPanel
                srType={srObject?.srType}
                srPanelCollapsed={srPanelCollapsed}
                showTicketAsTab={showTicketAsTab}
                scrollToAuditLogRecord={scrollToAuditLogRecord}
                auditLogsRef={auditLogsRef}
                isArchived={isArchived}
              />
            </StyledMainTicketPanel>
          )}
          {isPreviewSR && (
            <StyledPreviewModeMessageWrapper>
              <StatusMessage message={previewMode} AlertIcon={PreviewIcon} />
            </StyledPreviewModeMessageWrapper>
          )}
        </StyledTicketPanel>
      )}
      <ExternalModal url={classicSRUrl} open={!!classicSRUrl} onClose={handleModalClose} />
    </>
  );
});

const TicketPanel = () => (
  <CategoryProvider>
    <TicketPanelContainer />
  </CategoryProvider>
);
export default TicketPanel;
