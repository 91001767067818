type TimezoneKey = 
  | 'VST'
  | 'SST'
  | 'PST'
  | 'PLT'
  | 'PNT'
  | 'PRC'
  | 'PRT'
  | 'NST'
  | 'NET'
  | 'MIT'
  | 'JST'
  | 'IET'
  | 'HST'
  | 'EAT'
  | 'ECT'
  | 'EET'
  | 'CAT'
  | 'CET'
  | 'CNT'
  | 'CST'
  | 'BET'
  | 'BST'
  | 'ACT'
  | 'AET'
  | 'AGT'
  | 'ART'
  | 'AST';

export const timezoneMapping: Record<TimezoneKey, string> = {
  VST: 'Asia/Phnom_Penh',
  SST: 'Pacific/Guadalcanal',
  PST: 'America/Los_Angeles',
  PLT: 'Asia/Karachi',
  PNT: 'America/Phoenix',
  PRC: 'Asia/Shanghai',
  PRT: 'America/Puerto_Rico',
  NST: 'Pacific/Auckland',
  NET: 'Asia/Yerevan',
  MIT: 'Pacific/Apia',
  JST: 'Asia/Tokyo',
  IET: 'America/Indiana/Indianapolis',
  HST: 'Pacific/Honolulu',
  EAT: 'Africa/Nairobi',
  ECT: 'Europe/Paris',
  EET: 'Europe/Bucharest',
  CAT: 'Africa/Gaborone',
  CET: 'Europe/Berlin',
  CNT: 'America/St_Johns',
  CST: 'America/Chicago',
  BET: 'America/Sao_Paulo',
  BST: 'Asia/Dhaka',
  ACT: 'Australia/Adelaide',
  AET: 'Australia/Sydney',
  AGT: 'America/Argentina/Buenos_Aires',
  ART: 'Africa/Cairo',
  AST: 'America/Anchorage',
};
  
export enum DisplayUsers {
  SubmitUser = 'submitUser',
  RequestUser = 'requestUser',
  RequestUserManagerName = 'requestUser.userManagerName',
}

export const displayUsersArray: DisplayUsers[] = [
  DisplayUsers.SubmitUser,
  DisplayUsers.RequestUser,
  DisplayUsers.RequestUserManagerName,
];

export enum PlaceholderValues {
  NotAvailable = 'N/A',
  CreationTimestamp = '[creation_timestamp]',
  ClosingTimestamp = '[closing_timestamp]',
  Select = 'Select',
  TypeHere = 'Type here',
  NoEditingPermissions = 'No editing permissions'
}
