import { Box, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== 'fieldWidth' })(({ fieldWidth }) => ({
  flexBasis: fieldWidth,
  paddingLeft: '16px',

  '& .dueDateField': {
    margin: '2px 0 0',
  },
}));

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => !['fieldWidth', 'isTemplatePage', 'isActiveField', 'isLinkField', 'isDraggable'].includes(prop),
})(({ theme, fieldWidth, isTemplatePage, isActiveField, isLinkField, isDraggable }) => ({
  flexBasis: fieldWidth,
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
  '.drag-icon': {
    position: 'absolute',
    zIndex: 999,
    width: '16px',
    height: '16px',
    top: isLinkField ? '36px' : '28px',
    left: '5px',
    opacity: 0,
  },
  '&.field-container .field-wrapper .richtext-wrap': {
    border: isTemplatePage ? 'none' : 'inherit',
  },
  '&:has(.multi-select-field-wrapper)': {
    '.drag-icon': {
      top: '28px',
    },
  },
  ':hover': isDraggable ? {
    '.drag-icon': {
      opacity: 1,
    },
    '.link-item': {
      paddingLeft: isTemplatePage ? '22px' : '0px',
    },
    '&:has(.multi-select-field-wrapper)': {
      '&:has(.multi-select-dropdown)': {
        '.drag-icon': {
          opacity: 0,
        },
      },
      '&:has(.MuiChip-root)': {
        '.drag-icon': {
          top: '30px',
        },
      },
      '& .multi-select-list-wrapper': {
        paddingLeft: '4px',
      },
    },
    '.field-wrapper': {
      '>div:nth-of-type(2), .empty-dueDateField': {
        paddingLeft: '24px',
      },
      '.empty-dueDateField': {
        background: !isLinkField && theme?.palette?.background?.bg_hover_main,
        borderLeft: isActiveField ? '2px solid #699D8C' : '',
        boxShadow: isActiveField ? '4px 4px 4px rgba(208, 231, 223, 0.5)' : 'none',
        '&:has(.MuiButtonBase-root, .MuiFormControl-root):not(:has(.rephrase-toolbar))': {
          paddingLeft: !isLinkField ? '22px' : '0',
          background: !isLinkField && theme?.palette?.background?.bg_hover_main,
        },
        '&:has(> .MuiFormControl-root .MuiInputBase-multiline, .Mui-focused)': {
          boxShadow: 'none',
          borderLeft: 'initial',
          border: '1px solid transparent',
          ' .MuiInput-root': {
            padding: '6px 8px',
            border: `1px solid ${theme.palette.generic.sysaid_default}`,
          },
        },
        ' .MuiButtonBase-root': {
          background: theme?.palette?.background?.bg_hover_main,
        },
      },
      '.multi-select-field-wrapper': {
        '&:has(.multi-select-dropdown)': {
          paddingLeft: '0px',
          background: theme.palette.generic.primary,
        },
        paddingLeft: '22px',
        background: theme?.palette?.background?.bg_hover_main,
        borderLeft: isActiveField ? `4px solid ${theme?.palette?.generic?.sysaid_default}` : theme.shape.border.border,
      },
      '.fr-element': {
        backgroundColor: isTemplatePage ? theme?.palette?.background?.bg_hover_main : theme?.palette?.view.main,
      },
      '.editor-header .action-icon-btn': {
        backgroundColor: isTemplatePage ? theme?.palette?.background?.bg_hover_main : theme?.palette?.view.main,
      },
    },
  } : {
    '.field-wrapper': {
      '.ticket-select-button': {
        background: theme.palette.background?.bg_hover_main,
      },
    },
  },
  '.field-wrapper': {
    '>div:nth-of-type(2), .empty-dueDateField': {
      background: !isLinkField && theme.palette.generic.primary,
      '&:has(> .MuiInputBase-multiline, .Mui-focused)': {
        border: '1px solid transparent',
        '&:not(:hover)': {
          boxShadow: 'none',
          ' .MuiInput-root': {
            padding: '6px 8px',
            border: `1px solid ${theme.palette.generic.sysaid_default}`,
          },
        },
      },
      border: isActiveField ? `1px solid ${theme.palette.generic.sysaid_default}` : theme.shape.border.border,
      boxShadow: isActiveField ? '4px 4px 4px rgba(208, 231, 223, 0.5)' : 'none',
    },
    '.multi-select-field-wrapper': {
      '&:has(.multi-select-dropdown)': {
        border: `1px solid ${theme.palette.generic.sysaid_default}`,
        boxShadow: '4px 4px 4px rgba(208, 231, 223, 0.5)',
        borderRadius: '4px',
        '.MuiButtonBase-root': {
          justifyContent: 'flex-start',
        },
      },
      border: isActiveField ? `1px solid ${theme.palette.generic.sysaid_default}` : theme.shape.border.border,
      boxShadow: isActiveField ? '4px 4px 4px rgba(208, 231, 223, 0.5)' : 'none',
      borderRadius: '4px',
      '>div:first-of-type': {
        border: 'none',
      },
    },
  },
  '.MuiButtonBase-root': {
    justifyContent: 'space-between',
    '&:not:has(.multi-select-field-wrapper)': {
      color: theme?.palette?.text?.title_txt,
      borderRadius: theme.shape.border.borderRadius,
      padding: '6px 8px',
      width: '100%',
    },
  },
  '.MuiBox-root': {
    maxWidth: '100%',
  },
  '.ticket-select-button': {
    border: theme.shape.border.border,
    borderRadius: theme.shape.border.borderRadius,
    marginLeft: '5px',
    height: isTemplatePage ? '36px' : '31px',
    padding: isTemplatePage ? '6px 8px' : 'inherit',
    width: '350px',
  },
}));

export const ClearFieldValue = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '-1px',
  lineHeight: '16px',
  fontSize: '12px',
  color: theme.palette.grey.bt_def_grey_light,
  cursor: 'pointer',
}));

export const FieldComponentStyledContainer = styled(Box)(({ theme }) => ({
  '.ticket-select-button': {
    width: 'unset',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '>div:nth-of-type(2), .empty-dueDateField': {
    transform: 'translateX(0)!important',
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
    borderRadius: theme.shape.border.borderRadius,
    marginLeft: '0',
    width: '100%',
    'MuiButtonBase-root, button, .MuiFormControl-root': {
      border: 'none',
      '&:hover': {
        border: 'none',
      },
    },
  },
  '.attachment-chip': {
    width: '190px',
  },
}));

const BlockWrapperIgnoreProps = ['halfRow', 'isTemplatePage', 'isDescriptionField'];
export const BlockWrapper = styled(Stack, {
  shouldForwardProp: (prop) => !BlockWrapperIgnoreProps.includes(prop),
})(({ halfRow, isTemplatePage, theme }) => {
  let flexBasis = halfRow ? '49%' : '100%';
  let marginBottom = '12px';
  let width = '49%';
  let position = 'static';

  if (isTemplatePage) {
    flexBasis = 'auto';
    marginBottom = '11px';
    width = halfRow ? 'calc(50% - 21px)' : '100%';
    position = 'relative';
  }

  return {
    flexBasis,
    marginBottom,
    width,
    position,
    borderRadius: '4px',

    '.DeleteIcon': {
      opacity: '0',
      padding: '3px',
      borderRadius: '4px',
      ':hover': {
        background: theme?.palette?.background?.grey_hover,
      },
    },
    '.multi-select-field-wrapper': {
      marginLeft: isTemplatePage ? '' : '-8px',
    },
    '& .multi-select-list-wrapper': {
      minHeight: '24px',
    },
    '&:hover': {
      ' .DeleteIcon': {
        opacity: '1',
      },
    },
    '&:last-child': {
      marginBottom: '0px',
    },
  };
});

export const DeleteIconWrapper = styled('div')(() => ({
  position: 'absolute',
  zIndex: '99',
  right: '-29px',
  top: '14px',
  padding: '8px 0px 8px 6px',
  '& .DeleteIcon': {},
}));

FieldComponentStyledContainer.defaultProps = {
  width: '100%',
};

BlockWrapper.defaultProps = {
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};
