import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { TICKETS_OPEN_URL, APP_CONSTANTS } from 'constants';
import { DropdownSelect, itemClassNames, useDropdownControl } from 'common/components/dropdownSelect';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import Tooltip from 'common/components/tooltip';
import { handleLocalStorageSaveItem } from 'common/utils/utils';
import { getFieldAttributes } from 'services/ticketService';
import {
  PARENT_STATUS_CHANGE_POPUP,
  SR_TO_INCIDENT_STATUS_CHANGE_POPUP,
  UNCHANGED_CHILD_STATUS_POPUP,
} from 'features/resolutionPanel/middlePanel/relatedItems/constants';
import { updateSRStatus } from 'store/srSlice';
import { useHasLinkedIncidents } from 'features/resolutionPanel/middlePanel/relatedItems/hooks/useRelatedItems';
import useTexts from './useTexts';
import { Label } from './style';
import MessagePrompt from '../messagePrompt';
import {
  changeStatusPopupStyles,
  handleLinkedIncidentsStautsChangePopupTexts as handlePopupTexts,
  shouldShowLinkedIncidentsStautsChangePopup,
} from './utils';

function Status(props) {
  const {
    fieldId,
    status,
    statusList,
    onFocus,
    isTemplatePage,
    dataset = {},
    disabled,
    onDisabledClick,
    srType,
    handleDirty,
    isFloatingTicketPanel,
    updateFieldValidation,
    onChange,
    dataTestid = 'status',
    forceStopuseStateStore = false,
    srId,
    srTypeText,
    isChildSr,
    srParentId,
    childSrsCount,
  } = props;
  const dispatch = useDispatch();
  const {
    statusRequiredFieldsTooltipMessage,
    emptyStatusPlaceholder,
    mandatoryFieldError,
    unchangedStatusTitle,
    unchangedStatusDecription,
    unchangedStatusOkBtn,
    unchangedStatusCancelBtn,
    parentStatusChangeTitle,
    parentStatusChangeDescription,
    parentStatusChangeOkBtn,
    parentStatusChangeCancelBtn,
    linkedIncidentsStatusChangeTitle,
    linkedIncidentsStatusChangeDescription,
  } = useTexts();
  const [promptProperties, setPromptProperties] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const isError = !status && !isTemplatePage;
  const srStatus = statusList?.find((stsItem) => Number(stsItem.id) === Number(status));
  const { statusSettings, linkedIncidentsCount } = useHasLinkedIncidents(srId, srType);

  const handleFocus = useCallback(() => {
    onFocus(fieldId);
  }, [onFocus, fieldId]);

  const { anchor, handleOpen, handleClose } = useDropdownControl(disabled, handleFocus);
  const { data } = useGetListValueById({ listKey: fieldId, list: statusList, id: status });

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      if (!value) {
        isValid = false;
      }

      if (updateFieldValidation && !isTemplatePage) {
        updateFieldValidation('status', true, isValid);
      }
      return isValid;
    },
    [isTemplatePage, updateFieldValidation],
  );

  useEffect(() => {
    if (!forceStopuseStateStore) {
      dispatch(updateSRStatus(data));
    }
    const identifier = data?.id || data?.valueKey || null;
    checkAndUpdateValidationStatus(identifier);
  }, [data, checkAndUpdateValidationStatus, dispatch, forceStopuseStateStore]);

  const handleChange = useCallback(
    (value) => {
      const identifier = value.id || value.valueKey;
      checkAndUpdateValidationStatus(identifier);
      if (handleDirty) handleDirty({ status: Number(identifier) });
      if (!forceStopuseStateStore) {
        dispatch(updateSRStatus(value));
      }
      if (onChange) {
        onChange(value);
      }
      handleClose();
    },
    [checkAndUpdateValidationStatus, handleDirty, onChange, handleClose, dispatch, forceStopuseStateStore],
  );

  const handleCheckboxChange = () => {
    handleLocalStorageSaveItem({ attributeName: promptProperties?.popupName, value: !isChecked });
    setIsChecked((prevState) => !prevState);
  };

  const handleClosePrompt = () => {
    setPromptProperties({ ...promptProperties, open: false });
  };

  const handleNavigateToParentSr = () => {
    const parentSrUrl = TICKETS_OPEN_URL.SPACES(srParentId);
    window.open(parentSrUrl, '_blank', 'noreferrer');
    handleClosePrompt();
  };

  const handleChangeStatus = (value) => {
    // check to show parent/child status change prompt
    const dontShowAgainData = JSON.parse(localStorage.getItem(APP_CONSTANTS.DONT_SHOW_AGAIN)) || {};
    const showPrompt = !dontShowAgainData?.[PARENT_STATUS_CHANGE_POPUP];
    if (childSrsCount && showPrompt) {
      setPromptProperties({
        open: showPrompt,
        popupName: PARENT_STATUS_CHANGE_POPUP,
        title: parentStatusChangeTitle,
        description: parentStatusChangeDescription.replace('__childSrCount__', childSrsCount),
        showIcon: true,
        okBtnText: parentStatusChangeOkBtn,
        okBtnAction: () => {
          handleClosePrompt();
          handleChange(value);
        },
        cancelBtnText: parentStatusChangeCancelBtn,
        cancelBtnAction: () => {
          handleClosePrompt();
          handleClose();
        },
        style: changeStatusPopupStyles[PARENT_STATUS_CHANGE_POPUP],
      });
      return;
    }
    // check to show linked incidents status change prompt
    const showLinkedIncidentsStatusChangePopup = shouldShowLinkedIncidentsStautsChangePopup({
      linkedIncidentsCount,
      statusSettings,
      value,
    });
    if (showLinkedIncidentsStatusChangePopup) {
      const { title, description } = handlePopupTexts({
        statusSettings,
        value,
        statusList,
        linkedIncidentsStatusChangeTitle,
        linkedIncidentsStatusChangeDescription,
        srTypeText,
        linkedIncidentsCount,
      });
      setPromptProperties({
        open: showLinkedIncidentsStatusChangePopup,
        popupName: SR_TO_INCIDENT_STATUS_CHANGE_POPUP,
        title,
        description,
        showIcon: true,
        okBtnText: parentStatusChangeOkBtn,
        okBtnAction: () => {
          handleClosePrompt();
          handleChange(value);
        },
        cancelBtnText: parentStatusChangeCancelBtn,
        cancelBtnAction: () => {
          handleClosePrompt();
          handleClose();
        },
        style: changeStatusPopupStyles[SR_TO_INCIDENT_STATUS_CHANGE_POPUP],
      });
      return;
    }
    handleChange(value);
  };

  const handleClear = useCallback(() => {
    checkAndUpdateValidationStatus(null);
    if (!forceStopuseStateStore) {
      dispatch(updateSRStatus(null));
    }
    if (onChange) {
      onChange({ id: null });
    }
    handleClose();
  }, [onChange, handleClose, dispatch, checkAndUpdateValidationStatus, forceStopuseStateStore]);

  const onOpen = (event) => {
    if (isChildSr && !!srParentId) {
      const dontShowAgainData = JSON.parse(localStorage.getItem(APP_CONSTANTS.DONT_SHOW_AGAIN)) || {};
      const showPrompt = !dontShowAgainData?.[UNCHANGED_CHILD_STATUS_POPUP];
      setPromptProperties({
        open: showPrompt,
        popupName: UNCHANGED_CHILD_STATUS_POPUP,
        title: unchangedStatusTitle,
        description: unchangedStatusDecription.replace('__srId__', srParentId),
        okBtnText: unchangedStatusOkBtn,
        okBtnAction: handleNavigateToParentSr,
        cancelBtnText: unchangedStatusCancelBtn,
        cancelBtnAction: handleClosePrompt,
        showIcon: false,
        style: changeStatusPopupStyles[UNCHANGED_CHILD_STATUS_POPUP],
      });
    }
    if (!disabled) {
      handleOpen(event);
    } else if (onDisabledClick) onDisabledClick();
  };

  const getTooltipText = () => {
    let text = '';
    if (isError) {
      text = mandatoryFieldError;
    } else if (disabled) {
      text = statusRequiredFieldsTooltipMessage;
    } else if (srStatus?.valueClass) {
      text = srStatus.valueClass;
    }
    return text;
  };

  const selection = useMemo(() => (srStatus?.id || srStatus?.id === 0 ? [srStatus?.id] : []), [srStatus?.id]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getFieldAttributes(fieldId, { query, srType }),
    }),
    [fieldId, srType],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(srStatus?.value || null);
  }, [checkAndUpdateValidationStatus, srStatus]);

  return (
    <>
      {promptProperties?.open && (
        <MessagePrompt
          open
          title={promptProperties?.title}
          onOkClick={promptProperties?.okBtnAction}
          btnOkText={promptProperties?.okBtnText}
          onClose={promptProperties?.cancelBtnAction}
          btnCancelText={promptProperties?.cancelBtnText}
          showCancelBtn
          showIcon={promptProperties?.showIcon}
          showDontShowMeAgain
          isCheckboxChecked={isChecked}
          onToggleCheckbox={handleCheckboxChange}
          style={promptProperties?.style}
        >
          {promptProperties?.description}
        </MessagePrompt>
      )}
      <Label
        marginLeft={Boolean(isTemplatePage || isFloatingTicketPanel) && '12px'}
        stylingVariant={itemClassNames.status}
        color={srStatus?.valueClass}
        onClick={onOpen}
        isTemplatePage={isTemplatePage}
        isReadOnly={disabled}
        data-testid={dataTestid}
        isEmpty={isEmpty(srStatus)}
        data-cy={`status-item-${selection}`}
        {...dataset}
      >
        <Tooltip
          isError={isError}
          title={getTooltipText()}
          text={srStatus?.value || srStatus?.valueCaption || emptyStatusPlaceholder}
          forceShowOnHover={disabled || isError}
          isTruncatedText
          placement="top"
          style={{ fontFamily: 'Roboto-Bold' }}
        />
      </Label>

      <DropdownSelect
        fieldName="status"
        options={statusList}
        handleChange={handleChangeStatus}
        handleClose={handleClose}
        selection={selection}
        stylingVariant={itemClassNames.status}
        anchor={anchor}
        backendQueryConfig={backendQueryConfig}
        handleClearValue={handleClear}
        isActionsHidden={!isTemplatePage}
      />
    </>
  );
}

export default Status;
