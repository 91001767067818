import { forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { QUEUE } from 'features/queue/constants';
import { StyledMenuItem } from 'common/components/dropdownSelect/SelectItem/SelectItem.styles';
import { InfoTypography } from 'common/components/dropdownSelect/InfoTypography';
import { selectActiveUser, details } from 'store/userSlice';
import { AssigneeItem } from '../../../assigneeItem';
import useTexts from '../../useTexts';
import { useScrollbar } from '../useScrollbar';

export const AdminList = forwardRef((props, ref) => {
  const {
    adminsList,
    handleChange,
    currentAssigned,
    isSearching,
    isEmptyResults,
    maxHeight,
    showCheckboxes,
    filteredAssignees,
    required,
  } = props;
  const userAccount = useSelector(selectActiveUser);
  const userDetails = useSelector(details);
  const { unassignedText, loginUserText, emptyResultsText } = useTexts();
  const { Container, scrollProps } = useScrollbar(maxHeight);

  const checkIfSelected = (userName) => {
    let isSelected;
    if (currentAssigned) {
      isSelected =
        userName === currentAssigned.admin?.userName &&
        (currentAssigned.group?.groupName == null || currentAssigned.group?.groupName === 'none');
    } else if (filteredAssignees && filteredAssignees.length && typeof filteredAssignees[0] === 'string') {
      isSelected = !!filteredAssignees.find((o) => o.userName === userName);
    } else if (filteredAssignees) {
      isSelected = !!filteredAssignees.find((o) => o.userName === userName);
    }
    return isSelected;
  };

  const handleSelectUser = (admin) => {
    handleChange({ admin });
  };

  const renderUnassignedOption = () => {
    if (!required && isSearching) {
      return (
        <StyledMenuItem
          className="option"
          key="-1"
          value="-1"
          onClick={() => handleSelectUser(QUEUE.unassigned)}
          selected={checkIfSelected(QUEUE.unassigned.userName)}
        >
          <AssigneeItem
            text={unassignedText}
            type="unassigned"
            isSelected={checkIfSelected(QUEUE.unassigned.userName)}
            showCheckbox={showCheckboxes}
          />
        </StyledMenuItem>
      );
    }
  };
  const renderLoginUserOption = () => {
    if (isSearching) {
      return (
        <StyledMenuItem
          className="option"
          key={`${userAccount?.username}_u`}
          value={userAccount?.calculatedUserName}
          data-testid={userAccount?.calculatedUserName}
          onClick={() => handleSelectUser(userDetails)}
          selected={checkIfSelected(userAccount?.username)}
        >
          <AssigneeItem
            text={`${userAccount?.calculatedUserName} (${loginUserText})`}
            profileImage={userDetails?.profileImage}
            type="admin"
            isSelected={checkIfSelected(userAccount?.username)}
            showCheckbox={showCheckboxes}
          />
        </StyledMenuItem>
      );
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getAllAdmins() {
        return !isSearching ? adminsList : [...adminsList, userDetails, QUEUE.unassigned];
      },
    }),
    [adminsList, userDetails, isSearching],
  );

  return (
    <>
      {isEmptyResults ? (
        <InfoTypography message={emptyResultsText} />
      ) : (
        <Container {...scrollProps}>
          <div className="options_wrapper">
            {renderLoginUserOption()}
            {renderUnassignedOption()}
            {adminsList
              .filter(
                isSearching ? (option) => option.calculatedUserName !== userAccount?.calculatedUserName : () => true,
              )
              .map((option) => {
                const text =
                  option?.userName === userAccount?.username
                    ? `${userAccount?.calculatedUserName} (${loginUserText})`
                    : option?.calculatedUserName;
                return (
                  <StyledMenuItem
                    className="option"
                    key={`${option?.userName}_u`}
                    value={option?.userName}
                    data-testid={option?.userName}
                    onClick={() => handleSelectUser(option)}
                    selected={checkIfSelected(option?.userName)}
                  >
                    <AssigneeItem
                      text={text}
                      profileImage={option?.profileImage}
                      type="admin"
                      showCheckbox={showCheckboxes}
                      isSelected={checkIfSelected(option?.userName)}
                    />
                  </StyledMenuItem>
                );
              })}
          </div>
        </Container>
      )}
    </>
  );
});
