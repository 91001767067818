import { PlaceholderValues } from 'common/utils/constants';
import { StyledTextField } from '../styles';

export default function TextField({
  name,
  value,
  placeholder = PlaceholderValues.NotAvailable,
  type = 'text',
  InputProps,
  handleChange,
  ...restProps
}) {
  return (
    <StyledTextField
      id={`${name}-input`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        handleChange?.(e.target.value, name);
      }}
      name={name}
      autoComplete="off"
      type={type}
      InputProps={{
        ...InputProps,
        'data-testid': `${name}-input`,
      }}
      {...restProps}
    />
  );
}
