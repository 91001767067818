import { createSlice } from '@reduxjs/toolkit';
import { sliceNames } from './constants';

const initialState = {
  userActivity: null,
  msgBox: { text: '' },
  lastUserActivity: null,
};

export const keepAliveSlice = createSlice({
  name: sliceNames.keepAliveSliceName,
  initialState,

  reducers: {
    setUserActivity: (state, { payload }) => {
      state.userActivity = payload;
    },
    showMsg: (state, action) => {
      state.msgBox = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
  },
});

export const { setUserActivity, showMsg, setIsAuthorized } = keepAliveSlice.actions;

export const lastUserActivity = (state) => state?.keepAlive?.userActivity;

export default keepAliveSlice.reducer;
export const keepAliveSliceName = keepAliveSlice.name;
