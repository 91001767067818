import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToasterMessage = {
  top: '-44vh',
};

export const StyledTicketPanel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isArchived',
})(({ theme, isArchived }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.pages_background,
  overflowY: 'auto',

  '& .middle-header': {
    marginTop: '2px',
  },

  '& .assignee-tooltip-wrapper': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  ...(isArchived && {
    '&::before': {
      ...theme?.mixins?.archivedOverlay,
      zIndex: 'unset',
    },

    '.archived-banner': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      width: '100%',
      height: '30px',
      fontSize: '12px',
      backgroundColor: theme?.palette?.generic?.secondary,
      color: theme?.palette?.generic?.primary,
    },
  }),
}));

export const StyledCircularProgress = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCircularProgress-root': {
    color: theme.palette.generic.grey_600,
  },
}));
