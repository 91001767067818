import { isEmpty } from 'lodash';
import { IntegrationTypes } from './IntegrationSection.consts'
import { REQUIRED_JIRA_FIELDS, JIRA_FIELD_COUNT, SINGLE_ROW_COUNT } from './components/jira/jira.consts';

const hasExactJiraFields = (fields) => {
  if (fields?.length !== JIRA_FIELD_COUNT) return false;

  return REQUIRED_JIRA_FIELDS.every((requiredField, index) =>
    fields[index]?.fieldName === requiredField
  );
};

export const hasOnlyJiraIntegrationFields = (section) => {
  if (!section) return false;

  // Handle SR section (has sectionRows structure)
  if (!isEmpty(section.sectionRows)) {
    const firstRow = section.sectionRows[0];
    return section.sectionRows.length === SINGLE_ROW_COUNT && hasExactJiraFields(firstRow?.fields);
  }

  // Handle Template section (has fields structure)
  return hasExactJiraFields(section.fields);
};

const SECTION_CONDITIONS = [
  {
    test: hasOnlyJiraIntegrationFields,
    value: IntegrationTypes.JIRA,
  },
];

const checkConditions = (conditions, input) => {
  for (const condition of conditions) {
    if (condition.test(input)) {
      return condition.value;
    }
  }
  return null;
};

export const isIntegrationSection = (section) =>
  checkConditions(SECTION_CONDITIONS, section);
