import { styled } from '@mui/material/styles';
import Box from '@mui/material/ListItem';

export const StyledIntegrationsList = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  width: 'auto',
  margin: '8px 24px',
  fontSize: theme.fontSize.main,
  border: theme.shape.border.border,
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.border.borderRadius,
  color: theme?.palette?.text?.title_txt,

  '& .text-element': {
    paddingLeft: '8px',
  },
  '& .editor-element': {
    paddingRight: '8px',
  },

}));
