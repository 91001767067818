const PREFIX_KEY = 'category';
const FIRST_LEVEL_KEY = 'firstLevelKey';
const SECOND_LEVEL_KEY = 'secondLevelKey';
const THIRD_LEVEL_KEY = 'thirdLevelKey';

export const CATEGORIES = {
  PREFIX_KEY,
  FIRST_LEVEL_KEY,
  SECOND_LEVEL_KEY,
  THIRD_LEVEL_KEY,
  LAST_CHANGED_SR_ID: 'lastChangedSrId',
  FIRST_LEVEL_CATEGORY_FIELD_KEY: `${PREFIX_KEY}.${FIRST_LEVEL_KEY}`,
  SECOND_LEVEL_CATEGORY_FIELD_KEY: `${PREFIX_KEY}.${SECOND_LEVEL_KEY}`,
  THIRD_LEVEL_CATEGORY_FIELD_KEY: `${PREFIX_KEY}.${THIRD_LEVEL_KEY}`,
};

export const FIRST_LEVEL_CATEGORY_FIELD_ID = 59;
export const SECOND_LEVEL_CATEGORY_FIELD_ID = 60;
export const THIRD_LEVEL_CATEGORY_FIELD_ID = 61;

export const DELETED_CATEGORY_ID = 0;

export const TO_BE_DETERMINED = 'TO_BE_DETERMINED' // used to display empty category
