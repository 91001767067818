import axios from 'axios';
import { axiosInstance } from 'services/axiosBaseInstance';
import { clearStorage } from 'features/Logout/utils';
import { getCookie } from 'common/utils/utils';
import { APP_CONSTANTS } from '../constants';
import { store } from '../store/store';
import { logout } from '../store/userSlice';
import { FORGOT_PASSWORD_SUBDIRECTORY, LOGIN_SUBDIRECTORY } from '../constants/routes';

const source = axios.CancelToken.source();
const { dispatch } = store;

export const applyConfigInterceptors = (instance) => {
  instance.interceptors.response.use(
    // Do something with response data before sent it back
    (response) => response,
    (error) => {
      if (
        error?.response?.status === 401 &&
        window.location.pathname !== `${process.env.PUBLIC_URL}/${LOGIN_SUBDIRECTORY}`
      ) {
        const from = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
        const serverIdCookie = getCookie(APP_CONSTANTS.SERVER_ID);
        if (serverIdCookie !== undefined) {
          dispatch(logout());
        }
        clearStorage();

        if (window.location.pathname === `${process.env.PUBLIC_URL}/${FORGOT_PASSWORD_SUBDIRECTORY}`) return;

        if (serverIdCookie === undefined) {
          setTimeout(() => {
            window.location.href = '/';
          }, 50);
        } else if (window.location.pathname !== `${process.env.PUBLIC_URL}/${FORGOT_PASSWORD_SUBDIRECTORY}`) {
          setTimeout(() => {
            window.location.href = `${process.env.PUBLIC_URL}/${LOGIN_SUBDIRECTORY}?from=${from}`;
          }, 50);
        }
      } else if (error?.response?.status === 403) {
        const location = error.response.headers.location;
        if (location && window.location.href !== location) {
          window.location.href = location;
        }
      }

      return Promise.reject(error);
    },
  );
};

applyConfigInterceptors(axiosInstance);

export { axiosInstance, source };
