import { styled } from '@mui/material/styles';
import MuiTextField, { textFieldClasses } from '@mui/material/TextField';

export const StyledCommonTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    ![
      'unread',
      'error',
      'ticket',
      'fullwidth',
      'disabled',
      'isNewField',
      'isTemplatePage',
      'isLocked',
      'isTextAreaFocused',
      'isEmpty',
      'isTicketPage',
    ].includes(prop),
})(
  ({
    theme,
    unread,
    error,
    fullwidth,
    disabled,
    ticket,
    isNewField,
    isTemplatePage,
    multiline,
    isTextAreaFocused,
    isEmpty,
    isTicketPage,
  }) => {
    let background = 'inherit';
    const isPointerEventsOn = disabled === undefined || (!isTextAreaFocused && disabled && multiline);
    const pointerEventsWhenTemplatePage = isPointerEventsOn ? 'auto' : 'none';

    let color = theme?.palette?.text?.txt_default;

    if (unread) {
      color = theme?.palette?.text?.title_txt;
    }
    if (ticket) {
      color = theme?.palette?.text?.txt_default;
    }

    if (isNewField && !error && !isTemplatePage) {
      background = '#EBF5FF';
    }

    if (disabled && !error) {
      background = isTicketPage ? `${theme?.palette?.grey?.bt_alt_grey_light}!important` : 'transparent';
      color = theme.palette.text.secondary_txt;
    }

    const getHoveredBorderColor = () => {
      if (disabled) {
        if (isTicketPage) {
          return theme?.palette?.grey?.divider_grey_v1;
        }
        return 'transparent';
      }

      return isEmpty ? theme?.palette?.blueLabels?.bt_blue_chip_light : theme?.palette?.grey?.divider_grey_v1;
    };

    return {
      [`&.${textFieldClasses.root}`]: {
        background,
        width: fullwidth ? '100%' : 'min(100%, 224px)',
        borderRadius: '4px',
        border:
          // eslint-disable-next-line
          disabled && isTicketPage
            ? `1px solid ${theme?.palette?.grey?.divider_grey_v1}`
            : isEmpty && !disabled
            ? `1px solid ${theme?.palette?.blueLabels?.bt_blue_field_empty}`
            : '1px solid transparent',
        backgroundColor: isEmpty && !disabled ? theme?.palette?.blueLabels?.bt_blue_field_empty : 'transparent',
        scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
        scrollbarWidth: 'thin',

        '.MuiInputBase-root': {
          alignItems: multiline && 'flex-start',
        },
        '.Mui-disabled': {
          textFillColor: color,
        },
        '& .warning-icon': {
          margin: multiline ? '2px 2px 0' : '0px 2px',
          display: 'flex',
        },
        '&:hover': {
          '.MuiInput-input': {
            color: error && theme?.palette?.labels.bt_red_dark,
            textFillColor: error && theme?.palette?.labels.bt_red_dark,
            '&::placeholder': {
              color: error && theme?.palette?.labels.bt_red_dark,
              opacity: 1,
            },
          },
          '.Mui-disabled': {
            fontFamily: unread ? theme.fonts.robotoBold : theme.fonts.robotoRegular,
            color: error ? theme?.palette?.labels.bt_red_dark : theme.palette.text.secondary_txt,
            textFillColor: error ? theme?.palette?.labels.bt_red_dark : theme.palette.text.secondary_txt,
            pointerEvents: 'none!important',
          },
          ':not(.Mui-focused)': {
            // eslint-disable-next-line
            backgroundColor:
              isEmpty && !disabled ? theme?.palette?.blueLabels?.bt_blue_field_empty_hover : 'transparent',
            border: `1px solid ${getHoveredBorderColor()}`,
            color: error && theme.palette.labels.bt_red_dark,
            borderRadius: '4px',
            '.MuiInput-input': {
              color: isEmpty ? theme?.palette?.blueLabels?.bt_status_reg : error && theme?.palette?.labels.bt_red_reg,
            },
            ...(disabled
              ? {
                  cursor: 'default!important',
                }
              : {}),
          },
          '& .warning-icon *': {
            fill: theme.palette.labels.bt_red_dark,
          },
        },
        '.Mui-focused': {
          margin: isTextAreaFocused ? '0' : '-1px',
          background: '#fff',
          border: isTextAreaFocused ? 'none' : theme.shape.border.border,
          borderRadius: theme.shape.border.borderRadius,
          color: theme?.palette?.text?.txt_default,
          scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
          scrollbarWidth: 'thin',

          '.MuiInput-input': {
            color: theme?.palette?.text?.txt_default,
            textFillColor: theme?.palette?.text?.txt_default,

            '&::placeholder': {
              color: theme?.palette?.text?.txt_default,
            },
          },
          '*::-webkit-scrollbar': {
            width: '4px',
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            background: theme?.palette?.grey?.divider_grey_v1,
          },
          '*::-webkit-scrollbar-track': {
            marginTop: '2px',
            marginBottom: '2px',
          },
          '& span[data-error-tooltip=true], .warning-icon *': {
            display: 'none',
          },
        },
        '.MuiFormHelperText-root': {
          color: theme?.palette?.text?.secondary_txt,
          fontFamily: theme?.fonts.robotoRegular,
          border: 'none',
        },
        '.MuiInput-input': {
          padding: 0,
          textOverflow: 'ellipsis',
          height: isTemplatePage ? '22px' : 'auto',
          pointerEvents: isTemplatePage ? pointerEventsWhenTemplatePage : 'auto',
          color: isEmpty ? theme?.palette?.blueLabels?.bt_blue_chip_reg : error && theme?.palette?.labels.bt_red_reg,
          '&::placeholder': {
            // color: error && theme?.palette?.labels.bt_red_reg,
            opacity: 1,
          },
        },
        '.MuiInput-root': {
          maxWidth: '100%',
          padding: isTemplatePage ? '6px 8px' : '4px 8px',
          marginLeft: '0px',
          fontSize: '14px',
          lineHeight: '14px',
          fontFamily: unread ? theme.fonts.robotoBold : theme.fonts.robotoRegular,
          color: unread ? theme?.palette?.text?.title_txt : color,
          ...(disabled
            ? {
                ':hover': {
                  color: `${theme.palette.text.secondary_txt}!important`,
                  textFillColor: `${theme.palette.text.secondary_txt}!important`,
                },
              }
            : {}),
        },
        '*::-webkit-scrollbar': {
          width: '0',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          background: theme?.palette?.grey?.divider_grey_v1,
        },
        '*::-webkit-scrollbar-track': {
          marginTop: '2px',
          marginBottom: '2px',
        },
      },

      // Hide number field arrows
      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0',
      },
      /* Firefox */
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    };
  },
);
