import React from 'react';
import licenseSxpirationSummer from 'images/photos/license_expiration_summer.svg';
import licenseSxpirationFall from 'images/photos/license_expiration_fall.svg';
import licenseSxpirationSpring from 'images/photos/license_expiration_spring.svg';
import licenseSxpirationWinter from 'images/photos/license_expiration_winter.svg';

const seasonsImages = {
  spring: { image: licenseSxpirationSpring },
  summer: { image: licenseSxpirationSummer },
  fall: { image: licenseSxpirationFall },
  winter: { image: licenseSxpirationWinter },
};

const SeasonalImage = ({ season }) => {
  const { image } = seasonsImages[season];

  return <img src={image} alt={`screen decorated according to ${season} season`} className="seasonal-image" />;
};

export default SeasonalImage;
