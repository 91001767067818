import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 42px 24px 44px',
  gap: '10px',

  '.section-header': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    left: '0px',
    columnGap: '12px',
    '.MuiIconButton-root': {
      position: 'absolute',
      padding: '0',
      top: '3px',
      marginLeft: '-12px',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      transform: 'rotate(180deg)',
      '&.icon-collapse': {
        transform: 'rotate(0)',
      },
    },

    '.section-title': {
      maxWidth: '350px',
      marginLeft: '14px',

      '& .MuiInputBase-root': {
        marginLeft: '0px',
      },
    },
    '.delete-section-button': {
      color: theme.palette.labels.bt_red_reg,
      justifyContent: 'flex-end',
      maxWidth: '112px',
      width: '100%',
      marginRight: '-6px',
      opacity: 0,
    },
  },

  '.drag-icon-box': {
    position: 'absolute',
    marginLeft: '-36px',
    width: '36px',
    textAlign: 'center',
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '.drag-icon': {
    cursor: 'grab',
  },
  '&:hover': {
    '.drag-icon-box, .delete-section-button': {
      opacity: 1,
    },
  },
  '.collapse-box': {
    width: '100%',
  },
  '.section-fields-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    minHeight: '40px',
    '.MuiGrid-container': {
      marginTop: '0',
      marginLeft: '0',
      justifyContent: 'space-between',
      '#grid_item_dropHere': {
        paddingTop: '18px',
        '.drag-icon': {
          top: '10px'
        },
        '.form-field-placeholder': {
          color: theme.palette.generic.sysaid_mid
        }
      }
    },
    '.field-container': {
      borderRadius: theme.shape.border.borderRadius,
      '.MuiGrid-root': {
        flexBasis: '100%',
        '.editor-header .action-icon-btn': {
          right: '3px',
          top: '3px',
        },
      },
    },
    '.form-field-placeholder': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.fontSize.small,
      color: theme.palette.grey.bt_def_grey_light,
      background: theme.palette.generic.sysaid_very_light,
      border: `1px dashed ${theme?.palette?.generic?.sysaid_mid}`,
      borderRadius: theme.shape.border.borderRadius,
      flexBasis: '49%',
      marginBottom: '2%',
      minHeight: '36px',
    },
    '.drag-icon-box': {
      marginLeft: '-36px',
      width: '36px',
      textAlign: 'center',
      opacity: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '.drag-icon': {
      cursor: 'grab',
    },
    '&:hover': {
      '.drag-icon-box, .delete-section-button': {
        opacity: 1,
      },
    },
    '.collapse-box': {
      width: '100%',
    },
  },
}));
export const SectionFieldsAreaWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  '.MuiFormControl-root': {
    border: theme.shape.border.border,
    borderRadius: '4px',
    ':hover': {
      borderColor: theme.palette.generic.sysaid_default,
      background: theme?.palette?.background?.bg_hover_main,
    },
  },
  '.select_button': {
    maxWidth: '100%!important',
    border: theme.shape.border.border,
    borderRadius: theme.shape.border.borderRadius,
    transform: 'translateX(0)!important',
  },
  '.MuiGrid-item > div': {
    width: '100%',
    maxWidth: '100%',
  },
  '.editor-element.attachments-wrapper': {
    position: 'absolute',
  },
}));
