import { createSlice } from '@reduxjs/toolkit';
import { APP_CONSTANTS } from 'constants/app';
import { sliceNames } from './constants';

export const initialState = {
  theme: localStorage.getItem(APP_CONSTANTS.THEME) || 'light',
  toasterMessages: { message: null, type: 'success' },
  error: null,
  onPopupAdvanceMessage: false,
  navigationObject: null,
  direction: localStorage.getItem(APP_CONSTANTS.DIRECTION) || 'ltr',
  directionChanged: false,
};

export const globalSlice = createSlice({
  name: sliceNames.globalSliceName,
  initialState,

  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem(APP_CONSTANTS.THEME, action.payload);
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
      localStorage.setItem(APP_CONSTANTS.DIRECTION, action.payload);
    },
    setDirectionChanged: (state, action) => {
      state.directionChanged = action.payload;
    },
    setToasterMessage: (state, action) => {
      // eslint-disable-next-line eqeqeq
      if (state.toasterMessages?.message != null && action?.payload?.message != null) return;
      state.toasterMessages = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setOnPopupAdvanceMessage: (state, action) => {
      state.onPopupAdvanceMessage = action.payload;
    },
    setNavigationObject: (state, action) => {
      state.navigationObject = action.payload;
    },
  },
});

export const {
  setTheme,
  setTexts,
  setToasterMessage,
  setDirection,
  setDirectionChanged,
  setError,
  setOnPopupAdvanceMessage,
  setNavigationObject,
} = globalSlice.actions;

export const selectTheme = (allSliceState) => allSliceState.global.theme;

export const selectDirection = (allSliceState) => allSliceState.global.direction;

export const selectDirectionChanged = (allSliceState) => allSliceState.global.directionChanged;

export const selectToasterMessage = (allSliceState) => allSliceState.global.toasterMessages;

export const selectError = (allSliceState) => allSliceState.global.error;

export const selectOnPopupAdvanceMessage = (allSliceState) => allSliceState.global.onPopupAdvanceMessage;

export const selectNavigationObject = (allSliceState) => allSliceState.global.navigationObject;

export default globalSlice.reducer;
export const globalSliceName = globalSlice.name;
