import axios from 'axios';

const defaultApiPath = '/spaces/api/';

const getBaseURL = (apiPath = defaultApiPath) => `${window.location.origin}${apiPath}`;

const createBaseInstance = (apiPath = defaultApiPath) => {
  const axiosInstance = axios.create({
    baseURL: getBaseURL(apiPath),
    timeout: 1000000,
  });

  return axiosInstance;
}

const axiosInstance = createBaseInstance();

const applyInterceptors = (instance) => {
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      const { config } = err;

      if (!config || !config.retry) {
        return Promise.reject(err);
      }
      config.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          console.log('retry the request', config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => instance(config));
    },
  );
};

applyInterceptors(axiosInstance);

const baseURL = getBaseURL();
export { axiosInstance, baseURL, createBaseInstance, applyInterceptors };
