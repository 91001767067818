import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import counterReducer, { counterSliceName } from '../features/example/counter/slice';
import mainPanelReducer, { mainPanelSliceName } from '../features/loggedInRoutesPanel/slice';
import queueReducer, { queueSliceName } from '../features/queue/slice';
import auditLogReducer, { auditLogSliceName } from '../features/resolutionPanel/middlePanel/auditLog/store/slice';
import richTextFieldsReducer, {
  richTextFieldsSliceName,
} from '../features/srPanel/templateFieldsGrid/richTextField/store/slice';
import globalReducer, { globalSliceName } from './globalSlice';
import userReducer, { userSliceName } from './userSlice';
import srReducer, { srSliceName } from './srSlice';
import templateBuilderReducer, { templateBuilderSliceName } from '../features/templateBuilder/slice';
import activeComponentReducer, { activeComponentSliceName } from '../features/templateBuilder/activeComponentSlice';
import categoryReducer, { categorySliceName } from './categorySlice';
import fieldValidationsReducer, { fieldValidationsSliceName } from './fieldValidationsSlice';
import pageValidationReducer, { pageValidationSliceName } from './pageValidationSlice';
import saveSrValidationReducer, { saveSrValidationSliceName } from './saveSrValidationSlice';
import attachmentsReducer, { attachmentsSliceName } from './attachmentsSlice';
import floatingTicketPanelReducer, { floatingTicketPanelSliceName } from './floatingTicketPanelSlice';
import dirtyFieldReducer, { dirtyFieldSliceName } from './isDirtyFieldSlice';
import messagePromptReducer, { messagePromptSliceName } from './messagePromptSlice';
import ticketSummaryReducer, { ticketSummarySliceName } from './ticketSummarySlice';
import settingsReducer, { settingsSliceName } from '../features/settings/slice';
import errorReducer, { hasErrorSlice } from './errorSlice';
import keepAliveReducer, { keepAliveSliceName } from './keepAliveSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
    counterSliceName,
    mainPanelSliceName,
    queueSliceName,
    auditLogSliceName,
    richTextFieldsSliceName,
    globalSliceName,
    userSliceName,
    srSliceName,
    templateBuilderSliceName,
    activeComponentSliceName,
    categorySliceName,
    fieldValidationsSliceName,
    pageValidationSliceName,
    saveSrValidationSliceName,
    attachmentsSliceName,
    floatingTicketPanelSliceName,
    dirtyFieldSliceName,
    messagePromptSliceName,
    ticketSummarySliceName,
    settingsSliceName,
    hasErrorSlice,
    keepAliveSliceName,
  ],
};

const queuePersistConfig = {
  key: [queueSliceName],
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['isUnassigned', 'activeView', 'defaultView', 'isViewEdited', 'isQuickFilterApplied'],
};

const globalPersistConfig = {
  key: [globalSliceName],
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['texts'],
};

const appReducer = combineReducers({
  counter: counterReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
  mainPanel: mainPanelReducer,
  queue: persistReducer(queuePersistConfig, queueReducer),
  auditLog: auditLogReducer,
  user: userReducer,
  sr: srReducer,
  richTextFields: richTextFieldsReducer,
  templateBuilder: templateBuilderReducer,
  category: categoryReducer,
  fieldValidations: fieldValidationsReducer,
  pageValidation: pageValidationReducer,
  saveSrValidation: saveSrValidationReducer,
  attachments: attachmentsReducer,
  activeComponent: activeComponentReducer,
  floatingTicketPanel: floatingTicketPanelReducer,
  dirtyFields: dirtyFieldReducer,
  messagePrompt: messagePromptReducer,
  ticketSummary: ticketSummaryReducer,
  settings: settingsReducer,
  error: errorReducer,
  keepAlive: keepAliveReducer,
});
//
// const rootReducer = (state, action) => {
//   if (action.type === 'RESET_ALL') {
//     // for all keys defined in the persistConfig(s) we will do cleanup
//     storage.removeItem('persist:root');
//   }
//   return appReducer(state, action);
// };

const persistedReducer = persistReducer(rootPersistConfig, appReducer);

export const setupStore = (preloadedState = {}) =>
  configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // serializableCheck: {
        //   ignoredActionPaths: ['payload.column', 'payload.rect'],
        //   ignoredPaths: ['queue.filterEditor'],

        // },
        serializableCheck: false,
      }),
  });

export const store = setupStore();
export const persistor = persistStore(store);
