import { useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import { StyledSeasonalPage } from './style';
import SeasonalImage from './seasonalImage';
import SeasonalText from './seasonalText';
import { SeasonName } from './constants';

export const SeasonalLicenseExpirationPage = () => {
  const [currentSeason, setCurrentSeason] = useState(null);

  useLayoutEffect(() => {
    const seasonNumber = _.random(1, 4);
    const season = SeasonName[seasonNumber];
    setCurrentSeason(season);
  }, []);

  return (
    <StyledSeasonalPage>
      {currentSeason && (
        <div className="seasonal-content-wrapper">
          <SeasonalImage season={currentSeason} />
          <div className="seasonal-text-wrapper">
            <SeasonalText season={currentSeason} />
          </div>
        </div>
      )}
    </StyledSeasonalPage>
  );
};

export default SeasonalLicenseExpirationPage;
