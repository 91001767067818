import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const defaultItemTexts = {
    'spaces.menu.settings.customization.copilot': 'SysAid Copilot',
    'spaces.menu.settings.customization.copilot.setting': 'General Settings',
    'spaces.menu.settings.customization.copilot.dataPool': 'Data Pool',
    'spaces.menu.settings.customization.copilot.monitoring': 'Monitor & Fine-tune',
    'spaces.menu.settings.customization.copilot.usageDashboard': 'AI Usage Dashboard',
    'spaces.menu.settings.customization.copilot.aiBuilder': 'AI Builder',
  };
  const texts = useRbTexts();
  const componentTexts = {
    searchTitle: texts?.['spaces.menu.settings.search'],
    headerTitle: texts?.['spaces.menu.settings'],
    availableUpgrade: texts?.['spaces.menu.settings.availableUpgrade'] || 'Available Upgrade',
    getItemTitle: (key) => texts?.[key] || defaultItemTexts?.[key] || '',
    ...texts,
  };

  return componentTexts;
}
