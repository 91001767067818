import { StyledMenuItem } from 'common/components/dropdownSelect/SelectItem/SelectItem.styles';
import { InfoTypography } from 'common/components/dropdownSelect/InfoTypography';

import { AssigneeItem } from '../../../assigneeItem';
import useTexts from '../../useTexts';
import { useScrollbar } from '../useScrollbar';
import { CONSTANTS } from '../../constants';

export const DrillDownList = (props) => {
  const { memberList, groupName, handleChange, currentAssigned, showAssignToTeam, isGridFilter, filteredAssignees } =
    props;
  const { assignToTeam, emptyResultsText } = useTexts();
  const { Container, scrollProps } = useScrollbar(CONSTANTS.DROPDOWN_SCROLL_THRESHOLD);

  const onSelect = (option, type) => {
    if (isGridFilter) {
      handleChange({ admin: option }, type);
      return;
    }
    handleChange({
      group: { groupName },
      admin: { userName: option?.userName || null, profileImage: option?.profileImage || null },
    });
  };
  const checkIfSelected = (userId) => {
    if (isGridFilter) {
      if (filteredAssignees?.length && typeof filteredAssignees[0] === 'string') {
        return !!filteredAssignees.find((o) => o.id === userId);
      }
      if (filteredAssignees) {
        return !!filteredAssignees.find((o) => o.id === userId);
      }
    }
    return userId === currentAssigned?.admin?.id;
  };

  return (
    <>
      {memberList?.length === 0 ? (
        <InfoTypography message={emptyResultsText} />
      ) : (
        <Container {...scrollProps}>
          <div className="options_wrapper">
            {showAssignToTeam && (
              <StyledMenuItem
                className="option"
                key={`${groupName}_g`}
                value={groupName}
                data-testid={groupName}
                onClick={() => onSelect({ groupName }, 'group')}
              >
                <AssigneeItem text={assignToTeam} type="group" />
              </StyledMenuItem>
            )}
            {memberList?.map((option) => {
              const isSelected = checkIfSelected(option.id);
              return (
                <StyledMenuItem
                  className="option"
                  key={`${option.userName}_u`}
                  value={option.userName}
                  data-testid={option.userName}
                  onClick={() => onSelect(option, 'admin')}
                  selected={isSelected}
                >
                  <AssigneeItem
                    text={option.calculatedUserName}
                    profileImage={option.profileImage}
                    type="admin"
                    isSelected={isSelected}
                    showCheckbox={isGridFilter}
                  />
                </StyledMenuItem>
              );
            })}
          </div>
        </Container>
      )}
    </>
  );
};
