import { createSlice } from '@reduxjs/toolkit';
import { sliceNames } from './constants';

const initialState = {
  user: {
    username: null,
    roles: [],
    language: 'en',
    isAuth: false,
    calculatedUserName: null,
    userId: -1,
    isAdmin: false,
    isSysAidAdmin: false,
    calculatedRole: '',
    isUserLoading: true,
    settingLanguage: '',
    settingsRB: '',
    changeLanguage: false,
    reloadHeaders: false,
    userRB: {},
  },
  details: null,
  shouldDisplayLockoutMessage: false,
};

export const userSlice = createSlice({
  name: sliceNames.userSliceName,
  initialState,

  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    setUserDetails: (state, action) => {
      state.details = action.payload;
    },

    setShouldDisplayLockoutMessage: (state, action) => {
      state.shouldDisplayLockoutMessage = action.payload;
    },
    setLoginUrlObject: (state, action) => {
      state.loginUrlObject = action.payload;
    },
    toggleIsUserLoading: (state, action) => {
      state.user.isUserLoading = action.payload;
    },
    settingLanguage: (state, action) => {
      state.user.settingLanguage = action.payload;
    },
    settingRB: (state, action) => {
      state.user.settingsRB = action.payload;
    },
    toggleSwitchLangauge: (state, action) => {
      state.changeLanguage = action.payload;
    },
    setUserRB: (state, action) => {
      state.user.userRB = action.payload;
    },
    toggleSwitchHeadersLanguage: (state, action) => {
      state.user.reloadHeaders = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setUserDetails,
  setShouldDisplayLockoutMessage,
  setLoginUrlObject,
  toggleIsUserLoading,
  settingLanguage,
  settingRB,
  toggleSwitchLangauge,
  setUserRB,
  toggleSwitchHeadersLanguage,
} = userSlice.actions;

export const selectActiveUser = (state) => state.user?.user;
export const details = (state) => state.user?.details;
export const activeUsername = (state) => state.user?.user?.username;
export const shouldDisplayLockoutMessage = (state) => state.shouldDisplayLockoutMessage;
export const selectSettingLanguage = (state) => state.user?.user?.settingLanguage;
export const selectRB = (state) => state.user?.user?.settingsRB;
export const userRB = (state) => state.user?.user?.userRB;
export const isHeadersLanguageChange = (state) => state.user?.user?.reloadHeaders;

export default userSlice.reducer;
export const userSliceName = userSlice.name;
