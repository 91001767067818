import { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { DialogContentText } from '@mui/material';
import { getFieldAttributes } from 'services/ticketService';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFieldAttributes, useSrById, useUpdateSR } from 'remote-state/ticketServiceHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { convertFromClientFieldId, getValueKeyByFieldName } from 'common/utils/fieldUtils';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import Tooltip from 'common/components/tooltip';
import User from 'common/components/user';
import { setOnPopupAdvanceMessage } from 'store/globalSlice';
import MessagePrompt from 'common/components/messagePrompt';
import { PromptTextField } from 'common/components/messagePrompt/contextTypes/TextField';
import { messagePromptStyle } from 'common/components/priority/style';
import useTexts from 'common/components/priority/useTexts';
import { ConditionalWrapper, handleLocalStorageSaveItem, mergeArraysByKey, toDisplayUser } from 'common/utils/utils';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import { QUERIES_KEYS } from 'constant';
import { TICKETS_OPEN_URL, APP_CONSTANTS } from 'constants';
import {
  PARENT_STATUS_CHANGE_POPUP,
  SR_TO_INCIDENT_STATUS_CHANGE_POPUP,
  UNCHANGED_CHILD_STATUS_POPUP,
} from 'features/resolutionPanel/middlePanel/relatedItems/constants';
import {
  changeStatusPopupStyles,
  handleLinkedIncidentsStautsChangePopupTexts,
  shouldShowLinkedIncidentsStautsChangePopup,
  shouldShowParentStautsChangePopup,
} from 'common/components/status/utils';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import useShowNoEditPermissionPopup from 'common/utils/hooks/useShowNoEditPermissionPopup';
import { convertValue } from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import useStatusTexts from 'common/components/status/useTexts';
import MultiSelectField from 'features/queue/grid/defaultCellRender/MultiSelectField';
import useQueueTexts from 'features/queue/grid/useTexts';
import useHeaderTexts from 'features/header/useTexts';
import { CONSTANTS } from 'features/header/constants';
import { useGetSrLinkedIncidentsCount } from 'features/resolutionPanel/middlePanel/relatedItems/hooks/useRelatedItems';
import { useStatusSettings } from 'remote-state/accountSettingsServiceHooks';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { PlaceholderValues } from 'common/utils/constants';
import { FIELD_TYPE } from 'common/components/grid/constants';
import useValidateSrLockBeforeUpdate from '../../hooks/useValidateSrLockBeforeUpdate';
import { StyledItem } from './SelectRender.styles';
import { BarLoader } from '../SkeletonLoader';
import { selectSelectRenderValue } from '../../../slice';
import { STATUS_FIELD_ID } from '../../constants';
import { capitalizeFirstLetter } from '../../../utils';

const extractValueAndReason = (valueObject, keyString) => {
  if (valueObject) {
    const { value, reason } = valueObject;
    let valueToUpdate = value;
    if (Array.isArray(value)) {
      valueToUpdate = value
        .filter((el) => el !== '')
        .map((item) => item[keyString])
        ?.toString();
    } else if (typeof value === 'object' && value !== null) {
      valueToUpdate = value[keyString];
    }
    return { value: valueToUpdate, reason };
  }
  return { value: null, reason: null };
};

const findSelectedItem = (selectedItem, keyString) => {
  if (!selectedItem) return [];
  if (Array.isArray(selectedItem)) {
    return selectedItem.map((item) => item[keyString]);
  }
  return [selectedItem[keyString]];
};

export const SelectRender = memo((props) => {
  const {
    srId,
    value,
    style,
    srType = 1,
    fieldId,
    customColumn,
    fieldName,
    captionString,
    required,
    delay = 0,
    isMultiple,
    headerName,
    hoverEffect,
    delayedHover,
    className = '',
    disabled = false,
    isNoEditPermission = false,
    sortBy,
    notInTemplate,
  } = props;
  const fieldAttributesInput = useMemo(() => [{ fieldId, srType, customColumn }], [fieldId, srType, customColumn]);
  const { data: fieldAttributes, isError } = useFieldAttributes(fieldAttributesInput, false, srId)?.[0] || {};
  const values = fieldAttributes?.values;
  const [dropdownList, setDropdownList] = useState(values || []);
  const [showPrompt, setShowPrompt] = useState(false);
  const [statusPromptProperties, setStatusPromptProperties] = useState(null);
  const [priorityUpdateReason, setPriorityUpdateReason] = useState('');
  const [updatedValue, setUpdatedValue] = useState(value);
  const [anchor, setAnchor] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { parentLink: srParentId, childItemsCount: childSrsCount } = useSrById(srId)?.data || {};
  const { mutateAsync: getSrLinkedIncidentsCount } = useGetSrLinkedIncidentsCount(srId);
  const { data: statusSettings } = useStatusSettings();
  const timeout = useRef();
  const hoverRef = useRef();
  const labelRef = useRef(null);
  const dispatch = useDispatch();
  const forcedSelectRenderValue = useSelector(selectSelectRenderValue);
  const isStatusOfChildSr = fieldId === STATUS_FIELD_ID && !!srParentId;
  const isStatusOfParentSr = fieldId === STATUS_FIELD_ID && !!childSrsCount;

  const {
    popupTitle,
    popupDescription,
    popupInputPlaceholder,
    popupOkBtnText,
    mandatoryFieldError,
    emptyStatusPlaceholder,
    emptyPriorityPlaceholder,
  } = useTexts();
  useDelayHover(hoverRef, delayedHover, hoverEffect);
  const { checkAndUpdateSr } = useUpdateSR();
  const { showNoPermissionPopup } = useShowNoEditPermissionPopup();
  const {
    data: { manualPriorityChangesEnabled },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  const {
    statusRequiredFieldsTooltipMessage,
    unchangedStatusTitle,
    unchangedStatusDecription,
    unchangedStatusOkBtn,
    unchangedStatusCancelBtn,
    parentStatusChangeTitle,
    parentStatusChangeDescription,
    parentStatusChangeOkBtn,
    parentStatusChangeCancelBtn,
    linkedIncidentsStatusChangeTitle,
    linkedIncidentsStatusChangeDescription,
  } = useStatusTexts();
  const { notExistInTemplateMessage } = useQueueTexts();
  const srTypeValue = SR_TYPE_CODE_MAP[srType];
  const { srTypeText } = useHeaderTexts(srTypeValue);

  const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
    defaultValue: false,
  });

  useEffect(() => {
    setDropdownList(values || []);
  }, [values]);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    const { srId: targetSrId, fieldName: targetFieldName, fieldValue } = forcedSelectRenderValue;

    if (targetSrId === srId && targetFieldName === fieldName) {
      checkAndUpdateSr({ requestParams: { [targetFieldName]: fieldValue }, id: targetSrId });
      setUpdatedValue(fieldValue);
    }
  }, [forcedSelectRenderValue, srId, fieldName, checkAndUpdateSr]);

  const parsedValue = useMemo(() => {
    let value = updatedValue;
    if (updatedValue) {
      if (isMultiple) {
        value = updatedValue.split(',');
      }
      if (typeof updatedValue === 'object') {
        value = convertValue(updatedValue);
      }
    }
    return value;
  }, [updatedValue, isMultiple]);

  const keyString = getValueKeyByFieldName(fieldName);

  let valueId;
  if (isMultiple) {
    valueId = parsedValue;
  } else if (typeof parsedValue === 'object') {
    valueId = parsedValue?.[keyString];
  } else {
    valueId = parsedValue;
  }

  const { data: selectedItem, loaded: islistValueLoaded } = useGetListValueById({
    listKey: fieldId,
    customColumn,
    list: dropdownList && dropdownList.length > 0 ? dropdownList : values,
    id: valueId,
    isMultiple,
  });

  const isPriorityColumn = fieldName === FIELD_TYPE.priority;
  const isStatusColumn = fieldName === FIELD_TYPE.status;
  const isChipItem = isStatusColumn || isPriorityColumn;
  const isDisabled = disabled || isMultiple || isStatusOfChildSr;
  const selectText = PlaceholderValues.Select;
  const notAvailableText = PlaceholderValues.NotAvailable;
  const multipleFieldPlaceholder = disabled ? notAvailableText : selectText;
  const selectFieldPlaceholder = disabled ? notAvailableText : selectText;
  const fieldPlaceholder = isMultiple ? multipleFieldPlaceholder : selectFieldPlaceholder;

  const getPlaceholder = () => {
    if (isStatusColumn) {
      return emptyStatusPlaceholder;
    }
    if (isPriorityColumn) {
      return emptyPriorityPlaceholder;
    }
    if (required && !valueId) {
      return '';
    }
    if (notInTemplate) {
      return notExistInTemplateMessage;
    }
    return selectedItem?.placeholder || fieldPlaceholder;
  };

  const handleCheckboxChange = () => {
    handleLocalStorageSaveItem({ attributeName: statusPromptProperties?.popupName, value: !isChecked });
    setIsChecked((prevState) => !prevState);
  };

  const handleCloseStatusPrompt = useCallback(() => {
    setStatusPromptProperties({ ...statusPromptProperties, open: false });
  }, [statusPromptProperties]);

  const handleNavigateToParentSr = useCallback(() => {
    const parentSrUrl = TICKETS_OPEN_URL.SPACES(srParentId);
    window.open(parentSrUrl, '_blank', 'noreferrer');
    handleCloseStatusPrompt();
  }, [handleCloseStatusPrompt, srParentId]);

  const handleOpen = useCallback(() => {
    if (isStatusOfChildSr) {
      const dontShowAgainData = JSON.parse(localStorage.getItem(APP_CONSTANTS.DONT_SHOW_AGAIN)) || {};
      const showPrompt = !dontShowAgainData?.[UNCHANGED_CHILD_STATUS_POPUP];
      setStatusPromptProperties({
        open: showPrompt,
        popupName: UNCHANGED_CHILD_STATUS_POPUP,
        title: unchangedStatusTitle,
        description: unchangedStatusDecription.replace('__srId__', srParentId),
        showIcon: false,
        okBtnText: unchangedStatusOkBtn,
        okBtnAction: handleNavigateToParentSr,
        cancelBtnText: unchangedStatusCancelBtn,
        cancelBtnAction: handleCloseStatusPrompt,
        style: changeStatusPopupStyles[UNCHANGED_CHILD_STATUS_POPUP],
      });
      return;
    }
    if (!disabled) {
      timeout.current = setTimeout(() => {
        setAnchor(labelRef.current);
      }, delay);
    }
  }, [
    labelRef,
    disabled,
    delay,
    isStatusOfChildSr,
    srParentId,
    handleCloseStatusPrompt,
    handleNavigateToParentSr,
    unchangedStatusCancelBtn,
    unchangedStatusDecription,
    unchangedStatusOkBtn,
    unchangedStatusTitle,
  ]);

  const handleClose = useCallback(() => setAnchor(null), []);

  const handleSaveSR = useCallback(
    async (valueObject) => {
      const { value, reason } = extractValueAndReason(valueObject, keyString);
      const object = { [fieldName]: value };
      const objectToUpdate = {
        requestParams: object,
        id: srId,
        queueUpdate: true,
      };
      handleClose();
      const res = await checkAndUpdateSr(objectToUpdate, reason);
      if (!isMultiple && res) {
        const resValue = res.data?.sr?.[fieldName];
        if (
          resValue &&
          selectedItem?.[keyString] !== (typeof resValue === 'object' ? convertValue(resValue)[keyString] : resValue)
        ) {
          setUpdatedValue(resValue);
        }
      }
    },
    [srId, fieldName, keyString, handleClose, checkAndUpdateSr, isMultiple, selectedItem],
  );

  const { handleUpdateSR } = useValidateSrLockBeforeUpdate({ srId, handleSaveSR });

  const updateMultiSelectField = useCallback(() => {
    handleUpdateSR({ value: selectedItem });
  }, [handleUpdateSR, selectedItem]);

  const handleChange = useCallback(
    (value, optionsMap) => {
      if (optionsMap) setDropdownList(Object.values(optionsMap));
      if (isMultiple) {
        setUpdatedValue(value.toString());
      } else if (manualPriorityChangesEnabled && fieldName === 'priority') {
        if (value.valueKey !== selectedItem?.valueKey) {
          setUpdatedValue(value);
          dispatch(setOnPopupAdvanceMessage(true));
          setShowPrompt(true);
        }
      } else {
        handleUpdateSR({ value });
      }
    },
    [manualPriorityChangesEnabled, fieldName, isMultiple, handleUpdateSR, dispatch, selectedItem?.valueKey],
  );

  const handleChangeField = useCallback(
    async (value, optionsMap) => {
      // change value if not status field
      if (fieldId !== STATUS_FIELD_ID) {
        handleChange(value, optionsMap);
        return;
      }
      // check to show parent/child status change prompt
      const showParentStatusChangePrompt = shouldShowParentStautsChangePopup({ isStatusOfParentSr });
      if (showParentStatusChangePrompt) {
        setStatusPromptProperties({
          open: showParentStatusChangePrompt,
          popupName: PARENT_STATUS_CHANGE_POPUP,
          title: parentStatusChangeTitle,
          description: parentStatusChangeDescription.replace('__childSrCount__', childSrsCount),
          showIcon: true,
          okBtnText: parentStatusChangeOkBtn,
          okBtnAction: () => {
            handleCloseStatusPrompt();
            handleChange(value, optionsMap);
          },
          cancelBtnText: parentStatusChangeCancelBtn,
          cancelBtnAction: () => {
            handleCloseStatusPrompt();
            handleClose();
          },
          style: changeStatusPopupStyles[PARENT_STATUS_CHANGE_POPUP],
        });
        return;
      }
      // check to show linked incidents status change prompt
      if (srType !== CONSTANTS.INCIDENT_TYPE) {
        const linkedIncidentsCount = await getSrLinkedIncidentsCount({ srId });
        const showPrompt = shouldShowLinkedIncidentsStautsChangePopup({ linkedIncidentsCount, statusSettings, value });
        if (showPrompt) {
          const { title, description } = handleLinkedIncidentsStautsChangePopupTexts({
            statusSettings,
            value,
            statusList: values,
            linkedIncidentsStatusChangeTitle,
            linkedIncidentsStatusChangeDescription,
            srTypeText,
            linkedIncidentsCount,
          });
          setStatusPromptProperties({
            open: showPrompt,
            popupName: SR_TO_INCIDENT_STATUS_CHANGE_POPUP,
            title,
            description,
            showIcon: true,
            okBtnText: parentStatusChangeOkBtn,
            okBtnAction: () => {
              handleCloseStatusPrompt();
              handleChange(value, optionsMap);
            },
            cancelBtnText: parentStatusChangeCancelBtn,
            cancelBtnAction: () => {
              handleCloseStatusPrompt();
              handleClose();
            },
            style: changeStatusPopupStyles[SR_TO_INCIDENT_STATUS_CHANGE_POPUP],
          });
          return;
        }
      }
      // status change
      handleChange(value, optionsMap);
    },
    [
      fieldId,
      srType,
      srTypeText,
      srId,
      handleCloseStatusPrompt,
      isStatusOfParentSr,
      parentStatusChangeCancelBtn,
      parentStatusChangeDescription,
      parentStatusChangeOkBtn,
      parentStatusChangeTitle,
      linkedIncidentsStatusChangeDescription,
      linkedIncidentsStatusChangeTitle,
      childSrsCount,
      handleChange,
      handleClose,
      getSrLinkedIncidentsCount,
      statusSettings,
      values,
    ],
  );

  const handleOkClickInPrompt = useCallback(() => {
    if (priorityUpdateReason) {
      handleUpdateSR({ value: updatedValue, reason: priorityUpdateReason });
    }
    dispatch(setOnPopupAdvanceMessage(false));
    setPriorityUpdateReason('');
    setShowPrompt(false);
  }, [updatedValue, priorityUpdateReason, handleUpdateSR, dispatch]);

  const handleClosePrompt = () => {
    dispatch(setOnPopupAdvanceMessage(false));
    setPriorityUpdateReason('');
    setShowPrompt(false);
  };

  const handlePriorityReasonUpdate = (value) => {
    setPriorityUpdateReason(value);
  };

  const stylingVariant = itemClassNames[fieldName];
  const styleClass = `valueClass_${selectedItem?.valueClass}`;
  const isDefault = ![FIELD_TYPE.priority, FIELD_TYPE.status].includes(fieldName);
  const isCustomStylesForListFields = isDefault && (fieldAttributes?.type === 'select' || fieldAttributes?.type === 'multiselect')
  const isCustomStyles =
    headerName === 'Ticket Type' || 
    headerName === 'Impact' || 
    isCustomStylesForListFields || 
    (isMultiple && customColumn);

  const handleClearValue = useCallback(() => {
    handleUpdateSR(null);
    setUpdatedValue(null);
    handleClose();
  }, [handleUpdateSR, handleClose]);

  const selection = useMemo(() => findSelectedItem(selectedItem, keyString), [selectedItem, keyString]);
  const isEmptyRequiredValue = required && !valueId;

  const getTooltipTitle = () => {
    if (isEmptyRequiredValue && isChipItem) {
      return mandatoryFieldError;
    }
    if (fieldName === 'requestUser' && selectedItem?.isDeleted) {
      return 'User does not exist';
    }
    if (fieldName === FIELD_TYPE.status && disabled) {
      return statusRequiredFieldsTooltipMessage;
    }
    return undefined;
  };
  const tooltipTitle = getTooltipTitle();

  const options = useMemo(() => {
    const key = isMultiple && customColumn ? 'value' : keyString;
    if (!isMultiple || isEmpty(selectedItem)) return dropdownList;
    if (Array.isArray(selectedItem)) return mergeArraysByKey(dropdownList, selectedItem, key);
    return dropdownList.includes(selectedItem) ? dropdownList : [...dropdownList, selectedItem];
  }, [isMultiple, dropdownList, selectedItem, keyString, customColumn]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        typeof query === 'object'
          ? getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { ...query, customColumn })
          : getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, customColumn }),
    }),
    [fieldId, customColumn],
  );

  const handleDisabledClick = useCallback(() => {
    if (!isDefault && isNoEditPermission) showNoPermissionPopup();
  }, [showNoPermissionPopup, isDefault, isNoEditPermission]);

  const isSrType = headerName === 'SR Type';
  const text = selectedItem?.[captionString] || updatedValue?.calculatedUserName || getPlaceholder();

  if (isError) return <div>{isError}</div>;
  if (!islistValueLoaded && fieldName !== 'requestUser') return <BarLoader withFade width={60} height={24} />;

  const isEmptyField = !valueId && text === selectText;
  const normalizedFieldName = capitalizeFirstLetter(fieldName);
  
  return (
    <StyledItem
      data-cy={`${fieldName}-column-wrapper`}
      ref={hoverRef}
      isDefault={isDefault}
      className={anchor ? 'expanded' : ''}
      required={required}
      isCustomStyles={isCustomStyles}
      disabled={isDisabled}
      isSROpenedFromQueue={isSROpenedFromQueue}
      isEmptyRequiredValue={isEmptyRequiredValue}
      isChipItem={isChipItem}
      isCapitalized={fieldName === 'srType'}
      onMouseEnter={() => setIsHovered(true)}
      isMultiple={isMultiple}
      notInTemplate={notInTemplate}
      isEmptyField={isEmptyField}
      {...(disabled ? { onClick: handleDisabledClick } : {})}
    >
      {isMultiple ? (
        <MultiSelectField
          itemList={options}
          selectedItemList={selection}
          handleSelectItems={handleChange}
          captionString={captionString}
          keyString={keyString}
          disabled={disabled}
          fieldPlaceholder={text}
          srId={srId}
          sortBy={sortBy}
          required={required}
          tooltipTitle={isEmptyRequiredValue && mandatoryFieldError}
          fieldId={fieldId}
          customColumn={customColumn}
          srType={srType}
          updateField={updateMultiSelectField}
          isEmptyField={isEmptyField}
          notInTemplate={notInTemplate}
        />
      ) : (
        <>
          <span
            ref={labelRef}
            className={`${normalizedFieldName}Item ${className}`}
            data-testid={`${normalizedFieldName}Item`}
            onClick={handleOpen}
            aria-hidden="true"
          >
            <span key={selectedItem?.valueKey} className={`chip ${styleClass}`}>
              <ConditionalWrapper
                condition={toDisplayUser([itemClassNames.reporter, 'requestUser', 'submitUser', 'manager'], headerName)}
                wrapper={(children) => <User userName={selectedItem?.valueCaption}>{children}</User>}
              >
                <span className="text">
                  <Tooltip
                    isError={isEmptyRequiredValue && isChipItem}
                    title={text}
                    isTruncatedText={!notInTemplate}
                    forceShowOnHover={tooltipTitle != null}
                    text={text}
                    placement="top"
                    disableHoverListener={isEmptyRequiredValue && !isChipItem}
                    placeholder={getPlaceholder()}
                    // TODO: Perform the check if we need custom style to the wrapper of this component, and apply the style prop as received from the wrapper always.
                    style={isCustomStyles || isSrType ? style : null}
                    disableTooltip={notInTemplate}
                  />
                  {isEmptyRequiredValue && !isChipItem && (
                    <Tooltip
                      className="missing-required-field"
                      title={mandatoryFieldError}
                      placement="top"
                      disableHoverListener={!isEmptyRequiredValue || !mandatoryFieldError}
                    />
                  )}
                  {!disabled && isCustomStyles && (
                    <ArrowDownIcon data-testid="arrow-down-icon" />
                  )}
                </span>
              </ConditionalWrapper>
            </span>
          </span>
          {isHovered && (
            <DropdownSelect
              anchor={anchor}
              options={options}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChangeField}
              selection={selection}
              fieldName={fieldName}
              keyString={keyString}
              captionString={captionString}
              stylingVariant={stylingVariant}
              sortBy={sortBy}
              handleClearValue={handleClearValue}
              backendQueryConfig={backendQueryConfig}
              isActionsHidden={isChipItem}
            />
          )}
        </>
      )}

      {showPrompt && (
        <MessagePrompt
          open
          onOkClick={handleOkClickInPrompt}
          showCancelBtn
          showIcon={false}
          isOkButtonDisable={priorityUpdateReason.length === 0}
          onClose={handleClosePrompt}
          title={popupTitle(selectedItem?.valueCaption)}
          btnOkText={popupOkBtnText}
          style={messagePromptStyle}
        >
          <DialogContentText>
            {popupDescription}
            <span className="mandatory-asterisk">*</span>
          </DialogContentText>
          <PromptTextField placeholder={popupInputPlaceholder} onChange={handlePriorityReasonUpdate} />
        </MessagePrompt>
      )}
       {statusPromptProperties?.open && (
          <MessagePrompt
            open
            title={statusPromptProperties?.title}
            onOkClick={statusPromptProperties?.okBtnAction}
            btnOkText={statusPromptProperties?.okBtnText}
            onClose={statusPromptProperties?.cancelBtnAction}
            btnCancelText={statusPromptProperties?.cancelBtnText}
            showCancelBtn
            showIcon={statusPromptProperties?.showIcon}
            showDontShowMeAgain
            isCheckboxChecked={isChecked}
            onToggleCheckbox={handleCheckboxChange}
            style={statusPromptProperties?.style}
          >
            {statusPromptProperties?.description}
          </MessagePrompt>
        )}
    </StyledItem>
  );
});
