import { Tooltip as MuiTooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { useRef, useState } from 'react';
import { StyledLabelWithEllipsis } from './style';

export const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip classes={{ popper: className }} {...props} enterDelay={500} enterNextDelay={500} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: 380,
    padding: '12px',
    borderRadius: '10px',
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: theme?.fonts?.robotoRegular,
  },
}));

export const Tooltip = ({ children, forceDisplayOnHover, isIcon, forceTooltip, ...rest }) => {
  const elementRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipDisplay = () => {
    if (forceTooltip !== undefined) {
      setShowTooltip(forceTooltip);
      return;
    }
    const isTextEllipsis = elementRef?.current?.scrollWidth > elementRef?.current?.clientWidth;
    setShowTooltip(isTextEllipsis);
  };

  return (
    <StyledTooltip {...rest} disableHoverListener={forceDisplayOnHover ? false : !showTooltip}>
      <StyledLabelWithEllipsis
        variant="span"
        ref={elementRef}
        onMouseEnter={handleTooltipDisplay}
        onMouseLeave={handleTooltipDisplay}
        isIcon={isIcon}
      >
        {children}
      </StyledLabelWithEllipsis>
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
  type: PropTypes.string,
  forceDisplayOnHover: PropTypes.bool,
  isIcon: PropTypes.bool,
};

Tooltip.defaultProps = {
  title: '',
  arrow: false,
  placement: 'top',
  type: 'info',
  forceDisplayOnHover: false,
  isIcon: false,
};
