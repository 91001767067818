import { useQueryClient } from '@tanstack/react-query';
import { useSrArchivingEnabled } from '../accountSettingsServiceHooks';
import { QUERIES_KEYS } from '../../constant';

export const useArchiveTicket = () => {
  const isArchivingEnabled = useSrArchivingEnabled();
  const queryClient = useQueryClient();

  const invalidateArchivePermissions = (isArchived, srId) => {
    if (isArchivingEnabled) {
      const timer = setTimeout(() => {
        const archiveAction = isArchived ? 'restore' : 'archive';
        queryClient.invalidateQueries([QUERIES_KEYS.TICKET_PERMISSIONS, archiveAction, srId]);
      }, 2000); // 2 seconds delay to let backend save new status and retrieve correct archive/restore permission
      return () => clearTimeout(timer);
    }
  };

  return {
    invalidateArchivePermissions,
  };
};
