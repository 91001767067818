import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { PAGE_VALIDATION_CONSTANTS, PENDO_TRACK_EVENTS } from 'constants/index';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { createSrNote, deleteSRNote, editSrNote } from 'services/noteService';
import { createSrActivity, deleteSRActivity } from 'services/activityService';
import { sendSrMessage } from 'services/messagesServices';
import { resolveSolutionResolutionApi, editSolutionResolutionApi } from 'services/resolutionService';
import { selectActiveUser } from 'store/userSlice';
import { setToasterMessage } from 'store/globalSlice';
import { setPageValidations } from 'store/pageValidationSlice';
import { useTicketAttachments, useDeleteAttachmentsQuery } from 'remote-state/attachments';
import { useHandleTicketLock, useSR } from 'remote-state/ticketServiceHooks';
import { useJourneyLogsByFilterQuery } from 'remote-state/useAuditLogsHooks';
import { selectFieldValidations } from 'store/fieldValidationsSlice';
import { selectPrevSRStatus, selectSRStatus, updateSRStatus } from 'store/srSlice';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import useTexts from '../../../useTexts';
import { editorActionData, promptTypes, editorActionTypes, FILTER_ENUMS } from '../constants';
import { useAuditLog } from './useAuditLog';
import { initialMessage, initialNote, initialResolution, initialActivity } from '../store/initialState';
import { checkIsDirty } from '../utils';
import {
  selectActionLine,
  updateActionLine,
  updateActionLineIsEditorDirty,
  clearActionLine,
  toggleActionLinePrompt,
  clearActionLineData,
  updateActionLineData,
  initialActionsState,
} from '../store/slice';
import { updateState } from '../store/actionLineReducer';
import { parallelEditTypes } from '../actionLine/prompts/parallelEditTypes';
import {
  convertToApiObj,
  convertToReduxObj,
  isSRFieldsValid,
  isValidResolution,
} from '../actionLine/solutionResolution/utils';
import * as activityUtils from '../actionLine/activities/utils';
import { useArchiveTicket } from '../../../../../remote-state/archive';

export const useEditorActions = () => {
  const isEmptyValue = (value) => value === null || value === undefined || value === '' || value.trim() === '';
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const {
    type: editorType,
    isNewEditor,
    data,
    activeIndex,
    isEditorDirty,
    isExpanded,
    isEditorFullSize,
  } = useSelector(selectActionLine);
  const prevSRStatus = useSelector(selectPrevSRStatus);
  const userAccount = useSelector(selectActiveUser);
  const srStatus = useSelector(selectSRStatus);
  const texts = useTexts();
  const { updateAuditLogs, toggleAuditLogsProperty, setAuditLogsProperty, getTicketAuditLogsByType } = useAuditLog();
  const { invalidateSRAttachments } = useTicketAttachments();
  const { invalidateArchivePermissions } = useArchiveTicket();
  const { mutateAsync: deleteAttachmentsApi } = useDeleteAttachmentsQuery();
  const { mutate: lockSR } = useHandleTicketLock(srId);
  const pendoTrackEvents = usePendoTrackEvents();

  const editorActionSettings = editorActionData[editorType];
  const username = userAccount?.username;
  const {
    sr: { data: sr },
  } = useSR();
  const {
    data: { userConf },
  } = useUserInfo(QUERIES_KEYS.CURRENT_USER);
  const srType = sr?.srType;
  const { fieldSrList = {} } = useSelector(selectFieldValidations);
  const { updateJourneyAuditLogsByType } = useJourneyLogsByFilterQuery({ srId, filterType: 'resolution' });

  const initializeEditorData = (editorType = editorActionTypes.NOTE, logInformation = {}, params = {}) => {
    if (editorType === editorActionTypes.NOTE) {
      if (userConf?.shareNoteByDefault) {
        return { ...initialNote, ...{ shareWithReqUser: true } };
      }
      return initialNote;
    }
    if (editorType === editorActionTypes.MESSAGE) {
      return { ...initialMessage, ...logInformation, ...params };
    }
    if (editorType === editorActionTypes.RESOLUTION) {
      logInformation = {
        ...logInformation,
        resolutionText: logInformation?.resolution?.text || '',
        solutionText: logInformation?.solution?.text || '',
      };
      return { ...initialResolution, ...logInformation };
    }
    if (editorType === editorActionTypes.ACTIVITY) {
      return { ...initialActivity, ...logInformation };
    }
  };

  const updateTicketPageValidation = useCallback(
    ({ isValid }) => {
      dispatch(
        setPageValidations({
          component: `${PAGE_VALIDATION_CONSTANTS.ACTION_LINE}`,
          validationDetails: {
            isValid,
            promptTexts: !isValid && {
              title: texts.modal[editorType].exitPage.title,
              description: texts.modal[editorType].exitPage.description,
              btnOkText: texts.modal[editorType].exitPage.btnOkText,
              btnCancelText: texts.modal.btnCancelText({ cancelBtn: texts.modal[editorType].exitPage.btnCancelText }),
            },
          },
        }),
      );
    },
    [dispatch, editorType, texts.modal],
  );

  const onSuccess = ({ auditLogRecords, sr = null }) => {
    if (auditLogRecords?.length > 0) {
      updateAuditLogs({ log: auditLogRecords });
    }
    dispatch(clearActionLine());
    updateTicketPageValidation({ isValid: true });
    if (sr) {
      queryClient.setQueryData(['srId', sr.id], (oldTicket) => ({
        ...oldTicket,
        ...sr,
      }));
    }
  };

  /* Activities Api functions declarations */
  const { mutateAsync: createActivity } = useMutation({
    mutationFn: createSrActivity,
    onSuccess: ({ auditLogRecords }, variables) => {
      const message = texts.modal[editorType].success.description;

      onSuccess({ auditLogRecords });
      dispatch(setToasterMessage({ message }));
      pendoTrackEvents(PENDO_TRACK_EVENTS.ACTIVITY_SUBMITTED, variables);
    },
    onError: () =>
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.ERROR,
          message: { description: texts.modal[editorType][promptTypes.ERROR].createDescription },
        }),
      ),
  });

  const { mutateAsync: deleteActivity } = useMutation({
    mutationFn: deleteSRActivity,
    onSuccess: ({ auditLogRecords }, variables) => {
      getTicketAuditLogsByType(FILTER_ENUMS.ACTIVITIES);
      getTicketAuditLogsByType(FILTER_ENUMS.FULL_JOURNEY);
      getTicketAuditLogsByType(FILTER_ENUMS.AUDIT_LOG_ITEMS);
      onSuccess({ auditLogRecords });
      dispatch(setToasterMessage({ message: texts.activityDeleted }));
      pendoTrackEvents(PENDO_TRACK_EVENTS.ACTIVITY_DELETED, variables);
    },
    onError: () =>
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.ERROR,
          message: { description: texts.modal.ACTIVITY[promptTypes.ERROR].deleteDescription },
        }),
      ),
  });

  const { mutateAsync: deleteNote } = useMutation({
    mutationFn: deleteSRNote,
    onSuccess: ({ auditLogRecords }) => {
      onSuccess({ auditLogRecords });
      dispatch(setToasterMessage({ message: `${texts.noteText} ${texts.deletedText}` }));
    },
    onError: () =>
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.ERROR,
          message: { description: texts.modal[editorType][promptTypes.ERROR].deleteDescription },
        }),
      ),
  });

  const deleteEditorItem = async ({ editorItemId, auditLogId, editorType }) => {
    if (editorActionTypes.NOTE === editorType) {
      lockSR({ username, srId, queueLocking: false });
      await deleteNote({ srId, noteId: editorItemId, auditLogId });
      invalidateSRAttachments();
    }
    if (editorActionTypes.ACTIVITY === editorType) {
      await deleteActivity({ srId, activityId: editorItemId, auditLogId });
    }
  };

  const createReduxAction = (editorType, data, manualStatusChanged) => {
    if (editorType === editorActionTypes.RESOLUTION) {
      const editorActionSettings = editorActionData[editorActionTypes.RESOLUTION];
      const updatedResolution =
        srStatus.valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS && !manualStatusChanged
          ? {
              ...data,
              [editorActionSettings.statusCaption]: srStatus.value,
              [editorActionSettings.status]: srStatus.id,
            }
          : data;
      return convertToReduxObj(updatedResolution);
    }
    return data;
  };

  const handleTypeChangePrompt = ({ parallelEditType, auditLogId, logInformation, type, params = {} }) => {
    const promptProperties = {};
    const commonProperties = {
      isOpen: true,
      promptType: promptTypes.CANCEL,
      parallelEditType,
    };

    const actionLineInfo = createReduxAction(type, { ...logInformation });

    const editModeProperties = {
      parallelEditParams: {
        auditLogId,
        lastSavedData: { ...actionLineInfo, auditLogId },
        data: { ...actionLineInfo, auditLogId },
      },
    };
    switch (parallelEditType) {
      case parallelEditTypes.EDIT_TO_EDIT: {
        promptProperties.message = { description: texts.modal[editorType].cancel.editToEditText };
        Object.assign(promptProperties, editModeProperties);
        break;
      }
      case parallelEditTypes.CREATE_TO_EDIT: {
        Object.assign(promptProperties, editModeProperties);
        break;
      }
      case parallelEditTypes.CREATE_TO_CREATE:
      case parallelEditTypes.EDIT_TO_CREATE: {
        promptProperties.message = {
          description: texts.modal[editorType].cancel.editToCreateText,
          title: texts.modal[editorType].cancel.editToCreateTitle,
        };
        promptProperties.btnTexts = {
          cancelBtn: texts.modal[editorType].cancel.editToCreateCancelBtnText,
          okBtn: texts.modal[editorType].cancel.editToCreateOkBtnText,
        };
        promptProperties.parallelEditParams = { type, data: params };
        break;
      }
      default:
        return null;
    }
    dispatch(toggleActionLinePrompt({ ...promptProperties, ...commonProperties }));
  };

  const handleActionLineOperation = async ({
    actionName,
    type = editorType,
    logInformation = null,
    manualStatusChanged = false,
    auditLogId = null,
    handleClickEdit = () => {},
    handleClickDelete = () => {},
  }) => {
    lockSR({ username, srId, queueLocking: false });

    if (actionName === 'delete') {
      if (isEditorDirty) {
        dispatch(
          toggleActionLinePrompt({
            isOpen: true,
            promptType: promptTypes.CANCEL,
            message: {
              title: isNewEditor
                ? texts.modal[type][promptTypes.CANCEL].unsavedTitle
                : texts.modal[type][promptTypes.CANCEL].title,
            },
          }),
        );
      } else {
        handleClickDelete();
      }
    }
    if (actionName === 'edit') {
      let shouldResetData = false;
      if (activeIndex === -1 && isEditorDirty) {
        handleTypeChangePrompt({
          parallelEditType: parallelEditTypes.CREATE_TO_EDIT,
          auditLogId,
          logInformation,
          type,
        });
      } else if (activeIndex !== -1 && activeIndex !== auditLogId && isEditorDirty) {
        handleTypeChangePrompt({ parallelEditType: parallelEditTypes.EDIT_TO_EDIT, auditLogId, logInformation, type });
      } else {
        shouldResetData = true;
        const parsedData = createReduxAction(type, { ...logInformation }, manualStatusChanged);
        dispatch(
          updateActionLine({
            isNewEditor: false,
            type,
            isExpanded: true,
            activeIndex: auditLogId,
            lastSavedData: { ...parsedData, auditLogId },
            data: { ...parsedData, auditLogId },
          }),
        );
      }
      handleClickEdit({ shouldResetData });
    }
  };

  /* Notes Api functions declarations */
  const { mutateAsync: createNote } = useMutation({
    mutationFn: createSrNote,
    onSuccess,
    onError: () =>
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.ERROR,
          message: { description: texts.modal[editorType][promptTypes.ERROR].createDescription },
        }),
      ),
  });

  /* Messages Api functions declarations */
  const { mutateAsync: sendMessage } = useMutation({
    mutationFn: sendSrMessage,
    onSuccess: (data) => {
      if (data?.success) {
        let msg = texts.modal[editorType].success.description;
        if (!isEmptyValue(data.errorMessage)) {
          msg = data.errorMessage;
        }
        onSuccess({ auditLogRecords: data.auditLogRecords });
        dispatch(setToasterMessage({ message: msg }));
      } else {
        dispatch(
          setToasterMessage({
            type: 'error',
            message: data.errorMessage,
          }),
        );
      }
    },
    onError: () =>
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.ERROR,
          message: { description: texts.modal[editorType][promptTypes.ERROR].createDescription },
        }),
      ),
  });

  const onErrorEditNote = (promptType) => {
    dispatch(
      toggleActionLinePrompt({
        isOpen: true,
        promptType: promptTypes.ERROR,
        message: { description: texts.modal[promptType][promptTypes.ERROR].editDescription },
      }),
    );
  };

  const onSuccessEditNote = ({ auditLogRecords, sr }) => {
    onSuccess({ auditLogRecords, sr });
  };

  const { mutateAsync: editNote } = useMutation({ mutationFn: editSrNote });

  /* solutionResolution Api functions declarations */
  const { mutateAsync: resolveSolutionResolution } = useMutation({
    mutationFn: resolveSolutionResolutionApi,
    onSuccess: ({ auditLogRecords, sr, message }) => {
      onSuccess({ auditLogRecords, sr });
      dispatch(setToasterMessage({ message }));
    },
    onError: () =>
      dispatch(
        setToasterMessage({ message: texts.modal[editorType][promptTypes.ERROR].description(null), type: 'error' }),
      ),
  });

  const onSuccessEditSolutionResolution = ({ auditLogRecords, sr, message, showToasterMessage }) => {
    onSuccess({ auditLogRecords, sr });
    updateJourneyAuditLogsByType(auditLogRecords);
    if (showToasterMessage) dispatch(setToasterMessage({ message, type: 'success' }));
  };

  const onErrorEditSolutionResolution = ({ showToasterMessage }) => {
    if (showToasterMessage)
      setToasterMessage({ message: texts.modal[editorType][promptTypes.ERROR].description(null), type: 'error' });
  };

  const { mutateAsync: editSolutionResolution } = useMutation({
    mutationFn: editSolutionResolutionApi,
  });

  const saveEditorDataByType = async ({
    updateData = null,
    isEditing = false,
    logType = editorType,
    promptType = editorType,
    autoPopulatedNewSrId,
    autoPopulatedNewSrType,
  }) => {
    const requestData = {
      srId: autoPopulatedNewSrId || srId,
      srType: autoPopulatedNewSrType || srType,
      ...(updateData || data),
    };
    if (!autoPopulatedNewSrId) {
      await lockSR({ username, srId: autoPopulatedNewSrId || srId, queueLocking: false });
    }

    if (editorActionTypes.NOTE === logType) {
      if (isNewEditor && !isEditing) {
        await createNote(requestData);
      } else {
        await editNote({ requestData, onErrorEditNote, onSuccessEditNote, promptType });
      }
    }

    if (editorActionTypes.MESSAGE === logType) {
      const {
        messageText,
        sender,
        recipients,
        bcc,
        cc,
        attachments,
        inlineImages,
        isAttachmentLinkChecked,
        quotedRecord,
        completeSubject,
      } = data;
      const message = {
        srId,
        srType,
        addSRAttLink: isAttachmentLinkChecked,
        subject: completeSubject,
        messageText,
        sender,
        recipients,
        bcc,
        cc,
        attachments,
        inlineImages,
        quotedRecord,
      };
      await sendMessage(message);
    }

    if (editorActionTypes.RESOLUTION === logType) {
      const isValidSrField = isSRFieldsValid(fieldSrList, srId, requestData.assignee);
      const resolution = { ...requestData, isValidSrField };
      if (isValidResolution(resolution)) {
        if (isNewEditor && !isEditing && !requestData.isDisabledJourneyLog) {
          const solutionResolution = convertToApiObj(requestData);
          await resolveSolutionResolution(solutionResolution);
        } else {
          const editedSolutionResolution = convertToApiObj(requestData, isExpanded, activeIndex);
          const showToasterMessage = !requestData.solution;
          await editSolutionResolution({
            editedSolutionResolution,
            onSuccessEditSolutionResolution,
            onErrorEditSolutionResolution,
            showToasterMessage,
          });
        }
      } else {
        dispatch(
          toggleActionLinePrompt({
            isOpen: true,
            promptType: promptTypes.CANCEL,
            message: {
              title: texts.modal[logType][promptTypes.REQUIRED_FIELDS].title,
              description: texts.modal[logType][promptTypes.REQUIRED_FIELDS].description,
            },
          }),
        );
      }
    }

    if (editorActionTypes.ACTIVITY === logType) {
      const activity = activityUtils.convertToApiObj(requestData);

      await createActivity(activity);
    }

    if (!isNewEditor && !isEditing && !!requestData.attachments?.length) {
      const attachmentsToDelete = requestData.attachments.filter((attachment) => attachment.preparedForDeletion);
      if (attachmentsToDelete.length > 0) await deleteAttachmentsApi(attachmentsToDelete);
    }
    invalidateSRAttachments();

    const isArchived = sr?.archive === 1;
    invalidateArchivePermissions(isArchived, srId);
  };

  const initializeEditorAction = ({
    type = editorActionTypes.NOTE,
    isExpanded = true,
    logInformation = {},
    params = {},
  }) => {
    const editorData = initializeEditorData(type, logInformation, params);
    dispatch(
      updateActionLine({
        ...initialActionsState,
        type,
        data: editorData,
        lastSavedData: editorData,
        isExpanded,
        isEditorFullSize,
      }),
    );
  };

  const isMessageEditorValid = () => {
    const hasInvalidRecipients = data?.invalidRecipients?.length;
    const hasRecipient = !!data?.recipients?.length || !!data?.cc?.length || !!data?.bcc?.length;
    return !hasInvalidRecipients && hasRecipient;
  };

  const isEditorValid = () => {
    if (editorType === editorActionTypes.MESSAGE) {
      return isMessageEditorValid();
    }
    if (editorType === editorActionTypes.RESOLUTION) {
      return true;
    }
    if (editorType === editorActionTypes.ACTIVITY) {
      return activityUtils.isActivityValid(data);
    }
    if (data[editorActionSettings.editorTextKey]) {
      return true;
    }
    if (data.attachments?.length) {
      return true;
    }
  };

  const getMessageObjectByType = (isFromFooterCancelButton) => {
    if (isFromFooterCancelButton) {
      if (editorActionTypes.MESSAGE === editorType) {
        return {
          message: {
            title: texts.modal.MESSAGE.cancel.unsavedTitle,
            description: texts.modal.MESSAGE.cancel.description(null),
          },
          btnTexts: {
            cancelBtn: texts.modal.MESSAGE.cancel.btnCancelText,
            okBtn: texts.modal.MESSAGE.cancel.btnOkText(null),
          },
        };
      }
      if (editorActionTypes.RESOLUTION === editorType) {
        return {
          message: {
            title: texts.modal[editorType][promptTypes.CANCEL].title(),
            description: texts.modal[editorType][promptTypes.CANCEL].description(),
          },
          btnTexts: {
            cancelBtn: texts.modal.RESOLUTION.cancel.btnCancelText,
            okBtn: texts.modal.MESSAGE.cancel.btnOkText(null),
          },
        };
      }
      return {
        message: {
          title: texts.modal[editorType][promptTypes.CANCEL].unsavedTitle,
        },
      };
    }
    return null;
  };

  const clearEditorData = ({ isFromFooterCancelButton }) => {
    if (isEditorDirty) {
      dispatch(
        toggleActionLinePrompt({
          isOpen: true,
          promptType: promptTypes.CANCEL,
          ...getMessageObjectByType(isFromFooterCancelButton),
        }),
      );
    } else {
      toggleAuditLogsProperty({ auditLogIds: [activeIndex], property: 'isEdited' });
      const editorData = initializeEditorData(editorActionTypes.NOTE);
      dispatch(
        clearActionLine({
          type: editorActionTypes.NOTE,
          data: editorData,
          lastSavedData: editorData,
          isEditorDirty: false,
          dirtyFields: {},
        }),
      );
    }
  };

  const discardEditorData = () => {
    if (data.manualStatusChanged) {
      dispatch(updateSRStatus(prevSRStatus));
      dispatch(updateActionLineData({ manualStatusChanged: false }));
    }
  };

  const checkAndResetManualStatus = ({ logType = editorType }) => {
    if (editorActionTypes.RESOLUTION !== logType) {
      if (data.manualStatusChanged) {
        dispatch(updateSRStatus(prevSRStatus));
        dispatch(updateActionLineData({ manualStatusChanged: false }));
      }
    }
  };

  const setEditorExpanded = useCallback(
    (expanded) => {
      dispatch(updateActionLine({ isExpanded: expanded }));
    },
    [dispatch],
  );

  const handleEditorFocus = ({
    type = editorActionTypes.NOTE,
    isNewEditor = false,
    auditLogId = -1,
    isEditorChanged = isEditorDirty,
    params,
    logInformation = {},
  }) => {
    lockSR({ username, srId, queueLocking: false });

    if ((isNewEditor || activeIndex !== auditLogId) && isEditorChanged) {
      const promptType = parallelEditTypes.CREATE_TO_CREATE;
      handleTypeChangePrompt({ parallelEditType: promptType, type, auditLogId: activeIndex, params });
      return;
    }
    // from edit to create when no changed were made
    if (activeIndex !== auditLogId) {
      toggleAuditLogsProperty({ auditLogIds: [activeIndex], property: 'isEdited' });
      dispatch(clearActionLineData());
      dispatch(updateActionLine({ activeIndex: auditLogId, isNewEditor: auditLogId === -1, isExpanded: true }));
    }

    if (!isEditorChanged || isNewEditor) initializeEditorAction({ type, logInformation, params });
  };

  const handleEditorChange = useCallback(
    (updatedEditorField, newValue) => {
      try {
        const updatedData = { ...data, ...updateState(newValue, data) };
        
        dispatch(updateActionLineData(newValue));

        const isDirty = checkIsDirty({ updatedData, lastSavedData: data });
        
        dispatch(updateActionLineIsEditorDirty({ [updatedEditorField]: isDirty }));
        updateTicketPageValidation({ isValid: !isDirty });
      } catch (error) {
        console.error('Error =>', error);
      }
    },
    [data, dispatch, updateTicketPageValidation],
  );

  const toggleRichTextEdited = ({ auditLogId }) => {
    if (!isNewEditor && data.auditLogId !== auditLogId) {
      setAuditLogsProperty({ auditLogIds: [data.auditLogId, auditLogId], property: 'isEdited', value: true });
    } else {
      setAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isEdited', value: true });
    }
  };

  return {
    setEditorExpanded,
    initializeEditorAction,
    handleEditorFocus,
    handleEditorChange,
    handleActionLineOperation,
    saveEditorDataByType,
    clearEditorData,
    isEditorValid,
    deleteEditorItem,
    updateTicketPageValidation,
    initializeEditorData,
    toggleRichTextEdited,
    discardEditorData,
    checkAndResetManualStatus,
  };
};
