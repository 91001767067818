import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts(srType) {
  const texts = useRbTexts();
  const componentTexts = {
    noTitleText: texts?.['spaces.header.title.noTitleText'],
    srTypeText: texts?.[`spaces.header.srType_${srType}`],
    srTypeNewText: texts?.[`spaces.header.srType_${srType}_new`],
    lockedByText: texts?.['spaces.header.locked'] || 'SR is being edited by',
    lockedInQueueByText:
      texts?.['spaces.header.lockedInQueue'] ||
      'SR # is currently being edited by another agent and cannot be edited at the moment. Please try again later.',
    archivedText: texts?.['spaces.header.archived'] || 'Archived',
  };

  return componentTexts;
}
