import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import { hasOnlyJiraIntegrationFields } from 'features/IntegrationSection/integrations.utils';
import { StyledIntegrationsList } from './integrationsList.syles';
import { useLoadIntegrationFields } from '../../../remote-state/templateHooks';
import { IntegrationTypes } from '../../IntegrationSection/IntegrationSection.consts';
import { useFeatureFlagQuery } from '../../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../../constants/featureFlags';
import useTexts from '../../IntegrationSection/useTexts';
import { generateNewIntegrationSection } from './integrationsList.utils';

const JiraIntegrationOptions = ({ fields, currentJiraSectionId, handleAddSection, handleDeleteSection }) => {
  const hasJiraSection = (currentJiraSectionId !== null && currentJiraSectionId !== undefined);
  const { jiraSectionTitle, addJiraSection } = useTexts();

  const addIntegrationSection = () => {
    const jiraSection = generateNewIntegrationSection(jiraSectionTitle, fields);
    handleAddSection(jiraSection);
  };

  const deleteIntegrationSection = () => {
    handleDeleteSection(currentJiraSectionId);
  };

  const handleToggleJiraSection = () => {
    const handleSection = hasJiraSection ? deleteIntegrationSection : addIntegrationSection;
    handleSection();
  };

  return (
    <StyledIntegrationsList>
        <div className="text-element">{addJiraSection}</div>
      <SwitchToggle
        handleSwitchChange={handleToggleJiraSection}
        isChecked={hasJiraSection}
      />
    </StyledIntegrationsList>
  );
};

export const IntegrationsList = ({ handleAddSection, handleDeleteSection, sections }) => {
  const { data: isJiraEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.JIRA_ENABLED,
    defaultValue: false,
  });

  // This is a wrapper component for integration options.
  // If adding more integrations - iterate over the enabledIntegrations array to create a map of integration types and their data
  const enabledIntegrations = isJiraEnabled ? [IntegrationTypes.JIRA] : [];
  const [jiraSectionId, setJiraSectionId] = useState(null);
  const [integrationFieldsItems, setIntegrationFieldsItems] = useState([]);

  // TODO can we not do the API call if jira is not enabled? It will fetch all the fields because the query is empty
  const {
    data: jiraIntegrationData,
    isSuccess: isJiraFieldsSuccess,
    isFetching: isJiraFieldsFetching
  } = useLoadIntegrationFields(enabledIntegrations[0]);

  useEffect(() => {
    if (isJiraFieldsSuccess && !isJiraFieldsFetching) {
      setIntegrationFieldsItems(jiraIntegrationData?.currentList);
    }
  }, [jiraIntegrationData?.currentList, isJiraFieldsSuccess, isJiraFieldsFetching]);

  useEffect(() => {
    setJiraSectionId(
      sections.find(section => hasOnlyJiraIntegrationFields(section))?.id
    );
  }, [sections]);

  return (<>
    {!isEmpty(enabledIntegrations) && (
      <JiraIntegrationOptions
        fields={integrationFieldsItems}
        currentJiraSectionId={jiraSectionId}
        handleAddSection={handleAddSection}
        handleDeleteSection={handleDeleteSection}
      />
    )}
  </>);
};
