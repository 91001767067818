import { ReactComponent as ChevronIcon } from 'images/icons/workflows/chevron_right4.svg';
import { IconButton } from '../IconButton';
import { ProgressBar } from './components/ProgressBar';
import PhaseTitle from './components/PhaseTitle';
import { StyledAccordion } from './style';

export default function Phase({ phase, children, urlAccordionExpansion, isArchived }) {
  return (
    <StyledAccordion
      id={`phase-${phase.id}`}
      data-cy={`phase-${phase.id}`}
      className="phase"
      data-testid="phase"
      initialOpen={isArchived || urlAccordionExpansion}
      disableGutters
      elevation={0}
      closeIcon={<IconButton IconComponent={ChevronIcon} />}
      summary={
        <>
          <PhaseTitle title={phase.displayName} />
          <ProgressBar
            totalActionItems={phase.totalActionItems}
            completedActionItems={phase.completedActionItems}
            completedItemsPercentage={phase.completedItemsPercentage}
          />
        </>
      }
    >
      {children}
    </StyledAccordion>
  );
}
