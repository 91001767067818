const TIME_FRACTION = 0.6;
const FIVE_MINUTES = 5 * 60 * 1000;

export const CONSTS = {
  SERVERID: 'SERVERID',
  IDLE_TIME_FRACTION: 0.6,
  INTERVAL_TIME: FIVE_MINUTES,
  IDLE_TIME_ALLOWED: FIVE_MINUTES * TIME_FRACTION,
  SESSION_ID_LENGTH: 5,
  SESSION_ID_CHARACTERS: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
  EVENTS: ['click', 'keydown', 'mousemove', 'focus'],
  SESSION_STORAGE_ID: 'sessionStorageID',
};
