import { JIRA_ACTION_FIELD_NAME, JIRA_ISSUEKEY_FIELD_NAME } from '../../IntegrationSection/components/jira/jira.consts';

const generateNewIntegrationSection = (sectionTitle, fields) => ({
  id: 'jira-section',
  name: sectionTitle,
  nonSection: false,
  order: 0,
  instructions: null,
  sectionRows: [],
  viewOnlyGroups: [],
  viewOnly: true,
  ticketTemplateSectionPermissions: [],
  fields: fields
    .filter((field) => field.fieldName === JIRA_ACTION_FIELD_NAME || field.fieldName === JIRA_ISSUEKEY_FIELD_NAME)
    .map((fieldData) => ({
      fieldId: fieldData.id,
      fieldName: fieldData.fieldName,
      displayName: fieldData.displayName,
      defaultValue: fieldData.defaultValue || null,
      readOnly: false,
      customColumn: fieldData.customColumn,
      hideFieldCaption: false,
      required: false,
      hint: false,
      hintText: null,
      limitedPermissions: false,
      requiredStatuses: [],
      permissions: [],
      headerField: false,
      templateFieldAttributes: { ...fieldData, alwaysReadOnly: false },
      position: '',
      isHidden: fieldData.isHidden,
    })),
});

export { generateNewIntegrationSection };
