import { useEffect, useMemo, useState } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import { PlaceholderValues } from 'common/utils/constants';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import DatePicker from 'common/components/datePicker';
import FieldName from '../fieldName';
import { SR_PANEL_CONSTANTS } from "../../consts";

export default function ReadOnlyTextField(props) {
  const {
    field,
    displayName,
    fieldValue,
    onClick,
    source,
    required,
    fullwidth,
    sr,
    hasMoreDetails,
    url,
    urlParams,
    isTemplatePage,
    updateFieldValidation,
    isNoEditPermission
  } = props;
  const [text, setText] = useState('');
  const [isDate, setIsDate] = useState(false);

  const isExistingSR = getIsExistingSr(sr?.id);
  const fieldTypeId = field?.templateFieldAttributes?.ticketTemplateFieldType?.id;
  const isMultiline = fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT;
  const inlineFieldStyle = isMultiline ? {} : { width: '140px' };

  useEffect(() => {
    if (field?.fieldName === 'insertTime') {
      setIsDate(true);
    } else {
      setIsDate(false);
    }
  }, [field?.fieldName]);

  useEffect(() => {
    const initFieldValue =
      Number.isNaN(Number(fieldValue)) || fieldValue === null || fieldValue === '' ? fieldValue : Number(fieldValue);
    let initFieldDisplayName =
      typeof Number.isNaN(Number(fieldValue)) === 'object' ? initFieldValue.templateDisplayName : initFieldValue;
    if (!initFieldDisplayName && initFieldDisplayName !== 0) {
      if (!isExistingSR) {
        initFieldDisplayName = '';
      } else {
        initFieldDisplayName = isTemplatePage ? PlaceholderValues.NotAvailable : '';
      }
    }
    setText(initFieldDisplayName);
  }, [fieldValue, isExistingSR, isTemplatePage]);

  const isNewField = useMemo(() => {
    if (!isExistingSR) {
      if (text === PlaceholderValues.NotAvailable) {
        return false;
      }
      return true;
    }
    return false;
  }, [text, isExistingSR]);

  return (
    <>
      <FieldName
        url={url}
        required={required}
        urlParams={urlParams}
        fieldValue={fieldValue}
        fieldId={field?.fieldId}
        name={displayName}
        hasMoreDetails={hasMoreDetails}
        isTemplatePage={isTemplatePage}
        hintText={field?.hint && field?.hintText}
      />
      <div style={{ transform: 'translateX(-8px)' }}>
        {!isDate ? (
          <InlineFieldContainer
            ticket
            disabled
            isNoEditPermission={isNoEditPermission}
            text={text}
            source={source}
            required={required}
            fullwidth={fullwidth}
            isNewField={isNewField}
            style={inlineFieldStyle}
            fieldName={field?.fieldName}
            isTemplatePage={isTemplatePage}
            updateFieldValidation={updateFieldValidation}
            multiline={isMultiline}
            onClick={onClick}
          />
        ) : (
          <DatePicker date={text} disabled source={source} ticket />
        )}
      </div>
    </>
  );
}
