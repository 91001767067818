export const CONSTANTS = {
  TIMEOUT_TO_DISPLAY_ERROR_MESSAGE: 600000,
  ITSM_LINK: 'https://www.sysaid.com/',
};

export const LICENSE_VALIDATION_STATES = {
  DEV_MODE: 'dev-mode',
  LOADING: 'loading',
  SUCCESS: 'true',
  FAILED: 'false',
};
