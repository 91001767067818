import { styled } from '@mui/material/styles';
import { Box, CardActions, Menu, Stack, TextField } from '@mui/material';

export const StyledFormFooter = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
  gap: '10px',
}));

export const StyledWidgetContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '380px',
  fontSize: theme.fontSize.main,
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
  padding: '4px 10px',
  columnGap: '10px',
  '.widgetContentWrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '24px',
  },
  '.widgetContentBlock': {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '24px',
  },
  '.firstBlock': {
    justifyContent: 'flex-start',
    columnGap: '7px',
  },
  '.kebab-wrapper': {
    padding: '6px',
  }
}));

export const TruncatedText = styled(Box)({
  maxWidth: '320px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const FieldGroupWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  borderRadius: '8px',
  padding: '20px',
}));

export const FieldsRowWrapper = styled(Stack)({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '12px',
  width: '49%',
  position: 'static',
  borderRadius: '4px',
  padding: '8px 4px',
});

export const StyledDropdown = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  maxWidth: 'calc(100% - 200px)',

  '.field-name-title': {
    padding: '2px 0px 2px 8px',
  },
});

export const StyledIntegrationFieldContainer =  styled(Box)({
  flexBasis: '49%',
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
  paddingLeft: '16px',

  '.MuiBox-root': {
    maxWidth: '100%',
  },
});

const handleBackgroundColor = ({ isOpen, hasValue, theme }) => {
  if (hasValue) return theme?.palette?.generic?.primary;
  return isOpen
    ? `${theme?.palette?.background?.bg_hover_main}!important`
    : `${theme?.palette?.background?.bg_new_input_text}!important`;
};

export const StyledIntegrationTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['isOpen', 'hasValue'].includes(prop),
})(({ theme, isOpen, hasValue }) => ({
  width: 'auto',
  backgroundColor: handleBackgroundColor({ isOpen, hasValue, theme }),
  borderRadius: '4px',
  '& .MuiFormHelperText-root': {
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: theme?.fonts?.robotoRegular,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.MuiInputBase-fullWidth': {
      '& .MuiOutlinedInput-input': {
        width: '100%',
      },
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      fontSize: theme.fontSize.main,
      height: 'unset',
      lineHeight: 1.4286,
      minHeight: 'unset',
      padding: theme?.spacing(0.5, 1),
      color: theme?.palette?.text?.txt_default,
      '&::placeholder': {
        opacity: 1,
        color: '#000',
      },
    },
    '& .MuiPopover-root': {
      position: 'absolute',
      transform: 'translate(0, 100%)',
      '& .MuiPaper-root': {
        position: 'static',
      },
    },
  },
}));

export const StyledDropDownMenu = styled(Menu, {
  shouldForwardProp: (prop) => !['containerWidth'].includes(prop),
})(({ theme, containerWidth }) => ({
  '.MuiPaper-root': {
    padding: '8px',
    borderRadius: '6px',
    maxHeight: '250px',
    boxShadow: `0px 2px 8px ${theme.palette.background.boxShadow}`,
    height: 'fit-content',
    width: containerWidth,
  },
  ul: {
    padding: 0,
    height: '100%',
  },
  li: {
    padding: '0',
    '>*': {
      padding: '12px 16px',
      width: '100%',
      justifyContent: 'flex-start',
    },
    '&:hover': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      padding: '0',
      alignSelf: 'stretch',
      borderRadius: '6px',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
}));

export const StyledTooltip = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '100%',
  padding: '8px',
  width: 'max-content',
  lineHeight: 1.429,
  borderRadius: '6px',
  fontSize: theme.fontSize.small,
  color: 'white',
  backgroundColor: theme?.palette?.grey?.tooltip_bg,
  boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
}));
