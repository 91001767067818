import { createSlice } from '@reduxjs/toolkit';
import { sliceNames } from './constants';

// Category is deleted
export const EMPTY_CATEGORY = -1;

export const initialState = {
  primaryCategories: [],
  secondaryCategories: [],
  thirdLevelCategories: [],
  isOpened: null,
  selectedPrimaryCategory: undefined,
  selectedSecondaryCategory: undefined,
  selectedThirdLevelCategory: undefined,
  suggestedCategoriesBySrId: {},
  secondaryPreviousCategories: [],
  thirdLevelPreviousCategories: [],
};

function getCategoryBySrId(id, categories) {
  return categories.find((category) => category.id === id);
}

function updatePrevCategoryState(id, category, categories) {
  const prevCategory = getCategoryBySrId(id, categories);
  if (!prevCategory) {
    categories.push({ id, value: category.value });
    return;
  }
  prevCategory.value = category.value;
}

export const categorySlice = createSlice({
  name: sliceNames.categoriesSliceName,
  initialState,
  reducers: {
    removePrimaryCategory: (state, action) => {
      if (
        state.primaryCategories?.findIndex(
          (category) => category.id === action.payload.id && category.value === action.payload.value,
        ) >= 0
      ) {
        return;
      }
      const index = state.primaryCategories?.findIndex((category) => category.id === action.payload.id);

      if (index >= 0) {
        state.primaryCategories.splice(index, 1);

        state.secondaryCategories.forEach((category, idx) => {
          if (category.id === action.payload.id) {
            state.secondaryCategories.splice(idx, 1);
          }
        });
        state.thirdLevelCategories.forEach((category, idx) => {
          if (category.id === action.payload.id) {
            state.thirdLevelCategories.splice(idx, 1);
          }
        });
      }

      state.primaryCategories = [...state.primaryCategories];
      state.secondaryCategories = [...state.secondaryCategories];
      state.thirdLevelCategories = [...state.thirdLevelCategories];
    },

    addPrimaryCategory: (state, action) => {
      // category.id - service request id
      // category.value - ID of problem type
      // category.value = -1 means that service request doesn't have this type of category

      const isTheSameCategory =
        state.primaryCategories?.findIndex(
          (category) => category.id === action.payload.id && category.value === action.payload.value,
        ) >= 0;
      if (isTheSameCategory) {
        return;
      }

      const index = state.primaryCategories?.findIndex((category) => category.id === action.payload.id);
      const isSrExists = index >= 0;

      if (isSrExists) {
        state.primaryCategories.splice(index, 1);

        state.secondaryCategories.forEach((category) => {
          if (category.id === action.payload.id) {
            category.value = EMPTY_CATEGORY;
          }
        });
        state.thirdLevelCategories.forEach((category) => {
          if (category.id === action.payload.id) {
            category.value = EMPTY_CATEGORY;
          }
        });
      }

      state.primaryCategories = [action.payload, ...state.primaryCategories];
    },
    addSecondaryCategory: (state, action) => {
      if (
        state.secondaryCategories?.findIndex(
          (category) => category.id === action.payload.id && category.value === action.payload.value,
        ) >= 0
      ) {
        return;
      }
      const index = state.secondaryCategories?.findIndex((category) => category.id === action.payload.id);

      if (index >= 0) {
        updatePrevCategoryState(action.payload.id, state.secondaryCategories[index], state.secondaryPreviousCategories);
        state.secondaryCategories.splice(index, 1);

        state.thirdLevelCategories.forEach((category) => {
          if (category.id === action.payload.id) {
            updatePrevCategoryState(action.payload.id, category, state.thirdLevelPreviousCategories);
            category.value = -1;
          }
        });
      }
      state.secondaryCategories = [action.payload, ...state.secondaryCategories];
    },
    addThirdLevelCategory: (state, action) => {
      if (
        state.thirdLevelCategories?.findIndex(
          (category) => category.id === action.payload.id && category.value === action.payload.value,
        ) >= 0
      ) {
        return;
      }
      const index = state.thirdLevelCategories?.findIndex((category) => category.id === action.payload.id);

      if (index >= 0) {
        updatePrevCategoryState(
          action.payload.id,
          state.thirdLevelCategories[index],
          state.thirdLevelPreviousCategories,
        );
        state.thirdLevelCategories.splice(index, 1);
      }
      state.thirdLevelCategories = [action.payload, ...state.thirdLevelCategories];
    },
    setIsOpened: (state, action) => {
      state.isOpened = action.payload;
    },
    removePrimaryCategories: (state) => {
      state.primaryCategories = [];
    },
    removeSecondaryCategories: (state) => {
      state.secondaryCategories = [];
    },
    removeThirdLevelCategories: (state) => {
      state.thirdLevelCategories = [];
    },
    setSelectedPrimaryCategory: (state, action) => {
      state.selectedPrimaryCategory = action.payload;
    },
    setSelectedSecondaryCategory: (state, action) => {
      state.selectedSecondaryCategory = action.payload;
    },
    setSelectedThirdLevelCategory: (state, action) => {
      state.selectedThirdLevelCategory = action.payload;
    },
    setSuggestedCategoriesById: (state, { payload: suggestedCategoriesToIdPairs }) => {
      suggestedCategoriesToIdPairs
        .filter(({ suggestedCategory }) => suggestedCategory.id !== 0)
        .forEach(({ srID, suggestedCategory }) => {
          state.suggestedCategoriesBySrId[srID] = suggestedCategory;
        });
    },
    clearSuggestedCategoriesById: (state) => {
      state.suggestedCategoriesBySrId = {};
    },
    setSuggestedCategorySelected: (state, { payload }) => {
      const { srId, isSelected } = payload;

      const suggestedCategory = state.suggestedCategoriesBySrId[srId];

      if (suggestedCategory) {
        suggestedCategory.isSelected = isSelected;
      }
    },
    updateEmptyCategory: (state, action) => {
      const srId = action.payload.id;
      if (getCategoryBySrId(srId, state.primaryCategories).value === EMPTY_CATEGORY) {
        return;
      }
      const categoryName = action.payload.category;
      if (categoryName === 'secondaryCategory') {
        const prevCategory = getCategoryBySrId(srId, state.secondaryPreviousCategories);
        const index = state.secondaryCategories.findIndex((category) => category.id === srId);
        if (index >= 0 && prevCategory) {
          state.secondaryCategories.splice(index, 1);
          state.secondaryCategories.push(prevCategory);
        }
      } else if (categoryName === 'thirdLevelCategory') {
        const prevCategory = getCategoryBySrId(srId, state.thirdLevelPreviousCategories);
        const index = state.thirdLevelCategories.findIndex((category) => category.id === srId);
        if (index >= 0 && prevCategory) {
          state.thirdLevelCategories.splice(index, 1);
          state.thirdLevelCategories.push(prevCategory);
        }
      }
    },
  },
});

export const {
  addPrimaryCategory,
  addSecondaryCategory,
  addThirdLevelCategory,
  setIsOpened,
  removePrimaryCategories,
  removeSecondaryCategories,
  removePrimaryCategory,
  removeThirdLevelCategories,
  setSelectedPrimaryCategory,
  setSelectedSecondaryCategory,
  setSelectedThirdLevelCategory,
  setSuggestedCategoriesById,
  clearSuggestedCategoriesById,
  setSuggestedCategorySelected,
  updateEmptyCategory,
} = categorySlice.actions;

export const selectPrimaryCategory = (id) => (allSliceState) =>
  allSliceState?.category?.primaryCategories?.find((category) => category.id === id);

export const selectSecondaryCategory = (id) => (allSliceState) =>
  allSliceState?.category?.secondaryCategories?.find((category) => category.id === id);

export const selectThirdLevelCategory = (id) => (allSliceState) =>
  allSliceState?.category?.thirdLevelCategories?.find((category) => category.id === id);

export const selectIsOpened = () => (allSliceState) => allSliceState?.category?.isOpened;

export const selectSelectedPrimaryCategory = () => (allSliceState) => allSliceState?.category?.selectedPrimaryCategory;
export const selectSelectedSecondaryCategory = () => (allSliceState) =>
  allSliceState?.category?.selectedSecondaryCategory;
export const selectSelectedThirdLevelCategory = () => (allSliceState) =>
  allSliceState?.category?.selectedThirdLevelCategory;

export const selectSuggestedCategoryById = (srId) => (allSlicesState) =>
  allSlicesState.category.suggestedCategoriesBySrId[srId];

export default categorySlice.reducer;
export const categorySliceName = categorySlice.name;
