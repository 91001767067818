import { useEffect, useRef, useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { TicketSummaryTooltip } from 'common/components/summaryPopup/Tooltip';
import { useTrackIsCustomCompFocused } from 'common/components/summaryPopup/Tooltip/ticketSummaryTooltipHooks';
import { PlaceholderValues } from 'common/utils/constants';
import MissingTemplateTooltip from 'features/queue/grid/defaultCellRender/CellComponent/MissingTemplateTooltip';
import { InlineField } from './index';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from '../../../../store/fieldValidationsSlice';

export const InlineFieldContainer = memo((props) => {
  const {
    text,
    limit,
    source,
    ticket,
    onFocus,
    disabled,
    readOnly,
    required,
    fullwidth,
    multiline,
    fieldName,
    isNewField,
    placeholder,
    hoverEffect,
    delayedHover,
    isMultilineRTF,
    tooltipClassName,
    type,
    subType,
    notInTemplate,
    isNoEditPermission
  } = props;

  const {
    CustomFieldComponent,
    fieldId,
    handleSaveValue,
    showSummaryTooltip,
    disableTooltip,
    handleDirty,
    updateFieldValidation,
    fieldTypeId,
    isFieldContentSize,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(typeof text !== 'undefined' && text !== null ? String(text) : '');
  const forceShowError = useSelector(isNewField? selectForceFloatingTicketShowValidationError : selectForceShowValidationError);
  const [isError, setIsError] = useState(false);
  const inputRef = useRef();
  const [isOverflow, setIsOverflow] = useState(inputRef?.current?.scrollWidth > inputRef?.current?.offsetWidth);
  const { onFocus: customOnFocus, onBlur, isChildFocused } = useTrackIsCustomCompFocused();

  const checkIsError = useCallback(() => {
    const isInvalid =
      typeof currentValue === 'string' &&
      (!currentValue?.toString()?.trim()?.length || currentValue === PlaceholderValues.NotAvailable) &&
      required;
    const newFieldError = isNewField && isInvalid && forceShowError;
    const existingFieldError = !isNewField && isInvalid;
    return newFieldError || existingFieldError;
  }, [forceShowError, currentValue, isNewField, required]);

  const checkAndUpdateValidationStatus = useCallback(() => {
    let isValid = !checkIsError();
    setIsError(!isValid);
    if (required && (currentValue === PlaceholderValues.NotAvailable || currentValue?.toString()?.trim()?.length === 0)) {
      isValid = false;
    }
    if (updateFieldValidation) {
      updateFieldValidation(fieldName, required, isValid);
    }
    return isValid;
  }, [fieldName, required, updateFieldValidation, currentValue, checkIsError]);

  useEffect(() => {
    setCurrentValue(typeof text !== 'undefined' && text !== null ? text : '');
  }, [text]);

  const handleChangeValue = (event) => {
    if (type === 'number') {
      if (event.target.value) {
        if (subType === 'integer') {
          setCurrentValue(parseInt(event.target.value, 10)); // removing decimal point
        } else {
          setCurrentValue(Number(event.target.value));
        }
      } else {
        setCurrentValue(event.target.value);
      }
    } else {
      setCurrentValue(event.target.value || '');
    }
  };

  useEffect(() => {
    if (isMultilineRTF || multiline || currentValue?.length > 25) {
      setIsOverflow(true);
    } else {
      setIsOverflow(inputRef?.current?.scrollWidth > inputRef?.current?.offsetWidth);
    }
  }, [multiline, isMultilineRTF, currentValue, checkAndUpdateValidationStatus]);

  useEffect(() => {
    checkAndUpdateValidationStatus();
  }, [checkAndUpdateValidationStatus, currentValue, required]);

  const handleSave = (value) => {
    if (handleDirty) {
      handleDirty({ [fieldName]: value });
    }
    if (!fieldName) {
      console.log('[InlineFieldContainer] No name found for the property that is being edited (text field)');
      return;
    }
    const updateObj = { [fieldName]: value };

    handleSaveValue(updateObj);
  };

  const commonProps = {
    value: currentValue,
    onChange: handleChangeValue,
    text,
    handleSave,
    isError,
    setIsError,
    disabled,
    readOnly,
    required,
  };

  const handleOnFocus = () => {
    if (showSummaryTooltip) {
      customOnFocus();
    }
    if (onFocus) {
      onFocus(fieldId);
    }
  };

  if (CustomFieldComponent) {
    const customFieldCompInst = (
      <CustomFieldComponent {...rest} {...commonProps} onFocus={handleOnFocus} onBlur={onBlur} isFieldContentSize={isFieldContentSize} />
    );

    return showSummaryTooltip ? (
      <TicketSummaryTooltip isWrappedInputFocused={isChildFocused}>{customFieldCompInst}</TicketSummaryTooltip>
    ) : (
      customFieldCompInst
    );
  }
  return notInTemplate ? (
    <MissingTemplateTooltip />
  ) : (
    <InlineField
      {...rest}
      {...commonProps}
      isNoEditPermission={isNoEditPermission}
      placeholder={placeholder}
      source={source}
      fullwidth={fullwidth && 'true'}
      inputRef={inputRef}
      limit={limit}
      ticket={ticket}
      delayedHover={delayedHover}
      hoverEffect={hoverEffect}
      isOverflow={isOverflow}
      setIsOverflow={setIsOverflow}
      name={fieldName}
      isNewField={isNewField}
      showSummaryTooltip={showSummaryTooltip}
      disableTooltip={disableTooltip}
      onFocus={onFocus}
      tooltipClassName={tooltipClassName}
      fieldTypeId={fieldTypeId}
    />
  );
});
