import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { QUERIES_KEYS } from 'constant';
import { getKeepALive } from 'services/auth';
import _ from 'lodash';
import { isLocalHost } from 'services/localhost';
import { APP_CONSTANTS } from 'constants/app';
import { lastUserActivity, setIsAuthorized, setUserActivity, showMsg } from '../../../store/keepAliveSlice';
import { CONSTS } from './keepAlive.consts';

const isLocalhost = isLocalHost();
const isQAMode =
  localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) || localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) === null;

export const useKeepAlive = () => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [userIdle, setUserIdle] = useState(false);

  const lastUserAvtivity = useSelector(lastUserActivity);

  const setSessionID = () => {
    if (!sessionStorage.getItem(CONSTS.SESSION_STORAGE_ID)) {
      let randomText = '';

      for (let i = 0; i < CONSTS.SESSION_ID_LENGTH; i++) {
        randomText += CONSTS.SESSION_ID_CHARACTERS.charAt(_.random(0, CONSTS.SESSION_ID_CHARACTERS.length - 1));
      }

      const sessionStorageID = new Date().getTime() + randomText;
      sessionStorage.setItem(CONSTS.SESSION_STORAGE_ID, sessionStorageID);
    }
  };

  const handleUserActivity = useCallback(() => {
    if (timerRef.current) {
      setUserIdle(false);
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const IdleTime = new Date().getTime();
      setUserIdle(true);
      dispatch(setUserActivity(IdleTime));
    }, CONSTS.IDLE_TIME_ALLOWED);
  }, [dispatch]);

  const { data, status, error } = useQuery([QUERIES_KEYS.KEEP_ALIVE], {
    queryFn: () => getKeepALive(new Date().getTime(), userIdle ? lastUserAvtivity : new Date().getTime()),

    refetchInterval: CONSTS.INTERVAL_TIME,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    staleTime: CONSTS.IDLE_TIME_ALLOWED,
    enabled:
      !window.location.pathname.includes('/login') &&
      !window.location.pathname.includes('/logout') &&
      !isLocalhost &&
      !isQAMode,
  });

  useEffect(() => {
    if (status === 'success' && data) {
      const serverIdCookie = data?.cookies ? data?.cookies[CONSTS.SERVERID] : null;
      if (serverIdCookie) {
        document.cookie = `${CONSTS.SERVERID}=${serverIdCookie}; path=/;`;
      }

      if (
        !data?.data?.login &&
        !window.location.pathname.includes('/login') &&
        !window.location.pathname.includes('/logout')
      ) {
        window.location.reload();
      }
    } else if (status === 'error' && error) {
      dispatch(showMsg({ text: error.message }));
      dispatch(setIsAuthorized(false));
    }
  }, [status, dispatch, error, data]);

  useEffect(() => {
    setSessionID();
  }, []);

  useEffect(() => {
    CONSTS.EVENTS.forEach((event) => window.addEventListener(event, handleUserActivity));

    return () => {
      CONSTS.EVENTS.forEach((event) => window.removeEventListener(event, handleUserActivity));

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [dispatch, handleUserActivity]);
};
