import { useCallback, useState, useRef } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import { isEqual } from 'lodash-es';
import MessagePrompt from 'common/components/messagePrompt';
import { useHandleTicketLock, useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { getNestedOrBaseValue, looseCompare, buildAssigneeApiObject } from './utils';
import { WorkflowsContainer } from './style';
import { useSaveActionItem, useWorkflowQuery, attributeSelector } from './api/workflowQueries';
import Phase from './components/Phase';
import ActionItem from './components/ActionItem';
import useTexts from './hooks/useTexts';
import { WORFKLOWS_CONSTANTS } from './constants';

export default function Workflows({ isArchived }) {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const userAccount = useSelector(selectActiveUser);
  const actionItemDisabledTypes = WORFKLOWS_CONSTANTS.ACTION_ITEM_DISABLED_TYPES;

  const { data: workflowData, isInitialLoading } = useWorkflowQuery({
    srId,
    select: attributeSelector,
  });

  const { mutate: saveActionItem } = useSaveActionItem(srId);
  const { changeStatusPromptTitle, changeStatusPromptText, tryAgainText } = useTexts();
  const { mutateAsync: lockSR } = useHandleTicketLock(srId);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const isViewer = lockingDetails?.isLocked && userAccount?.username !== lockingDetails?.lockingUser;
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptProperties, setPromptProperties] = useState(null);
  const previousSave = useRef(null);
  const actionItemIdURL = router.latestLocation?.search?.action_item_id;
  const phaseIdUrl = router.latestLocation?.search?.phaseId;

  const handleSave = useCallback(
    async ({ actionItemId, value, fieldName, phaseId }) => {
      const comparisonPayload = { phaseId, actionItemId, fieldName, value };
      if (isEqual(previousSave?.current, comparisonPayload)) return;
      previousSave.current = { phaseId, actionItemId, fieldName, value };

      if (!lockingDetails?.isLocked) {
        await lockSR({ username: userAccount?.username, srId });
      }

      const isStatusUpdate = fieldName === WORFKLOWS_CONSTANTS.FIELDS.STATUS.NAME;
      const isAssigneeUpdate = fieldName === WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_TO.NAME;
      const originalPhase = workflowData?.phases.find((phase) => phase.id === phaseId);
      const originalActionItem = originalPhase.actionItems.find((actionItem) => actionItem.id === actionItemId);
      if (isAssigneeUpdate) {
        const groupUnchanged = looseCompare(
          originalActionItem.assignedGroup,
          getNestedOrBaseValue(value, 'group', 'group.groupName'),
        );
        const assigneeUnchanged = looseCompare(
          originalActionItem.assignee,
          getNestedOrBaseValue(value, 'user', 'user.userName'),
        );
        if (groupUnchanged && assigneeUnchanged) return;
      } else if (isStatusUpdate) {
        /* eslint-disable eqeqeq */
        if (originalActionItem.completed && value == WORFKLOWS_CONSTANTS.ACTION_ITEM_COMPLETE_VALUE) return;
        if (!originalActionItem.completed && value == WORFKLOWS_CONSTANTS.ACTION_ITEM_ENABLE_VALUE) return;
      } else {
        const originalField = originalActionItem.fields.find((field) => field.fieldName === fieldName);
        if (originalField?.value == value) return;
        /* eslint-enable eqeqeq */
      }

      let updateData = {
        fieldName,
        value,
      };

      if (fieldName === WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_TO.NAME) {
        updateData = buildAssigneeApiObject({ value });
      }

      saveActionItem(
        { data: updateData, actionItemId, phaseId },
        {
          onError: () => {
            setPromptProperties({
              cb: () => {
                setShowPrompt(false);
                handleSave({ actionItemId, phaseId, fieldName, value });
              },
            });
            setShowPrompt(true);
          },
        },
      );
    },
    [lockSR, lockingDetails?.isLocked, saveActionItem, srId, userAccount?.username, workflowData?.phases],
  );

  return (
    <WorkflowsContainer data-testid="workflows">
      {showPrompt && (
        <MessagePrompt
          open
          btnOkText={tryAgainText}
          showCancelBtn
          title={changeStatusPromptTitle}
          onClose={() => setShowPrompt(false)}
          onOkClick={promptProperties?.cb}
        >
          {changeStatusPromptText}
        </MessagePrompt>
      )}
      {!isInitialLoading &&
        workflowData?.phases?.map((phase) => (
          <Phase
            key={`phase-${phase.id}`}
            phase={phase}
            urlAccordionExpansion={phase.id === phaseIdUrl}
            isArchived={isArchived}
          >
            {phase?.actionItems?.map((actionItem) => (
              <ActionItem
                key={`ai-${actionItem.id}`}
                phaseId={phase.id}
                actionItem={actionItem}
                handleSave={handleSave}
                isViewer={isViewer}
                isDisabled={actionItem.disabled?.reason === actionItemDisabledTypes.INSUFFICIENT_PERMISSIONS}
                urlAccordionExpansion={actionItem.id === actionItemIdURL}
                isArchived={isArchived}
              />
            ))}
          </Phase>
        ))}
    </WorkflowsContainer>
  );
}
