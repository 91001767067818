import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { selectTheme, selectDirection, setDirection } from 'store/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import { details } from '../../store/userSlice';
import { useFeatureFlagQuery } from '../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../constants/featureFlags';

export default function Theme({ children }) {
  const dispatch = useDispatch();
  const themeSlice = useSelector(selectTheme);
  const direction = useSelector(selectDirection);
  const [currentTheme, setCurrentTheme] = useState(lightTheme(direction));
  const userDetails = useSelector(details);
  const { data: isRTLEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RTL_ENABLED,
    defaultValue: false,
  });
  const dir = i18n.dir(userDetails?.language);

  useEffect(() => {
    if (userDetails?.language && isRTLEnabled) {
      document.documentElement.dir = dir;
      if (direction !== dir) {
        dispatch(setDirection(dir));
      }
    }
  }, [userDetails, isRTLEnabled, currentTheme, direction, dispatch, dir]);

  useEffect(() => {
    if (themeSlice === 'light') {
      setCurrentTheme(lightTheme(direction));
    } else if (themeSlice === 'dark')  {
      setCurrentTheme(darkTheme(direction));
    }
  }, [themeSlice, direction]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
