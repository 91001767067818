import { useRouter } from '@tanstack/react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { ClickAwayListener } from '@mui/material';
import { selectActionLine, updateActionLineData } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import useTexts from 'features/resolutionPanel/useTexts';
import { useSR } from 'remote-state/ticketServiceHooks';
import { getUserDetailsByName } from 'services/userService';
import { useCallback, useEffect } from 'react';
import { StyledMessageHeader, StyledItemWrapper, StyledItemText } from './style';
import SenderInput from './SenderInput';
import RecipientInput from './RecipientInput';
import SubjectInput from './SubjectInput';

const CLICK_BY_FIELD = {
  cc: { isCcExpanded: true, isCcFocused: true },
  bcc: { isBccExpanded: true, isBccFocused: true },
};

const MessageHeader = () => {
  const router = useRouter();
  const texts = useTexts();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const srId = router.latestLocation.search.id;
  const { title: srTitle } = queryClient.getQueryData(['srId', srId]) || {};
  const { data } = useSelector(selectActionLine);
  const copyRecipientCount = data?.cc?.length + data?.bcc?.length;

  const {
    sr: { data: srObject },
  } = useSR();
  const populateCC = useCallback(async () => {
    if (srObject?.cc) {
      const srCCs = srObject.cc.split(',');
      const ccUsers = [];

      await Promise.all(
        srCCs.map(async (cc) => {
          const user = await queryClient.fetchQuery({
            queryKey: ['ccUser', cc],
            queryFn: () => getUserDetailsByName(cc),
          });

          const ccUser = {
            id: user?.id,
            name: user?.calculatedUserName,
            emailAddress: user?.emailAddress,
            type: 'user',
          };
          ccUsers.push(ccUser);
        }),
      );

      dispatch(
        updateActionLineData({
          cc: ccUsers,
          isCcExpanded: true,
        }),
      );
    }
  }, [srObject, dispatch, queryClient]);

  useEffect(() => {
    populateCC();
  }, [populateCC]);

  const handleFocus = () => {
    dispatch(
      updateActionLineData({
        isCcExpanded: !!data?.cc?.length || data.isCcExpanded,
        isBccExpanded: !!data?.bcc?.length || data.isBccExpanded,
      }),
    );
  };

  const onCopyRecipientsClick = (fieldName) => {
    dispatch(updateActionLineData(CLICK_BY_FIELD[fieldName]));
  };

  const handleClickAway = () => {
    dispatch(
      updateActionLineData({
        isCcExpanded: false,
        isBccExpanded: false,
      }),
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledMessageHeader onFocus={handleFocus} data-testid="message-header-wrapper" data-cy="message-header-wrapper">
        <StyledItemWrapper alignItems="center">
          <StyledItemText>{texts.sender}</StyledItemText>
          <SenderInput name="sender" selected={data.sender} />
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemText>{texts.recipient}</StyledItemText>
          <RecipientInput
            name="recipients"
            selected={data.recipients}
            isFocused={data.isRecipientsFocused}
            copyRecipientCount={copyRecipientCount}
          />

          {(!data.isCcExpanded || !data.isBccExpanded) && (
            <StyledItemText className="copy-recipients-wrapper">
              {!data.isCcExpanded && (
                <span
                  className="copy-recipient-span"
                  data-testid="cc-span"
                  onClick={() => onCopyRecipientsClick('cc')}
                  role="none"
                >
                  {texts.cc}
                </span>
              )}
              {/* TEMPORARY IN COMMENT */}
              {/* {!data.isBccExpanded && (
                <span
                  className="copy-recipient-span"
                  data-testid="bcc-span"
                  onClick={() => onCopyRecipientsClick('bcc')}
                  role="none"
                >
                  {texts.bcc}
                </span>
              )} */}
            </StyledItemText>
          )}
        </StyledItemWrapper>
        {data.isCcExpanded && (
          <StyledItemWrapper>
            <StyledItemText data-testid="cc-field">{texts.cc}</StyledItemText>
            <RecipientInput name="cc" selected={data.cc} isFocused={data.isCcFocused} />
          </StyledItemWrapper>
        )}
        {data.isBccExpanded && (
          <StyledItemWrapper>
            <StyledItemText data-testid="bcc-field">{texts.bcc}</StyledItemText>
            <RecipientInput name="bcc" selected={data.bcc} isFocused={data.isBccFocused} />
          </StyledItemWrapper>
        )}
        <StyledItemWrapper alignItems="center" mb={1.5}>
          <StyledItemText>{texts.subject}</StyledItemText>
          <SubjectInput title={data.subject || srTitle} srId={srId} />
        </StyledItemWrapper>
      </StyledMessageHeader>
    </ClickAwayListener>
  );
};

export default MessageHeader;
