import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import JiraLogo from 'images/photos/jira-logo.png';
import { getIssueDetails } from 'services/jiraIntegration';
import { useUpdateSR } from 'remote-state/ticketServiceHooks';
import { lockSR } from 'services/ticketService';
import { selectActiveUser } from 'store/userSlice';
import { KebabMenu } from 'common/components/kebabMenu';
import Tooltip from 'common/components/tooltip';
import { useStylesBootstrap } from '../../../queue/grid/customSelectBox';
import { StyledWidgetContainer, TruncatedText } from '../../integrations.styles';
import { UNLINK_JIRA_ISSUE } from './jira.consts';
import useTexts from '../../useTexts';

export const JiraIssueDetails = (props) => {
  const { issueKeyField, jiraIssueKey, actionField, sr } = props;
  const tooltipClass = useStylesBootstrap();
  const { unlinkJiraIssue } = useTexts();
  const { checkAndUpdateSr } = useUpdateSR();
  const userAccount = useSelector(selectActiveUser);

  const [linkedIssue, setLinkedIssue] = useState();

  useEffect(() => {
    getIssueDetails(jiraIssueKey).then(issue => setLinkedIssue(issue));
  }, [jiraIssueKey]);

  if (!linkedIssue) return;

  const { key, name, status, lastUpdateDate, url } = linkedIssue;

  const formattedUpdateDate = format(new Date(lastUpdateDate), 'dd/MM/yyyy');

  const unlinkIssue = async () => {
    setLinkedIssue(null);
    if (sr.id) {
      await lockSR({ username: userAccount?.username, srId: sr.id });
    }
    await checkAndUpdateSr({
      requestParams: {
        [issueKeyField.fieldName]: null,
        [actionField.fieldName]: null,
      },
      id: sr.id,
      queueUpdate: true,
    });
  };

  const handleKebabMenuAction = (actionName) => {
    switch (actionName) {
      case UNLINK_JIRA_ISSUE:
        unlinkIssue();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <StyledWidgetContainer>
        <div className="widgetContentWrapper">
          <div className="widgetContentBlock firstBlock">
            <img className="toggle-img" src={JiraLogo} alt="Jira Icon" />
            <Tooltip
              arrow
              placement="top"
              classes={tooltipClass}
              text={<TruncatedText><b>{name}</b></TruncatedText>}
            >
              {name}
            </Tooltip>
          </div>
          <div className="widgetContentBlock">
            <a href={url} target="_blank" rel="noreferrer">
              {key}
            </a>
            <div>{status}</div>
            <div>Updated: {formattedUpdateDate}</div>
          </div>
        </div>
        <KebabMenu
          className="widgetContentMenu"
          alignToLeft={false}
          isVisible
          handleKebabMenuAction={handleKebabMenuAction}
          actionList={[{ name: UNLINK_JIRA_ISSUE, defaultCaption: unlinkJiraIssue }]}
        />
      </StyledWidgetContainer>
    </>
  );
};
