import { Box, styled } from '@mui/material';

export const TicketBodySelectButton = styled(Box, {
  shouldForwardProp: (prop) =>
    !['hasTextStyles', 'hasValues', 'disabled', 'isNewField', 'isError', 'isEmptyField', 'anchor', 'isTemplatePage', 'isReadOnly'].includes(prop),
})(({ theme, hasTextStyles, disabled, isNewField, isError, isEmptyField, anchor, isTemplatePage, isReadOnly }) => {
  const colors = {
    txt_default: theme?.palette?.text?.txt_default,
    secondary_txt: theme?.palette?.text?.secondary_txt,
    bt_blue_field_empty: theme?.palette?.blueLabels?.bt_blue_field_empty,
    bt_blue_field_empty_hover: theme?.palette?.blueLabels?.bt_blue_field_empty_hover,
    bt_blue_chip_reg: theme?.palette?.blueLabels?.bt_blue_chip_reg,
    bt_red_reg: theme?.palette?.labels?.bt_red_reg,
    bt_red_mid: theme?.palette?.labels?.bt_red_mid,
    bt_red_light: theme?.palette?.labels?.bt_red_light,
    bt_yellow_green: theme?.palette?.labels?.bt_yellow_green,
    chip_grey: theme?.palette?.grey?.chip_grey,
    divider_grey_v1: theme?.palette?.grey?.divider_grey_v1,
    divider_grey_v2: theme?.palette?.grey?.divider_grey_v2,
    bg_hover_main: theme?.palette?.background?.bg_hover_main,
    bt_alt_grey_light: theme?.palette?.grey?.bt_alt_grey_light,
    bg_new_input_text: theme?.palette?.background?.bg_new_input_text,
    transparent: 'transparent',
    white: theme?.palette?.generic?.primary,
  }

  const defaultTextColor = hasTextStyles
    ? colors.txt_default 
    : colors.secondary_txt;

  const borderDefaultColor = isEmptyField 
    ? colors.bt_blue_field_empty_hover
    : colors.divider_grey_v1;

  let borderColor = isError 
    ? colors.bt_red_reg 
    : borderDefaultColor;

  let textColor = isEmptyField 
    ? colors.bt_blue_chip_reg
    : defaultTextColor;

  let backgroundColor = isEmptyField 
    ? colors.bt_blue_field_empty 
    : colors.transparent;

  let hoveredBackground = isEmptyField 
    ? colors.bt_blue_field_empty_hover
    : colors.bg_hover_main;

  if (disabled) {
    hoveredBackground = colors.bt_alt_grey_light;
    backgroundColor = colors.bt_alt_grey_light;
    textColor = colors.secondary_txt;
    borderColor = colors.divider_grey_v1;
  }

  if (isError) {
    textColor = colors.bt_red_reg;
    backgroundColor = colors.bt_red_light;
    hoveredBackground = colors.bt_red_mid;
  }

  if (!disabled && isEmptyField) {
    backgroundColor = colors.bg_new_input_text;
    hoveredBackground = colors.bg_hover_main;
  }

  if (!anchor && ((isError && !isNewField) || (isNewField && isEmptyField && isError))) {
    textColor = colors.bt_red_reg;
    backgroundColor = colors.bt_red_light;
    hoveredBackground = colors.bt_red_mid;
  }

  if (isReadOnly) {
    hoveredBackground = colors.white;
    backgroundColor = colors.white;
    textColor = colors.chip_grey;
    borderColor = colors.divider_grey_v2;
  }

  const hoveredStyles = {
    '&:hover': {
      cursor: !disabled ? 'pointer' : 'default',
      backgroundColor: `${hoveredBackground} !important`,
      ...(isEmptyField && { color: isError ? colors.bt_yellow_green : colors.txt_default }),
      borderRadius: theme?.shape?.border?.borderRadius,
    },
  };
  const expandedStyles = {
    backgroundColor: hoveredBackground,
    color: textColor,
  };

  return {
    fontSize: theme.fontSize.main,
    position: 'relative',
    backgroundColor: `${backgroundColor} !important`,
    height: theme.spacing(3.5),
    padding: theme.spacing(1),
    color: `${textColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    transform: `translateX(-${theme.spacing(1)})`,
    border: `1px solid ${borderColor}`,
    borderRadius: theme.shape.border.borderRadius,
    maxWidth: '224px',
    ...(isError && { 'svg path': {
      fill: colors.bt_red_reg,
    }}),
    ...(anchor ? expandedStyles : hoveredStyles),
    ...(!isTemplatePage && { justifyContent: 'space-between' })
  };
});
