import { styled } from '@mui/material/styles';

import { Grid, Box, MenuItem } from '@mui/material';
import arrowDownIcon from 'images/icons/arrowdown.svg';

export const StyledGridContainer = styled(Grid, {
  shouldForwardProp: (prop) =>
    prop !== 'isExpanded' &&
    prop !== 'isTemplatePage' &&
    prop !== 'isRichTextTruncated' &&
    prop !== 'disabled' &&
    prop !== 'height' &&
    prop !== 'hasAttachments' &&
    prop !== 'hasActiveUploads' &&
    prop !== 'isScrollable' &&
    prop !== 'isReadOnly' &&
    prop !== 'editorContent' &&
    prop !== 'hasHeader',
})(({ theme, isExpanded, isTemplatePage, isRichTextTruncated, height, hasAttachments, isReadOnly, isScrollable, hasHeader, editorContent }) => {
  const attachmentsMarginTop = () => {
    if ((!editorContent.replace(/<\/?p>/g, '') || !hasAttachments) && isReadOnly) {
      return '0px';
    }
    if (isExpanded) {
      return '12px';
    }
    return '6px';
  };

  return {
    width: '100%',

    '&&': {
      '@supports not selector(::-webkit-scrollbar)': {
        scrollbarColor: `${theme?.palette?.grey?.scroll} transparent`,
        scrollbarWidth: 'thin',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme?.palette?.grey?.scroll}`,
        borderRadius: '10px !important',
      },

      '.richtext-wrap': {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme?.palette?.grey?.scroll}`,
        borderRadius: theme?.shape?.border?.borderRadius,
        padding: '8px 4px 8px 8px',
        position: 'relative',

        '& .fr-wrapper': (isTemplatePage && !isRichTextTruncated) && {
          maxHeight: 'max-content !important',
        },
      },

      '.attachments-wrapper': {
        display: 'flex',
        marginTop: attachmentsMarginTop(),
        marginRight: isScrollable && '10px',
        maxHeight: isScrollable && isReadOnly ? '212px' : 'unset',
        width: isReadOnly && '100%',
      },

      '.uploader': {
        display: 'none',
      },
      '&& .fr-box': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: hasHeader && isExpanded ? '9px' : '0px',
        borderTop: hasHeader && isExpanded ? `1px solid ${theme?.palette?.grey?.divider_grey_v2}` : 'none',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        '& .fr-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0',
          height,
          maxHeight: height === 'auto' ? '102px' : height,
          overflow: 'hidden',
          zIndex: 0,
        },
      },
      '&& .fr-class-highlighted': {
        background: '#F3FF6F',
        borderRadius: '2px',
      },
      '& .fr-placeholder': {
        fontFamily: theme?.fonts?.robotoRegular,
        lineHeight: '24px !important',
      },
      '&& .fr-element': {
        fontFamily: theme?.fonts?.robotoRegular,
        color: theme?.palette?.text?.txt_default,
        height: '100%',
        wordBreak: 'break-all',
        padding: '0 30px 0 0',
      },
      '&& .fr-view': {
        color: theme?.palette?.text?.txt_default,
      },
      '&& p': {
        padding: 0,
        'img.fr-dii.fr-fil': {
          float: 'none',
          objectFit: 'contain',
          maxHeight: '336px',
          maxWidth: '250px',
          margin: '2px',
          backgroundColor: theme?.palette?.grey?.divider_grey_v2,
        },
      },
      '&& *': {
        fontFamily: '"Roboto-Regular", "Open Sans", Arial, sans-serif !important',
      },
      p: {
        margin: 0,
        wordBreak: 'break-word',
      },
      '&& .fr-counter': {
        display: 'none',
      },
      '&& .fr-counter-element': {
        display: isExpanded ? 'flex' : 'none',
        alignItems: 'center',
        margin: '0px !important',
        right: 0,
        padding: 0,
        color: theme?.palette?.grey?.bt_def_grey_reg,
        fontSize: theme?.fontSize?.main,
      },
      '&& .isQueuePage': {
        fontSize: theme?.fontSize?.small,
        color: theme.palette.text.disabled,
      },
      '.fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper': {
        'ul.fr-dropdown-list': {
          minWidth: '37px',
          'a.fr-active': {
            background: theme?.palette?.grey?.scroll,
          },
          'li a': {
            padding: '4px 16px',
            justifyContent: 'center',
            fontSize: theme?.fontSize?.main,
            color: theme.palette.text.title_txt,
            lineHeight: 1.4,
          },
        },
      },
      '&& .fr-popup': {
        boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
        input: {
          border: `1px solid ${theme.palette.grey.divider_grey_v1}!important`,
          borderRadius: '5px',
          padding: '2px 5px',
        },
        '.fr-input-line': {
          padding: '12px 0',
        },
        '.fr-buttons-column': {
          display: 'flex',
          flexDirection: 'column',
          padding: '12px 0',
          '.fr-btn': {
            height: 'auto',
            width: 'auto',
            padding: '4px 16px',
            margin: 0,
            borderRadius: 0,
          },
        },
        '.fr-color-hex-layer': {
          display: 'none',
        },
        '.fr-color-set': {
          padding: '5px',
          '& > span': {
            width: '20px',
            height: '20px',
            margin: '3px',
            '.fr-selected-color': {
              lineHeight: '20px',
            },
          },
          'span[data-param1="REMOVE"]': {
            display: 'none',
          },
        },
        '& .fr-link-insert-layer': {
          margin: '15px',
        },
        '& .fr-submit': {
          color: theme?.palette?.generic?.sysaid_default,
        },
        '.fr-btn': {
          width: '24px',
          height: '24px',
          textAlign: 'center',
          '&:hover > img': {
            filter: 'contrast(200%)',
          },
        },
      },
      '&& .fr-wrapper, .fr-toolbar': {
        border: 'none',
      },
      '&& .fr-toolbar': {
        display: isExpanded ? 'flex' : 'none',
        background: 'transparent',
        paddingRight: '11px',

        '.fr-newline': {
          display: 'none',
        },
        '.fr-btn-grp': {
          margin: 0,
          flex: 1,
        },
        '& .fr-command': {
          '& .fill-none': {
            fill: 'none',
          },
          '&:active': {
            background: `${theme.palette.background.bg_hover_alt}`,
          },
        },

        '& .fr-btn': {
          display: 'flex',
          alignItems: 'center',
          height: '24px',
          margin: '2px',
          padding: '0 4px',
          '&:hover:not(.fr-table-cell),.fr-selected:not(.fr-table-cell),.fr-command:active': {
            background: `${theme.palette.background.bg_hover_alt}`,
          },
          '&.fr-command.fr-dropdown:after': {
            position: 'static',
            content: `url(${arrowDownIcon})`,
            border: 'none',
            width: 'auto',
            height: 'auto',
            marginLeft: '2px',
            marginTop: '2px',
          },
        },
        '& .fr-active:not(.fr-dropdown)': {
          '&&& .fill-none': {
            fill: 'none',
          },
        },
      },
      '& .editor-header': {
        display: 'flex',
        rowGap: '10px',
        justifyContent: 'space-between',
        '.action-icon-btn': {
          zIndex: 2,
          position: 'absolute',
          right: '11px',
          top: 0,
          border: 'none',
          backgroundColor: theme?.palette?.view?.main,
          cursor: 'pointer',
          padding: '6px',
          lineHeight: 0,
          borderRadius: theme?.shape?.border?.borderRadius,
          svg: {
            width: '10px',
            height: '10px',
          },
          '& .fr-toolbar-icon': {
            paddingTop: '3px',
          },
          '&:hover': {
            backgroundColor: theme?.palette?.background?.blue_hover,
            'svg *': {
              stroke: theme?.palette?.icons?.icons_grey_v1,
            },
          },
        },
      },
      '&& .fr-toolbar-icon': {
        width: '16px',
        height: '16px',
        margin: 0,
      },
      '*::-webkit-scrollbar': {
        width: '7px',
      },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: theme?.palette?.grey?.scroll,
      },
      '*::-webkit-scrollbar-track': {
        marginBottom: '2px',
      },
      '.mention-text': {
        textDecoration: 'none',
        color: '#3578DB',
      },
      '.email-signature': {
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    },
  };
});

export const StyledUploadProgressContainer = styled('div')({
  display: 'flex',
  paddingBottom: '10px',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '10px',

  '.upload-progress-wrapper': {
    width: '100%',
    display: 'flex',
    columnGap: '12px',
  },
  '.progress-bar-wrapper': {
    width: 'auto',
  },
  '.progress-bar-error-wrapper': {
    columnGap: '8px',
    alignItems: 'center',
  },
});

export const StyledUploadFromSelectorWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUploadFromMenuOpened',
})(({ isUploadFromMenuOpened }) => ({
  position: 'absolute',
  left: 0,
  bottom: 'calc(100% - 1px)',
  display: isUploadFromMenuOpened ? 'block' : 'none!important',
  zIndex: 20,
  width: '238px',
  minWidth: '238px',
  height: 'fit-content',
  background: 'white',
  borderRadius: '6px',
  boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
}));

export const StyledUploadFromSelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  rowGap: '10px',
  padding: '10px',

  '& li': {
    justifyContent: 'center',
    alignItems: 'start',
    fontSize: '14px',
    minHeight: '42px',
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
  },
}));

export const StyledUploadFromSelectorItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  paddingBottom: '10px',

  '&:hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
  },
}));

export const StyledRichTextEditorWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldRenderDefaultUI',
})(({ shouldRenderDefaultUI }) => ({
  '.default-solution': {
    display: shouldRenderDefaultUI ? 'block' : 'none',
  },
  '.richtext-wrap': {
    display: !shouldRenderDefaultUI ? 'flex' : 'none!important',
  },
  '.header': {
    display: !shouldRenderDefaultUI ? 'flex' : 'none!important',
  },
}));
