import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useTexts from 'features/templateBuilder/useTexts';
import { activeComponentTypeEnum, setActiveComponent } from '../activeComponentSlice';
import { StyledTemplateSectionInstructions } from './StyledTemplateSectionInstructions';

const INSTRUCTIONS_MAX_LENGTH = 500;
export default function TemplateSectionInstructions(props) {
  const { value, updateSectionInstructions } = props;
  const dispatch = useDispatch();
  const { placeholderFieldTextInstruction } = useTexts();
  const [instructionsValue, setInstructionsValue] = useState(value);
  const handleInstructionsChange = (event) => {
    setInstructionsValue(event.target.value);
  };
  const handleInstructionsSave = (event) => {
    updateSectionInstructions(event.target.value);
  };
  return (
    <StyledTemplateSectionInstructions
      className="section-instructions"
      placeholder={placeholderFieldTextInstruction}
      multiline
      inputProps={{ maxLength: INSTRUCTIONS_MAX_LENGTH }}
      fullWidth
      helperText={`${instructionsValue.length}/${INSTRUCTIONS_MAX_LENGTH}`}
      onChange={handleInstructionsChange}
      onBlur={handleInstructionsSave}
      onFocus={() => {
        dispatch(
          setActiveComponent({ componentType: activeComponentTypeEnum.SECTION, componentId: 'sectionInstructions' }),
        );
      }}
      value={instructionsValue}
      data-ispropertiesfield="true"
      data-testid="section-instructions"
    />
  );
}
