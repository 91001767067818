import { useCallback, useEffect, useState } from 'react';
import MultiSelectField from 'common/components/controls/MultiSelectField';
import { useApplicationData } from 'remote-state/applicationHooks';
import { QUERIES_KEYS } from 'constant';
import { StyledWrapper, StyledLabel, StyledRadio, StyledRadioGroup, StyledFormControlLabel } from './style';
import useTexts from './useTexts';
import {
  PRIVATE_VISIBILITY_VALUE,
  PUBLIC_VISIBILITY_VALUE,
  VISIBILITY_FOR_GROUPS_KEY,
  VISIBILITY_KEY,
} from '../../saveViewAsModal/constants';

export default function PrivacySettings(props) {
  const { controller, handleChangeValue, isArchiveView } = props;
  const visibilityValue = controller.watch(VISIBILITY_KEY);
  const visibleForGroupIds = controller.watch(VISIBILITY_FOR_GROUPS_KEY);

  const [allGroups, setAllGroups] = useState([]);
  const { data: groups, isFetched, isSuccess } = useApplicationData(QUERIES_KEYS.GROUPS);

  const { privateLabel, publicLabel, privacySettingLabel, privacySettingGroupsLabel, privacySettingGroupsPlaceholder } =
    useTexts();
  const OPTIONS = [
    { label: publicLabel, value: PUBLIC_VISIBILITY_VALUE },
    ...(!isArchiveView ? [{ label: privateLabel, value: PRIVATE_VISIBILITY_VALUE }] : []),
  ];

  const onChange = ({ target: { value } }) => {
    handleChangeValue(VISIBILITY_KEY, value);
  };

  const handleSelectGroups = useCallback(
    (selectedGroups = []) => {
      handleChangeValue(VISIBILITY_FOR_GROUPS_KEY, selectedGroups.map(Number));
    },
    [handleChangeValue],
  );

  useEffect(() => {
    if (isFetched && isSuccess) {
      setAllGroups(groups);
    }
  }, [isFetched, isSuccess, groups]);

  return (
    <StyledWrapper>
      <StyledLabel className="privacy">{privacySettingLabel}</StyledLabel>
      <StyledRadioGroup row value={visibilityValue} onChange={onChange}>
        {OPTIONS.map(({ label, value }) => (
          <StyledFormControlLabel
            key={value}
            value={value}
            data-testId={value}
            control={<StyledRadio size="small" />}
            label={label}
          />
        ))}
      </StyledRadioGroup>
      {visibilityValue === PUBLIC_VISIBILITY_VALUE && (
        <>
          <StyledLabel className="groups">{privacySettingGroupsLabel}</StyledLabel>
          <MultiSelectField
            itemList={allGroups}
            selectedItemList={visibleForGroupIds}
            handleSelectItems={handleSelectGroups}
            captionString="groupName"
            keyString="id"
            fieldPlaceholder={privacySettingGroupsPlaceholder}
          />
        </>
      )}
    </StyledWrapper>
  );
}
