import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogTitle, ThemeProvider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useToasterMessage } from 'common/utils/hooks/useToasterMessage';
import { copyToClipboard } from 'features/floatingTicketPanel/utils/utils';
import useTexts from 'features/floatingTicketPanel/useTexts';
import { ReactComponent as SuccessIcon } from 'images/icons/SuccessIcon.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSignNew.svg';
import { ReactComponent as CloseIcon } from 'images/icons/icon_close_big.svg';
import { ReactComponent as ConfettiIcon } from 'images/icons/confetti.svg';

import { setNavigationObject } from 'store/globalSlice';
import { theme } from './theme';
import { classesStyles } from './style';
import { TICKET_EVENTS, TICKETS_OPEN_URL } from '../../../constants';

const ToasterMessage = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { toasterMessage, handleClose } = useToasterMessage();
  const { copiedURL, copyURL } = useTexts();
  const message = toasterMessage.message;
  const styleOverrides = toasterMessage.styleOverrides;
  const styleDialogOverrides = toasterMessage.styleDialogOverrides || [];
  const isTicketCreated = toasterMessage.onClickFunctionName === TICKET_EVENTS.TICKET_CREATED;
  const isTicketArchived = toasterMessage.onClickFunctionName === TICKET_EVENTS.TICKET_ARCHIVED;
  const linkToTicket = TICKETS_OPEN_URL.SPACES(toasterMessage?.id);

  const onClick = (e) => {
    if (toasterMessage.onClickFunctionName) {
      switch (toasterMessage.onClickFunctionName) {
        case TICKET_EVENTS.TICKET_CREATED_TOAST_CLICKED:
          dispatch(setNavigationObject({ to: linkToTicket }));
          break;
        case TICKET_EVENTS.TICKET_CREATED:
        case TICKET_EVENTS.TICKET_ARCHIVED:
          // eslint-disable-next-line no-case-declarations
          const isCopyLinkPressed = e?.target?.dataset?.testid === 'copyLink';

          if (isCopyLinkPressed) {
            e.target.innerText = copiedURL;
            setTimeout(() => {
              e.target.innerText = copyURL;
            }, 3000);

            copyToClipboard(window.location.origin + linkToTicket);
          }
          break;
        default:
          break;
      }
    }
  };

  const clicked = (e) => {
    if (!(isTicketCreated || isTicketArchived)) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (onClick) onClick(e);
  };

  const promptDisplay = useMemo(() => {
    if (isTicketCreated) {
      return classes.promptDisplayCreatedTicket;
    }

    if (isTicketArchived) {
      return classes.promptDisplayArchivedTicket;
    }

    return classes.promptDisplay;
  }, [classes, isTicketCreated, isTicketArchived]);

  const MessageIcon = useMemo(() => {
    switch (toasterMessage.type) {
      case 'error':
        return ErrorIcon;
      case 'warning':
        return WarningIcon;
      case 'confetti':
        return ConfettiIcon;
      default:
        return SuccessIcon;
    }
  }, [toasterMessage.type]);

  const svgIconStyle = useMemo(() => {
    switch (toasterMessage.onClickFunctionName) {
      case TICKET_EVENTS.TICKET_CREATED:
      case TICKET_EVENTS.TICKET_ARCHIVED:
        return classes.svgIconSmall;
      default:
        return classes.svgIcon;
    }
  }, [classes, toasterMessage.onClickFunctionName]);

  if (!toasterMessage?.message) return null;

  return (
    <ThemeProvider theme={theme(styleOverrides)}>
      <Dialog open onClose={handleClose} sx={styleDialogOverrides}>
        <div
          role="button"
          tabIndex={0}
          className={promptDisplay}
          onKeyDown={(e) => clicked(e)}
          onClick={(e) => clicked(e)}
        >
          <div className={classes.icon}>
            <MessageIcon className={svgIconStyle} />
          </div>
          <div className={classes.title}>
            <DialogTitle data-cy="toaster-message-title">
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </DialogTitle>
          </div>
          <DialogActions>
            <button onClick={handleClose} className={classes.okBtn}>
              <div>
                <CloseIcon />
              </div>
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default withStyles(classesStyles)(ToasterMessage);
