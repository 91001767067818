import { useCallback } from 'react';
import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import {
  createTemplate as createTemplateService,
  getAvailableFields,
  getTemplateDataByTemplateId,
  updateTemplate as updateTemplateService,
  duplicateTemplate as duplicateTemplateService,
  deleteTemplate as deleteTemplateService,
  setTemplateAsDefault,
  getAvailableColumns,
  getOOTBTemplateBySrType,
} from 'services/templateService';
import { convertClientColumnConfig } from 'features/queue/utils';
import { convertToClientField } from 'common/utils/fieldUtils';
import queryClient from './queryClient';
import { QUERIES_KEYS } from '../constant';

export function useTemplateData(srTypeOrTemplateId, source) {
  const options = {
    staleTime: 1000 * 60 * 15,
  };
  const isExisting = !!srTypeOrTemplateId && !Number.isNaN(Number(srTypeOrTemplateId));
  const queryByTemplateId = useQuery({
    queryKey: [QUERIES_KEYS.TEMPLATE_DATA, srTypeOrTemplateId],
    queryFn: () => getTemplateDataByTemplateId(srTypeOrTemplateId, source),
    enabled: Boolean(!!srTypeOrTemplateId && isExisting),
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  const queryBySrType = useQuery({
    queryKey: [QUERIES_KEYS.TEMPLATE_BY_SR_TYPE, srTypeOrTemplateId],
    queryFn: () => getOOTBTemplateBySrType(srTypeOrTemplateId),
    enabled: Boolean(!!srTypeOrTemplateId && !isExisting),
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  return isExisting ? queryByTemplateId : queryBySrType;
}

export const useGetTemplateById = (templateId, source) => {
  const options = {
    staleTime: 1000 * 60 * 15,
  };

  const select = useCallback(
    (template) => ({
      ...template,
      sections: template.sections.map((section) => ({
        ...section,
        sectionRows: section.sectionRows.map((sectionRow) => ({
          ...sectionRow,
          fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
        })),
      })),
    }),
    [],
  );

  const queryByTemplateId = useQuery({
    queryKey: [QUERIES_KEYS.TEMPLATE_DATA, templateId],
    queryFn: () => getTemplateDataByTemplateId(templateId, source),
    enabled: Boolean(templateId && templateId !== 'new'),
    select,
    ...options,
  });

  return queryByTemplateId;
};

export const useSaveTemplate = (templateId) => {
  const isNewTemplate = !templateId || templateId === 'new';
  const create = useMutation(createTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData([QUERIES_KEYS.TEMPLATE_DATA, template.id], (oldTemplate) => {
          const updatedTemplate = {
            ...oldTemplate,
            ...template,
          };
          return updatedTemplate;
        });
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST] });
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST_TOTAL_SIZE] });
      }
    },
  });
  const update = useMutation(updateTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData([QUERIES_KEYS.TEMPLATE_DATA, template.id], (oldTemplate) => {
          const updatedTemplate = {
            ...oldTemplate,
            ...template,
          };
          return updatedTemplate;
        });
        if (template.default) {
          queryClient.setQueryData([QUERIES_KEYS.DEFAULT_TEMPLATE, template.srType], (oldData) => {
            const updatedTemplate = {
              ...oldData,
              ...template,
            };
            return updatedTemplate;
          });
        }
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST] });
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST_TOTAL_SIZE] });
      }
    },
  });
  return isNewTemplate ? create : update;
};

export const useDuplicateTemplate = () => {
  const duplicateTemplate = useMutation(duplicateTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData([QUERIES_KEYS.TEMPLATE_DATA, template.id], () => template);
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST] });
        queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST_TOTAL_SIZE] });
      }
    },
  });
  return duplicateTemplate;
};

export const useDeleteTemplate = (templateId) => {
  const deleteTemplate = useMutation(deleteTemplateService, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATE_DATA, templateId] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST_TOTAL_SIZE] });
    },
  });
  return deleteTemplate;
};

export const useSetTemplateAsDefault = () =>
  useMutation(setTemplateAsDefault, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.TEMPLATES_LIST] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.DEFAULT_TEMPLATE] });
    },
  });

export const useLazyLoadFieldsList = (search) => {
  const { data, hasNextPage, fetchNextPage, isSuccess, isFetching } = useInfiniteQuery({
    queryKey: [QUERIES_KEYS.TEMPLATE_DESIGNER_AVAILABLE_FIELDS, 'infinite', search],
    queryFn: ({ pageParam = 0 }) => getAvailableFields({ search, page: pageParam }),
    getNextPageParam: (lastPage) => {
      const { last, pageNumber } = lastPage;
      const nextPage = pageNumber + 1;
      return !last ? nextPage : undefined;
    },
    select: (availableFields) => ({
      ...availableFields,
      pages: availableFields.pages.map((page) => ({
        ...page,
        currentList: page.currentList.map((field) => convertClientColumnConfig(field)),
      })),
    }),
  });

  return { data, hasNextPage, fetchNextPage, isSuccess, isFetching };
};

export const useLoadIntegrationFields = (integrationType) => {
  const { data, isSuccess, isFetching } = useQuery({
    // TODO ask Jo about this! Had to add the _integrationType for it to work
    queryKey: [`${QUERIES_KEYS.TEMPLATE_DESIGNER_AVAILABLE_FIELDS}_${integrationType}`, 'infinite', integrationType],
    queryFn: () => getAvailableFields({ search: integrationType }),
  });
  return { data, isSuccess, isFetching };
};

export const useLazyLoadAvialableColumsList = (search) => {
  const { data, hasNextPage, fetchNextPage, isSuccess, isFetching } = useInfiniteQuery({
    queryKey: [QUERIES_KEYS.COLUMN_ORDER_AVAILABLE_FIELDS, 'infinite', search],
    queryFn: ({ pageParam = 0 }) => getAvailableColumns({ search, limit: 50, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        const { last, pageNumber } = lastPage;
        const nextPage = pageNumber + 1;
        return !last ? nextPage : undefined;
      }
      return 0;
    },
    select: (availableFields) => ({
      ...availableFields,
      pages: availableFields.pages.map((page) => ({
        ...page,
        currentList: page.currentList.map((field) => convertClientColumnConfig(field)),
      })),
    }),
  });

  return { data, hasNextPage, fetchNextPage, isSuccess, isFetching };
};
