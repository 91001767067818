import { createBaseInstance, applyInterceptors } from './axiosBaseInstance';
import { applyConfigInterceptors } from './axiosConfig';

const coreApiPath = (process.env.NODE_ENV === 'production') ? '' : '/spaces/api/v1/';

const axiosInstance = createBaseInstance(coreApiPath);
applyInterceptors(axiosInstance);
applyConfigInterceptors(axiosInstance);

const JIRA_COMMAND_URL = 'addonsConsume/jiraEmbedded?jiraCommand=';

export async function getStatus() {
  const response = await axiosInstance.get(`${JIRA_COMMAND_URL}status&ver=spaces`);
  return response.data;
}

export async function getProjects() {
  const response = await axiosInstance.get(`${JIRA_COMMAND_URL}getProjects&ver=spaces`);
  return response.data;
}

export async function getIssuesByProject(projectKey) {
  const response = await axiosInstance.get(`${JIRA_COMMAND_URL}getIssues&ver=spaces&projectKey=${projectKey}`);
  return response.data;
}

export async function getUsers(projectKey) {
  const response = await axiosInstance.get(`${JIRA_COMMAND_URL}getUsers&ver=spaces&projectKey=${projectKey}`);
  return response.data;
}

export async function getIssueDetails(issueKey) {
  const response = await axiosInstance.get(`${JIRA_COMMAND_URL}getIssueDetails&ver=spaces&key=${issueKey}`);
  return response.data;
}

export async function createIssue(details) {
  const response = await axiosInstance.post(`${JIRA_COMMAND_URL}createIssue&ver=spaces`, details);
  return response.data.key;
}
