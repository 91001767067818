import { memo, useCallback, useMemo } from 'react';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import List from '@mui/material/List';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import { SectionFieldsAreaWrapper } from './StyledTemplateSection';
import { JIRA_ISSUEKEY_FIELD_NAME } from '../../IntegrationSection/components/jira/jira.consts';
import { isIntegrationSection } from '../../IntegrationSection/integrations.utils';
import { IntegrationTypes } from '../../IntegrationSection/IntegrationSection.consts';

function SectionFieldsArea(props) {
  const { id, sectionRows, handleUpdateSectionByValues, handleChangeFieldValue } = props;

  const isJiraIntegrationSection = isIntegrationSection({ sectionRows, fields: props?.fields }) === IntegrationTypes.JIRA;
  const fields = useMemo(() =>
    (props.fields || []).filter((field) =>
        typeof field.fieldName === 'string' &&
        !(isJiraIntegrationSection && field.fieldName === JIRA_ISSUEKEY_FIELD_NAME)
    ), [props.fields, isJiraIntegrationSection],
  );

  const { setNodeRef } = useDroppable({ id: `section-${id}` });
  const updateField = useCallback(
    (val) => {
      handleChangeFieldValue(val, id);
    },
    [id, handleChangeFieldValue],
  );
  return (
    <SortableContext
      id={`section-${id}`}
      items={fields?.map((field) => field.fieldName)}
      strategy={rectSortingStrategy}
    >
      <SectionFieldsAreaWrapper>
        <List className="section-fields-wrapper" ref={setNodeRef}>
          <TemplateFieldsGrid
            isTemplatePage
            fields={fields}
            sectionRows={sectionRows}
            onFieldChange={updateField}
            handleUpdateSectionByValues={handleUpdateSectionByValues}
            isJiraIntegrationSection={isJiraIntegrationSection}
          />
        </List>
      </SectionFieldsAreaWrapper>
    </SortableContext>
  );
}

export default memo(SectionFieldsArea);
