import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';

import { ReactComponent as WhiteChevron } from 'images/icons/white_chevron.svg';

import { StyledMoreNewServiceRequests } from './style';
import useTexts from '../useTexts';

export default function MoreFloatingTickets(props) {
  const { count, onClick, direction } = props;
  const { moreNewServiceRequests } = useTexts();
  const theme = useTheme();
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        right: direction === 'right' ? 8 : undefined,
        left: direction === 'right' ? undefined : 8,
        zIndex: 100,
      }}
      data-testid={direction === 'right' ? 'next-ticket' : 'prev-ticket'}
    >
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              padding: '12px',
              backgroundColor: theme.palette.text.title_txt,
            },
          },
        }}
        title={`${moreNewServiceRequests || 'Show more'}(${count})`}
        placement="top"
        isTruncatedText
        arrow
        enterDelay={500} 
        enterNextDelay={500}
      >
        <StyledMoreNewServiceRequests direction={direction} onClick={onClick}>
          <WhiteChevron />
        </StyledMoreNewServiceRequests>
      </Tooltip>
    </div>
  );
}
