import axios from 'axios';

export function getKeepALive(timeStamp, lastClick) {
  return axios // TODO: replace with axiosInstance
    .get(`/KeepAlive.jsp?stamp=${timeStamp}&lastClick=${lastClick}&notAddingIndexJSP=true&ssp=true`)
    .then((response) => ({
      status: response.status,
      data: response.data,
    }));
}
