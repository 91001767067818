import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSwitchLangauge, selectRB, selectSettingLanguage, toggleSwitchHeadersLanguage } from 'store/userSlice';
import { useRouter } from '@tanstack/react-router';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/app';
import { getCookie } from 'common/utils/utils';
import { getApplicationData } from 'services/applicationService';
import { isLocalHost } from 'services/localhost';
import { useResourceBundle } from './userServiceHooks';

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export const useApplicationData = (selectKey, options = {}) => {
  const sessionId = getCookie(APP_CONSTANTS.SESSION_ID);
  const getDataByKey = useCallback(
    (applicationData) => {
      switch (selectKey) {
        case QUERIES_KEYS.GENERAL_SETTINGS:
          return applicationData.generalSettings || {};
        case QUERIES_KEYS.RESOURCE_BUNDLE:
          return applicationData.resourceBundle?.resourceBundleMap || {};
        case QUERIES_KEYS.IS_EXTERNAL_MODE:
          return applicationData.isExternalMode ?? true;
        case QUERIES_KEYS.ADMINISTRATORS:
          return applicationData.administrators
            ? [...applicationData.administrators].sort((a, b) => collator.compare(a.userName, b.userName))
            : [];
        case QUERIES_KEYS.BULK_ACTIONS_LIMIT:
          return applicationData.bulkActionsLimit || 0;
        case QUERIES_KEYS.EDITION:
          return applicationData.edition;
        case QUERIES_KEYS.GROUPS:
          return applicationData.groups
            ? [...applicationData.groups].sort((a, b) => collator.compare(a.groupName, b.groupName))
            : [];
        case QUERIES_KEYS.MENU_ITEMS:
          if (!applicationData.menuItems) return {};
          return { menu: applicationData.menuItems.menu, userOptions: applicationData.menuItems.userOptions };
        case QUERIES_KEYS.IS_AI_ENABLED:
          return applicationData.isAiEnabled;
        case QUERIES_KEYS.ENABLED_SWITCH_UI:
          return applicationData.enabledSwitchUi;
        case QUERIES_KEYS.DEFAULT_UI:
          return applicationData.defaultUi;
        default:
          return applicationData;
      }
    },
    [selectKey],
  );

  const { enabled, forceCall, ...rest } = options;

  return useQuery({
    queryKey: [QUERIES_KEYS.APPLICATION_DATA],
    queryFn: getApplicationData,
    placeholderData: {},
    enabled: (isLocalHost() || !!sessionId || forceCall) && options.enabled !== false,
    select: (applicationData) => getDataByKey(applicationData),
    ...rest,
  });
};

// The function changes the language at two different time stamps: 1. when the user is signing in and then the value is determined by either direct api call or through user data
//The second Option applies when the user changes the language through the settings.
export function useRbTexts() {
  const LOGIN_PATH = '/spaces/login';
  const FORGOT_PASSWORD_PATH = '/spaces/forgot-password';
  const router = useRouter();
  const queryClient = useQueryClient();
  const isLoginPage = router.latestLocation.pathname.includes(LOGIN_PATH);
  const isForgotPasswordPage = router.latestLocation.pathname.includes(FORGOT_PASSWORD_PATH);
  const isLanguageChanged = useSelector((state) => state.user.changeLanguage);

  const settingsLoadedLanguage = useSelector(selectSettingLanguage);
  const loadedResourceBundleFromSettingsOrTranslations  = useSelector(selectRB);
  const { data } = useApplicationData(undefined, { enabled: !isLoginPage });
  const { data: rbForLoginPage } = useResourceBundle({ enabled: isLoginPage });
  const dispatch = useDispatch();

  const dbLoadedLanguageResourcesFrom =  data?.resourceBundle?.resourceBundleMap;
  const languageResourcesToLoad = isLoginPage || isForgotPasswordPage ? rbForLoginPage : dbLoadedLanguageResourcesFrom;

  const isLoadedResourceBundleFromSettingsOrTranslations = loadedResourceBundleFromSettingsOrTranslations && !(isLoginPage || isForgotPasswordPage);
  const resourceBundleMap = isLoadedResourceBundleFromSettingsOrTranslations ? loadedResourceBundleFromSettingsOrTranslations.resourceBundleMap : dbLoadedLanguageResourcesFrom;

  const [texts, setTexts] = useState(resourceBundleMap);

  useEffect(() => {
    if (!loadedResourceBundleFromSettingsOrTranslations || !isLanguageChanged) {
      setTexts(languageResourcesToLoad);
    }

    if (loadedResourceBundleFromSettingsOrTranslations) {
      setTexts(loadedResourceBundleFromSettingsOrTranslations.resourceBundleMap);
    }
  }, [isLanguageChanged, settingsLoadedLanguage, loadedResourceBundleFromSettingsOrTranslations, languageResourcesToLoad, dispatch, texts]);

  if (!loadedResourceBundleFromSettingsOrTranslations) {
    if (languageResourcesToLoad) {
      return languageResourcesToLoad;
    }
    return {};
  }
  if (isLanguageChanged) dispatch(toggleSwitchHeadersLanguage(true));

  if (!texts || Object.keys(texts).length === 0) {
    return {};
  }
  if (!isLanguageChanged || isLoginPage || isForgotPasswordPage) {
    return texts;
  }

  queryClient.setQueryData([QUERIES_KEYS.RESOURCE_BUNDLE], (prev) => {
    if (prev) {
      prev.resourceBundleMap = texts;
    }
    return prev;
  });

  queryClient.setQueryData([QUERIES_KEYS.APPLICATION_DATA], (prev) => {
    if (prev) {
      prev.resourceBundle.resourceBundleMap = texts;
    }
    return prev;
  });

 

  if (isLanguageChanged) dispatch(toggleSwitchLangauge(false));

  return texts;
}
