export const QUERIES_KEYS = {
  CURRENT_USER: 'currentUser',
  CURRENT_USER_FROM_CORE: 'currentUserFromCore',
  USER_DATA: 'userData',
  CURRENT_USER_PERMISSIONS: 'currentUserPermissions',
  USER_CONF: 'userConf',
  PHOTO: 'photo',
  LOGIN_OBJECT: 'loginObject',
  RESOURCE_BUNDLE: 'resourceBundle',
  APPLICATION_DATA: 'applicationData',
  GENERAL_SETTINGS: 'generalSettings',
  IS_EXTERNAL_MODE: 'isExternalMode',
  ADMINISTRATORS: 'administrators',
  BULK_ACTIONS_LIMIT: 'bulkActionsLimit',
  EDITION: 'edition',
  GROUPS: 'groups',
  MENU_ITEMS: 'menuItems',
  USER_DETAILS_BY_NAME: 'userDetailsByName',
  USER_DETAILS_BY_ID: 'userDetailsById',
  IS_AI_ENABLED: 'isAiEnabled',
  ASSISTIVE_AI: 'assistiveAi',
  IS_AI_AUTHOR: 'AIAuthor',
  IS_AI_EMOTIONS: 'AIEmotions',
  IS_AI_AUTO_POPULATE: 'AIAutoPopulate',
  IS_AI_SUGGESTED_CATEGORY: 'AISuggestedCategory',
  IS_AI_SUMMARIZATIONS: 'AICaseSummarizations',
  IS_AI_ADMINISTRATOR: 'isAiAdministrator',
  ENABLED_SWITCH_UI: 'enabledSwitchUi',
  DEFAULT_UI: 'defaultUi',
  FIELD_ATTRIBUTE: 'fieldAttribute',
  ATTRIBUTES: 'attributes',
  USER_ATTRIBUTES: 'userAttributes',
  KEEP_ALIVE: 'keepAlive',
  STATUS_SETTINGS: 'statusSettings',
  TEMPLATE_DESIGNER_AVAILABLE_FIELDS: 'templateDesignerAvailableFields',
  COLUMN_ORDER_AVAILABLE_FIELDS: 'columnOrderAvailableFields',
  TEMPLATES_LIST: 'templatesList',
  TEMPLATE_DATA: 'templateData',
  DEFAULT_TEMPLATE: 'defaultTemplate',
  TEMPLATES_LIST_TOTAL_SIZE: 'templatesListTotalSize',
  TEMPLATE_BY_SR_TYPE: 'templateBySrType',
  TICKET_PERMISSIONS: 'ticketPermissions',
  LICENS_ECHECK: 'licenseCheck',
};
