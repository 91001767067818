import axios, { AxiosResponse } from 'axios';

interface LicenseCheckResponse {
  instance: boolean;
  validAccount?: boolean;
  validLicense?: boolean;
}

export function getLicenseCheck(): Promise<LicenseCheckResponse> {
  return axios.get('/api/v1/licenseCheck').then((response: AxiosResponse<LicenseCheckResponse>) => response.data);
}
