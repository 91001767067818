import { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTicketSummaryTooltip } from 'common/components/controls/Tooltip/Tooltip';
import { setIsSummaryTooltipOpen } from 'store/ticketSummarySlice';
import { useTicketSummary } from 'common/utils/hooks/useTicketSummary';
import { useApplicationData } from 'remote-state/applicationHooks';
import { QUERIES_KEYS } from 'constant';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';

export const TicketSummaryTooltip = ({ children, isWrappedInputFocused }) => {
  const dispatch = useDispatch();
  const popperRef = useRef(null);
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI );

  // Must have separate open state for each tooltip, if all tooltips use the store state all will open/close at the same time
  const [isOpen, setIsOpen] = useState(false);
  const ticketSummaryPopup = useTicketSummary(popperRef);
  const setIsTooltipOpen = useCallback(
    (isOpen) => {
      setIsOpen(isOpen);
      dispatch(setIsSummaryTooltipOpen(isOpen));
    },
    [dispatch, setIsOpen],
  );

  useEffect(() => {
    // Force closing the tooltip on focus, since disabling hoverListener
    // and FocusListener doesn't close the popup while hovering the input

    if (isWrappedInputFocused && isOpen) {
      setIsTooltipOpen(false);
    }
  }, [isWrappedInputFocused, setIsTooltipOpen, isOpen]);

  const enableAiSummary = assistiveAI?.AICaseSummarizations || assistiveAI?.AIEmotions;
  const disableHoverListener =  isWrappedInputFocused || !isAiEnabled || !enableAiSummary;
  return (
    <StyledTicketSummaryTooltip
      title={ticketSummaryPopup}
      PopperProps={{
        popperRef,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
            },
          ],
        },
      }}
      placement="bottom"
      arrow
      enterDelay={500}
      enterNextDelay={500}
      open={isOpen}
      onOpen={() => setIsTooltipOpen(true)}
      onClose={() => setIsTooltipOpen(false)}
      disableFocusListener
      disableHoverListener={disableHoverListener}
    >
      <div data-testid="summary-tooltip" className="summary-tooltip">
        {children}
      </div>
    </StyledTicketSummaryTooltip>
  );
};
