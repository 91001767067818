import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    getColumnText: (displayKey) => texts?.[displayKey],
    filterByText: texts?.['spaces.filters.filterBy'],
    searchPlaceholderText: texts?.['spaces.filters.search.title'],
    categoryNoValue: texts?.['spaces.categories.noValue'],
    today: texts?.['spaces.header.quickFilter.displayKey.today'],
    yesterday: texts?.['spaces.header.quickFilter.displayKey.yesterday'],
    thisWeek: texts?.['spaces.header.quickFilter.displayKey.thisWeek'],
    thisMonth: texts?.['spaces.select.range.thisMonth'],
    last30Days: texts?.['spaces.select.range.last30Days'],
  };

  return componentTexts;
}
