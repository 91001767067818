import Tooltip from 'common/components/tooltip';
import useTexts from '../hooks/useTexts';
import { StyledLinkItemBtn } from './style';

const LinkItemBtn = ({ onClick, disabled, isArchived }) => {
  const {
    componentTexts: { linkText, limitReachedTooltip },
  } = useTexts();

  const LinkContent = <span>{linkText}</span>;

  return (
    <StyledLinkItemBtn onClick={onClick} disabled={disabled} data-testid="link-items-btn" isArchived={isArchived}>
      {disabled ? (
        <Tooltip
          title={
            <div
              style={{ textAlign: 'left' }}
              dangerouslySetInnerHTML={{ __html: limitReachedTooltip?.replace('__br__', '<br />') }}
            />
          }
          text={LinkContent}
          placement="top"
        />
      ) : (
        LinkContent
      )}
    </StyledLinkItemBtn>
  );
};

export default LinkItemBtn;
