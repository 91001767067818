import { createSlice } from '@reduxjs/toolkit';
import { FloatingTicketInstanceModes } from 'features/floatingTicketPanel/floatingTicketPanelManager/modes';
import { getVisiblePanelsOnScreenCount } from 'features/floatingTicketPanel/utils/utils';
import { sliceNames } from './constants';

const initialState = {
  panels: {},
  startIndexToShow: 0,
  showAutoPopulateSection: false,
};

const collapseAll = (state) => {
  Object.keys(state.panels).forEach((url) => {
    state.panels[url].mode = FloatingTicketInstanceModes.COLLAPSED;
  });
};

export const floatingTicketPanelSlice = createSlice({
  name: sliceNames.floatingTicketPanelSliceName,
  initialState,

  reducers: {
    addFloatingTicketPanel: (state, action) => {
      collapseAll(state);
      const { srType, createTime, mode } = action.payload;
      const id = `${srType}-${createTime}`;
      state.panels[id] = {
        mode: mode || FloatingTicketInstanceModes.REGULAR_MODE_OPENED,
        srType,
        createTime,
        id,
      };
      state.startIndexToShow = Math.max(0, Object.keys(state.panels).length - getVisiblePanelsOnScreenCount());
    },
    editFloatingTicketPanel: (state, action) => {
      const { mode, id } = action.payload;
      switch (mode) {
        case FloatingTicketInstanceModes.CLOSED:
          delete state.panels[id];
          break;

        case FloatingTicketInstanceModes.COLLAPSED:
          state.panels[id] = { ...state.panels[id], mode: FloatingTicketInstanceModes.COLLAPSED };
          break;

        case FloatingTicketInstanceModes.AUTO_POPULATE:
          state.panels[id] = { ...state.panels[id], mode: FloatingTicketInstanceModes.AUTO_POPULATE };
          break;

        default:
          collapseAll(state);
          state.panels[id] = { ...state.panels[id], ...action.payload };
          break;
      }
    },
    collapseAllFloatingPanels: (state) => {
      collapseAll(state);
    },
    closeFloatingTicketPanel: (state, action) => {
      delete state.panels[action.payload];
      state.startIndexToShow = state.startIndexToShow - 1;
    },
    setStartIndexToShow: (state, action) => {
      collapseAll(state);
      const move = action.payload;
      state.startIndexToShow = state.startIndexToShow + move;
    },
    toggleAutoPopulateSection: (state, action) => {
      state.showAutoPopulateSection = action.payload;
    },
  },
});

export const {
  addFloatingTicketPanel,
  editFloatingTicketPanel,
  collapseAllFloatingPanels,
  closeFloatingTicketPanel,
  setStartIndexToShow,
  toggleFloatingTicketPanelLoading,
  toggleAutoPopulateSection,
} = floatingTicketPanelSlice.actions;

export const selectPanels = (state) => state.floatingTicketPanel.panels;
export const selectStartIndexToShow = (state) => state.floatingTicketPanel.startIndexToShow;
export const selectAutoPopulateSectionDisplay = (state) => state.floatingTicketPanel.showAutoPopulateSection;

export default floatingTicketPanelSlice.reducer;
export const floatingTicketPanelSliceName = floatingTicketPanelSlice.name;
