import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DIRECTIONS } from '../../../../../../constants/common';

export const StyledAuditLogFieldChange = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isRichTextTruncated' && prop !== 'isRichText' && prop !== 'textTruncateEnabled',
})(({ theme, isRichTextTruncated }) => ({
  display: 'grid',
  gridTemplateColumns: 'fit-content(100%) min-content fit-content(100%)',
  alignItems: isRichTextTruncated ? 'center' : 'flex-start',
  width: '100%',
  wordBreak: 'break-word',
  fontSize: '14px',
  fontWeight: '400',

  '& .truncate-text-button': {
    padding: '0px 8px',
  },
  '.assignment-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '10px',
    height: '100%',
  },
  '.avatars-wrapper': {
    display: 'flex',
    flexDirection: 'row',
  },
  '.user-avatar': {
    marginRight: '-10px',
    zIndex: 2,
  },
  '.assignees-text-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 'calc(100% - 38px)', // 100% - icon - gap
  },
  '.assignees-text-wrapper:has(.both-assignee)': {
    width: 'calc(100% - 49px)', // 100% - icons - gap
  },
  '.rich-text-ellipsis': {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    MsBoxOrient: 'vertical',
    OBoxOrient: 'vertical',
    WebkitLineClamp: isRichTextTruncated ? '2' : 'none',
    MsLineClamp: isRichTextTruncated ? '2' : 'none',
    OLineClamp: isRichTextTruncated ? '2' : 'none',
    lineHeight: '20px !important',
  },
  '.field-change': {
    overflowWrap: 'break-word',
    whiteSpace: 'break-spaces',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    height: 'fit-content',
    '& .regular-text': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  '.assignee-username,.assignee-group': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  '.both-assignee': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '.assignee-username': {
      color: theme.palette.text.txt_default,
      lineHeight: '18px',
    },
    '.assignee-group': {
      fontSize: theme.fontSize.small,
      lineHeight: '16px',
      color: theme.palette.text.secondary_txt,
    },
  },

  [theme.breakpoints.down(1440)]: {
    marginTop: '0px',
  },
}));

export const StyledArrowDivider = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isCentered',
})(({ isCentered, direction }) => ({
  aspectRatio: 'auto',
  width: '21px',
  height: '100%',
  maxHeight: '32px',
  minHeight: '16px',
  margin: '0px 16px',
  alignSelf: isCentered ? 'center' : 'flex-start',
  transform: direction === DIRECTIONS.RTL && 'scale(-1)',
}));
