export const JIRA_FIELD_COUNT = 2;
export const JIRA_ACTION_FIELD_NAME = 'sr_cust_addon_jira_action';
export const JIRA_ISSUEKEY_FIELD_NAME = 'sr_cust_addon_jira_issuekey';
export const JIRA_ADDON_FIELD_PREFIX = 'sr_cust_addon_jira_';

// TODO useTexts
export const createJiraIssueFailuredMessage = 'Failed to create Jira issue';

export const REQUIRED_JIRA_FIELDS = [
  JIRA_ACTION_FIELD_NAME,
  JIRA_ISSUEKEY_FIELD_NAME
];
export const SINGLE_ROW_COUNT = 1;

export const jiraProjectField = {
  name: 'jiraProject',
  // TODO useTexts
  displayName: 'Jira Project',
  type: 'select',
  required: true,
};

export const jiraTypeField = {
  name: 'jiraType',
  // TODO useTexts
  displayName: 'Jira Type',
  type: 'select',
  required: true,
};

export const jiraParentIssueField = {
  name: 'jiraParentIssue',
  // TODO useTexts
  displayName: 'Jira Parent Issue',
  type: 'select',
  required: false,
};

export const jiraAssigneeField = {
  name: 'jiraAssignee',
  // TODO useTexts
  displayName: 'Jira Assignee',
  type: 'select',
  required: false,
};

export const jiraIssueField = {
  name: 'jiraIssue',
  // TODO useTexts
  displayName: 'Jira Issue',
  type: 'select',
  required: true,
};

export const jiraActionFieldValues = {
  CREATE: {
    // TODO useTexts??
    value: "Create",
    valueToDisplay: null,
    id: 1,
    valueClass: 0,
  },
  LINK: {
    // TODO useTexts??
    value: "Link",
    valueToDisplay: null,
    id: 2,
    valueClass: 0,
  },
};

export const UNLINK_JIRA_ISSUE = 'unlinkJiraIssue';
