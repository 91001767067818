import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledViewConfiguration = styled(Box)(({ theme }) => ({
  justifySelf: 'flex-start',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '5px',
  fontSize: theme?.fontSize?.medium,
  color: theme.palette.text.txt_default,
  fontFamily: theme.fonts.robotoLight,
  maxWidth: 'calc(100% - 700px)',
  '.list-actions': {
    marginRight: '8px',
  },
}));

export const StyledResetButton = styled('div', {
  shouldForwardProp: (prop) => !['style', 'isViewEdited', 'backgroundColor'].includes(prop),
})(({ theme, isViewEdited, style = {}, backgroundColor }) => ({
  height: '32px',
  marginRight: 'auto',
  borderRadius: '5px',
  fontSize: theme?.fontSize?.small,
  backgroundColor: theme?.palette?.generic?.[backgroundColor] || theme?.palette?.generic?.sysaid_light,
  display: isViewEdited ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5, 1),
  color: theme?.palette?.text?.title_txt,
  fontFamily: theme?.fonts?.robotoRegular,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette?.generic?.sysaid_mid,
  },
  ...style,
}));

export const StyledSaveButtonGroup = styled(Box, {
  shouldForwardProp: (prop) => !['isArchiveView'].includes(prop),
})(({ theme, isArchiveView }) => ({
  '.MuiButtonGroup-root': {
    height: '32px',
    '.ButtonWithMenuMainTitle': {
      height: '100%',
    },
    button: {
      backgroundColor: theme?.palette?.generic?.sysaid_light,
      fontSize: theme?.fontSize?.main,
      color: theme?.palette?.text?.title_txt,
      fontFamily: theme?.fonts?.robotoRegular,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: theme?.palette?.generic?.sysaid_mid,
      },
    },
    ...(isArchiveView
      ? {
          '.MuiButtonGroup-grouped': {
            borderWidth: '0px',
            height: 'unset',
            '& path': {
              stroke: theme?.palette?.text?.title_txt,
            },
          },
        }
      : {
          '.MuiButtonGroup-grouped:not(:last-of-type)': {
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            '& path': {
              stroke: theme?.palette?.text?.title_txt,
            },
          },
        }),
    '.MuiButtonGroup-grouped:not(:first-of-type)': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      padding: 0,
      minWidth: '32px',
      '& path': {
        stroke: theme?.palette?.text?.title_txt,
        fill: 'transparent',
      },
    },
  },
}));
