import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import { resetActiveView, selectActiveView, selectIsViewsEdited } from 'features/queue/slice';
import { useUpdateQueueView } from 'remote-state/queueViewsHooks';
import { convertFromClientField } from 'common/utils/fieldUtils';
import { ReactComponent as SaveIcon } from 'images/icons/save-view.svg';
import { ReactComponent as SaveAsIcon } from 'images/icons/save-as.svg';
import { ReactComponent as ResetIcon } from 'images/icons/reset.svg';
import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';
import ButtonWithMenu from 'common/components/buttons/ButtonWithMenu';
import useTexts from '../useTexts';
import { StyledResetButton, StyledSaveButtonGroup } from './style';
import { isArchiveQueueView } from '../../utils';

export const ViewActionButtons = ({ onErrorCallback, setIsSaveAsPopUpOpen }) => {
  const dispatch = useDispatch();
  const { mutate: updateView } = useUpdateQueueView();
  const activeView = useSelector(selectActiveView);
  const [loading, setLoading] = useState(false);
  const isViewEdited = useSelector(selectIsViewsEdited);
  const { resetButton, save, saveAs, queueViewsMessageSaveSuccess } = useTexts();
  const isArchiveView = isArchiveQueueView(activeView);

  const handleClickResetButton = () => {
    if (isViewEdited) {
      dispatch(resetActiveView());
    }
  };

  const handleUpdateView = useCallback(() => {
    setLoading(true);
    const createObj = {
      ...activeView,
      columnsConfig: {
        sorts: activeView.columnsConfig.customSortModel,
        filters: activeView.columnsConfig.customFilterModel,
        columnConfiguration: activeView?.columnsConfig.columnsOrder?.map((col) => convertFromClientField(col)),
        isQuickFilterApplied: activeView.columnsConfig.isQuickFilterApplied,
      },
    };
    updateView(createObj, {
      onSuccess: () => {
        setLoading(false);

        dispatch(
          setToasterMessage({
            message: queueViewsMessageSaveSuccess,
          }),
        );
      },
      onError: () => {
        setLoading(false);
        onErrorCallback();
      },
    });
  }, [dispatch, activeView, updateView, onErrorCallback, queueViewsMessageSaveSuccess]);

  const saveButtonMenu = useMemo(
    () => [
      {
        title: saveAs,
        icon: <SaveAsIcon />,
        onClick: () => setIsSaveAsPopUpOpen(),
        disabled: false,
        'data-testid': 'viewActionSaveAsButton',
      },
    ],
    [setIsSaveAsPopUpOpen, saveAs],
  );

  return (
    <>
      <StyledTooltip placement="bottom" title={resetButton} arrow>
        <StyledResetButton onClick={handleClickResetButton} isViewEdited={isViewEdited} data-testid="Reset button">
          <ResetIcon />
        </StyledResetButton>
      </StyledTooltip>

      <StyledSaveButtonGroup isArchiveView={isArchiveView}>
        <ButtonWithMenu
          title={save}
          menuList={saveButtonMenu}
          onClick={handleUpdateView}
          loading={loading}
          icon={<SaveIcon />}
          testIdPrefix="viewActionSave"
          isArchiveView={isArchiveView}
        />
      </StyledSaveButtonGroup>
    </>
  );
};
export default ViewActionButtons;
