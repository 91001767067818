import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { selectActiveUser } from 'store/userSlice';
import { getLoginPathInfo } from 'common/utils/utils';
import { useLoginObject } from 'remote-state/userServiceHooks';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { ROUTES_PATHS } from 'constants/routes';
import { getLicenseCheck } from 'services/licenseCheck';
import { useQueryClient } from '@tanstack/react-query';
import { isLocalHost } from 'services/localhost';
import { APP_CONSTANTS } from 'constants/app';
import { QUERIES_KEYS } from 'constant';
import useLicenseCheckMonitor from 'common/utils/hooks/useLicenseCheckMonitor';
import { LoginPage } from './pages/loginPage';
import { StyledLoginSession } from './style';
import SeasonalLicenseExpirationPage from './pages/licenseExpirationPage';
import { LICENSE_VALIDATION_STATES } from './constants';

const isLocalhost = isLocalHost();

export const isQAMode =
  localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) || localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) === null;

export const LoginSession = () => {
  const router = useRouter();
  const currentUser = useSelector(selectActiveUser);
  const { data: loginObject, isInitialLoading, isFetching } = useLoginObject();
  const { previousPath, manualLogin } = getLoginPathInfo();
  const [licenseValid, setLicenseValid] = useState(LICENSE_VALIDATION_STATES.LOADING);
  const queryClient = useQueryClient();
  const { licenseCheckFailedReport } = useLicenseCheckMonitor();

  useLayoutEffect(() => {
    if (isLocalhost) {
      setLicenseValid(LICENSE_VALIDATION_STATES.DEV_MODE);
    } else {
      const fetchLicenseCheck = async () => {
        try {
          const data = await queryClient.fetchQuery([QUERIES_KEYS.LICENS_ECHECK], getLicenseCheck);

          if (data.validLicense && data.validLicense === true) {
            setLicenseValid(LICENSE_VALIDATION_STATES.SUCCESS);
          } else {
            setLicenseValid(LICENSE_VALIDATION_STATES.FAILED);
            licenseCheckFailedReport(data, null);
          }
        } catch (error) {
          if (isLocalhost || isQAMode) {
            setLicenseValid(LICENSE_VALIDATION_STATES.DEV_MODE);
          } else {
            setLicenseValid(LICENSE_VALIDATION_STATES.FAILED);
            licenseCheckFailedReport({}, error);
          }
        }
      };

      fetchLicenseCheck();
    }
  }, [licenseCheckFailedReport, queryClient]);

  useEffect(() => {
    let navigationPath = previousPath;
    const from = router.latestLocation.search?.from;
    const external = router.latestLocation.search?.external || from?.startsWith(ROUTES_PATHS.SERVICE_PORTAL_URL);
    const currentUrl = router.latestLocation.href;
    if (from) {
      navigationPath = from;
    } else if (previousPath === ROUTES_PATHS.BASE_PATH && currentUrl.indexOf('login') < 0) {
      navigationPath = currentUrl;
    }
    if (navigationPath === '/') {
      navigationPath = '/spaces';
    }
    if (currentUser?.isAuth) {
      if (external) {
        window.location.href = navigationPath;
      } else {
        router.navigate({ to: navigationPath, replace: true });
      }
    }
  }, [currentUser?.isAuth, previousPath, router]);

  const renderRelevantPage = () => {
    const { externalUrl: externalLoginUrl } = loginObject;
    const externalLogin = document.cookie.split(';').filter((cookie) => cookie.includes('EXTERNAL_LOGIN'));
    if (loginObject && (!loginObject?.isExternalUrl || manualLogin === 'true' || externalLogin?.length > 0)) {
      return (
        <LoginPage
          customization={loginObject?.customization}
          isDomainField={loginObject?.isDomainField}
          isQAMode={loginObject?.isQAMode}
          isNewRecaptchaEnabled={loginObject?.useNewRecaptcha}
        />
      ); // our login page
    }
    if (loginObject?.isExternalUrl) {
      window.location.href = externalLoginUrl;
      return;
    }
    return <div>No Page Loaded</div>;
  };

  return (
    <>
      {licenseValid === LICENSE_VALIDATION_STATES.LOADING && <PendingComponentLoader />}
      {(licenseValid === LICENSE_VALIDATION_STATES.SUCCESS || licenseValid === LICENSE_VALIDATION_STATES.DEV_MODE) && (
        <>
          {!currentUser?.isAuth && (
            <>
              {isInitialLoading || isFetching ? (
                <PendingComponentLoader />
              ) : (
                <StyledLoginSession>
                  {loginObject ? (
                    <div className="login-page">{renderRelevantPage()}</div>
                  ) : (
                    <div>Error while trying to fetch login page</div>
                  )}
                </StyledLoginSession>
              )}
            </>
          )}
        </>
      )}
      {licenseValid === LICENSE_VALIDATION_STATES.FAILED && <SeasonalLicenseExpirationPage />}
    </>
  );
};
