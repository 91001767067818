import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import { PlaceholderValues } from 'common/utils/constants';
import { ReactComponent as OpenPickerIcon } from '../../../images/icons/icon_Schedule.svg';
import { StyledDatePickerButton, StyledDatePickerIcons } from './StyledDatePickerButton';
import useTexts from './useTexts';
import Tooltip from '../tooltip';

export const EmptyDatePicker = (props) => {
  const {
    placeholder,
    required,
    onClick,
    datePickerRef,
    isNewField,
    disabled,
    isTemplatePage,
    isDatePopupOpen,
    warningTooltip,
    isCloseTime,
  } = props;
  const { chooseDate } = useTexts();
  const disabledPlaceholderText = isCloseTime ? PlaceholderValues.ClosingTimestamp : PlaceholderValues.CreationTimestamp;
  const placeholderText = disabled ? disabledPlaceholderText : placeholder || chooseDate;
  const forceShowError = useSelector(isNewField ? selectForceFloatingTicketShowValidationError :  selectForceShowValidationError);

  const isError = useMemo(() => {
    const newFieldError = isNewField && required && forceShowError;
    const existingFieldError = !isNewField && required;
    return newFieldError || existingFieldError;
  }, [forceShowError, isNewField, required]);

  return (
    <StyledDatePickerButton
      ref={datePickerRef}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      disableRipple={disabled}
      isError={isError}
      isNewField={isNewField}
      isTemplatePage={isTemplatePage}
      isDatePopupOpen={isDatePopupOpen}
      data-testid="empty-date-picker"
    >
      <span>{placeholderText}</span>
      <StyledDatePickerIcons>
        {!disabled && <OpenPickerIcon style={{ width: '14px', height: '14px' }} className="openPickerIcon" />}
        {isError && (
          <Tooltip isError title={warningTooltip} placement="top" text={<WarningIcon className="warning-icon" />} />
        )}
      </StyledDatePickerIcons>
    </StyledDatePickerButton>
  );
};
