import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { actionEnums } from '../constants';

export const StyledLogDescription = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'hasTitle' && prop !== 'logType' && prop !== 'isEditMode' && prop !== 'isEditorAction',
})(({ theme, hasTitle, logType, isEditMode, isEditorAction }) => {
  let commonStyles = {};
  switch (logType) {
    case actionEnums.NOTE_UPDATE:
    case actionEnums.NOTE_DELETE:
    case actionEnums.MESSAGE_SENT:
    case actionEnums.AI_MESSAGE_SEND:
    case actionEnums.MESSAGE_RECEIVED:
    case actionEnums.SOLUTION_UPDATED:
    case actionEnums.RESOLUTION_UPDATED:
    case actionEnums.FIELD_DESCRIPTION:
    case actionEnums.FIELD_REGULAR:
    case actionEnums.FIELD_REASSIGNMENT:
    case actionEnums.FIELD_OUT_OF_OFFICE:
    case actionEnums.IM_MESSAGE:
    case actionEnums.SMS_MESSAGE:
    case actionEnums.ITIL_MESSAGE:
      commonStyles = {
        maxWidth: '687px',
      };
      break;
    case actionEnums.NOTE_CREATE:
    case actionEnums.SOLUTION_RESOLUTION_CREATED:
      commonStyles = {
        maxWidth: isEditMode ? '100%' : '687px',
        '& .action-line': {
          maxWidth: isEditMode ? '100%' : 'initial',
        },
      };
      break;
    case actionEnums.ACTIVITY_CREATED:
      commonStyles = {
        maxWidth: '100%',
      };
      break;
    case actionEnums.ATTACHMENTS_ADDED:
      commonStyles = {
        maxWidth: '100%',
      };
      break;
    case actionEnums.RELATED_ITEM_SR_CREATED:
    case actionEnums.RELATED_ITEM_SR_DELETED:
      commonStyles = {
        maxWidth: 'calc(100% - 240px)',
        '@media only screen and (max-width: 1280px)': {
          maxWidth: 'calc(100% - 255px)',
        },
      };
      break;
    default:
      commonStyles = {
        maxWidth: '503px',
      };
  }
  const descriptionLogMargin = () => {
    switch (logType) {
      case actionEnums.NOTE_DELETE:
      case actionEnums.NOTE_UPDATE:
        return '-4px 0 0';
      default:
        return '0px';
    }
  };
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: hasTitle ? 'center' : 'flex-start',
    flexGrow: '1',
    margin: descriptionLogMargin(),
    color: theme.palette.text.title_txt,
    fontSize: theme.fontSize.main,
    lineHeight: '1.429',
    wordBreak: 'break-word',
    width: isEditorAction ? '100%' : 'auto',
    ...commonStyles,
  };
});

export const StyledLogEvent = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  top: '7px',
}));

export const StyledEvent = styled(Box)(() => ({
  display: 'flex',
}));

export const StyledLinkButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: theme?.palette?.generic?.sysaid_default,
  textDecoration: `${theme?.palette?.generic?.sysaid_default} underline solid`,
}));

export const LogDescriptionItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'logInfo',
})(({ logInfo }) => {
  const descriptionValueStyle = () => {
    const { oldValue, newValue, isNewValue, logType, fieldName, textTruncateEnabled } = logInfo;
    if (
      textTruncateEnabled ||
      fieldName === 'description' ||
      fieldName === 'title' ||
      [
        actionEnums.NOTE_UPDATE,
        actionEnums.SOLUTION_UPDATED,
        actionEnums.RESOLUTION_UPDATED,
        actionEnums.FIELD_DESCRIPTION,
      ].includes(logType)
    ) {
      const maxWidth =
        (isNewValue && !oldValue && newValue) || (!isNewValue && oldValue && !newValue) ? '576px' : '308px';
      return {
        maxWidth,
        minWidth: '34px',
      };
    }
    if ([actionEnums.FIELD_STATUS, actionEnums.FIELD_PRIORITY, actionEnums.FIELD_CATEGORY].includes(logType)) {
      return {
        pointerEvents: 'none',
        maxWidth: '225px',
      };
    }
    return {
      maxWidth: '225px',
    };
  };
  return {
    width: 'auto',
    ...descriptionValueStyle(),
  };
});
