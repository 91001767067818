import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';

export const StyledDragOverlayField = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'isBig' })(
  ({ theme, isBig }) => {
    const background = '#fff';
    const boxShadow = '0 0 15px 0 rgba(0, 0, 0, 0.25)';
    const border = `1px solid ${theme?.palette?.generic?.sysaid}`;

    return {
      borderRadius: theme.shape.border.borderRadius,
      background,
      border,
      boxShadow,
      cursor: 'grabbing',
      alignItems: 'center',
      padding: '6px 10px',
      width: isBig ? '200%' : '100%',
      maxWidth: '100%',
      '&:hover': {
        background,
        border,
        boxShadow,
      },
      '.drag-icon': {
        width: '14px',
        height: '14px',
        '*': {
          stroke: theme.palette.generic.sysaid_hover,
        },
      },
      '.MuiListItemText-root': {
        marginLeft: '12px',
        marginTop: '5px',
        marginBottom: '3px',
      },
      '.MuiListItemText-primary': {
        fontSize: theme.fontSize.main,
        lineHeight: 1,
      },
    };
  },
);
