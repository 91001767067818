import { useState, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Paper,
  Grow,
  Popper,
  Button,
  ListItemIcon,
  MenuList,
  ClickAwayListener,
  CircularProgress,
} from '@mui/material';
import { StyledButtonGroup, StyledMenuItem } from './style';

export default function ButtonWithMenu({
  loading,
  disabled,
  icon,
  title,
  onClick,
  menuList,
  btnTheme = 'secondary',
  style = {},
  placement = 'top-end',
  testIdPrefix,
  isArchiveView,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    onClick();
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <StyledButtonGroup ref={anchorRef} disabled={disabled} btnTheme={btnTheme} style={style}>
        <Button
          onClick={handleClick}
          startIcon={loading ? <CircularProgress size={14} className="loadingIcon" /> : icon}
          data-testid={`${testIdPrefix}MainButton`}
        >
          <span className="ButtonWithMenuMainTitle">{title}</span>
        </Button>
        {!isArchiveView && (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            data-testid={`${testIdPrefix}DropDownButton`}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </StyledButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top end',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={`${testIdPrefix}DropDownList`} autoFocusItem data-testid={`${testIdPrefix}DropDownList`}>
                  {menuList.map((option, index) => {
                    const { disabled, title, icon, onClick, ...rest } = option;
                    return (
                      <StyledMenuItem
                        key={index}
                        disabled={disabled}
                        onClick={(event) => {
                          onClick(event);
                          handleToggle();
                        }}
                        {...rest}
                      >
                        {icon ?? <ListItemIcon>{icon}</ListItemIcon>}
                        {title}
                      </StyledMenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
