import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    addJiraSection: texts?.['spaces.template.sections.integrations.addJiraSection'] || 'Add Jira Integration',
    jiraSectionTitle: texts?.['spaces.template.sections.jiraSectionTitle'] || 'Jira Integration',
    unlinkJiraIssue: texts?.['spaces.integrations.jira.actions.unlink'] || 'Unlink Jira Issue',

  };
  return componentTexts;
};
