import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { Box, Grid } from '@mui/material';
import { activeComponentTypeEnum, setActiveComponent } from 'features/templateBuilder/activeComponentSlice';
import FieldComponent from './fieldComponent';
import DeleteIcon from '../../templateBuilder/TemplateSection/DeleteIcon';
import { BlockWrapper, DeleteIconWrapper } from './style';

function TemplateFieldsGrid(props) {
  const dispatch = useDispatch();

  const { fields, sectionRows, handleUpdateSectionByValues, sr, isTemplatePage, isJiraIntegrationSection } = props;

  const isSrPage = !isEmpty(sr);
  const handleFieldDelete = (fieldToDelete) => {
    const filterField = (field) => field.fieldId !== fieldToDelete;
    handleUpdateSectionByValues([
      {
        value: sectionRows.map((row) => ({ ...row, fields: row.fields.filter(filterField) })),
        property: 'sectionRows',
      },
      {
        value: fields.filter(filterField),
        property: 'fields',
      },
    ]);
  };

  return (
    <Grid container spacing={2} justifyContent="space-between" data-cy="fields-grid">
      {fields.map((field) => (
        <BlockWrapper
          isTemplatePage={isTemplatePage}
          halfRow={field?.position !== 'STRETCH'}
          key={field.fieldName}
          id={`grid_item_${field.fieldName}`}
        >
          <Box
            width="100%"
            data-ispropertiesfield="true"
            onMouseDown={() => {
              if (!isSrPage) {
                dispatch(
                  setActiveComponent({ componentType: activeComponentTypeEnum.FIELDS, componentId: field.fieldId }),
                );
              }
            }}
          >
            <FieldComponent field={field} {...props} />
          </Box>
          {!isSrPage && !isJiraIntegrationSection && (
            <DeleteIconWrapper>
              <DeleteIcon onDelete={() => handleFieldDelete(field.fieldId)} />
            </DeleteIconWrapper>
          )}
        </BlockWrapper>
      ))}
    </Grid>
  );
}

export default memo(TemplateFieldsGrid);
