import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledKebabMenu = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'isOpen',
})(({ isVisible, isOpen, theme }) => ({
  visibility: isVisible ? 'visible' : 'hidden',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16px',
  height: '16px',
  padding: '2px',
  backgroundColor: isOpen ? theme.palette.background.pages_background : 'transparent',
  borderRadius: theme?.shape?.border?.borderRadius,
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.background.pages_background,
  },
  '& svg': {
    maxHeight: '100%',
  },
}));

export const StyledMenu = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'alignToLeft',
})(({ alignToLeft }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
  borderRadius: '6px',
  backgroundColor: 'white',
  position: 'absolute',
  zIndex: 3,
  padding: '8px',
  top: '16px',
  width: 'max-content',
  left: alignToLeft ? '0px' : 'auto',
  right: alignToLeft ? 'auto' : '0px',
}));

export const StyledActionItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '42px',
  padding: '8px',
  fontSize: '14px',
  color: '#4F4F4F',
  gap: '10px',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
  },
}));
