import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from '../../../../common/utils/translation';
import { actionTypes } from './constants';

export default function useTexts(ticketCount) {
  const texts = useRbTexts();
  const componentTexts = {
    deleteTicketText: texts?.['spaces.actionBar.deleteTickets.delete'],
    deleteTicketCancelText: texts?.['spaces.actionBar.deleteTickets.cancel'],
    deleteTicketErrorText: texts?.['spaces.header.actions.deleteTicket.error'],
    recordsSelectedText: texts?.['spaces.actionBar.recordsSelected'],
    assignText: texts?.['spaces.actionBar.assign'],
    deleteTicketWarningMessageText: texts?.['spaces.actionBar.deleteTickets.warningMessage'],
    deleteTicketMessageText: ({ selectedTicketsCount, failureCount, successCount }) => {
      let text;
      if (selectedTicketsCount === successCount) {
        text = texts?.['spaces.actionBar.deleteTicket.wasDeletedMessage'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.wasDeletedMessage'];
        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      if (failureCount === selectedTicketsCount) {
        text = texts?.['spaces.actionBar.deleteTicket.all.failed.to.delete'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.all.failed.to.delete'];
        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      text = texts?.['spaces.actionBar.deleteTicket.partial.success'];
      if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.partial.success'];
      return formatTranslation(text, [
        { placeHolder: '{numberOfSuccessTickets}', value: successCount },
        { placeHolder: '{numberOfFailedTickets}', value: failureCount },
      ]);
    },

    loadingTicketMessageText: ({ actionType, assignee }) => {
      let text;
      switch (actionType) {
        case actionTypes.ASSIGN: {
          text = texts?.['spaces.actionBar.load.assign'];
          if (ticketCount > 1) text = texts?.['spaces.actionBar.loading.assign'];
          return formatTranslation(text, [
            { placeHolder: '{numberOfTickets}', value: ticketCount },
            { placeHolder: '{assignee}', value: assignee },
          ]);
        }
        case actionTypes.DELETE: {
          text = texts?.['spaces.actionBar.load.delete'];
          if (ticketCount > 1) text = texts?.['spaces.actionBar.loading.delete'];
          return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
        }
        default:
      }
    },
    assignedTicketMessageText: ({ selectedTicketsCount, failureCount, successCount, assignee = null }) => {
      let text = texts?.['spaces.actionBar.assignTicket.partial.success'];

      if (selectedTicketsCount === successCount) {
        text = texts?.['spaces.actionBar.assignTicket.wasAssignedMessage'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.wasAssignedMessage'];

        return formatTranslation(text, [
          { placeHolder: '{numberOfTickets}', value: ticketCount },
          { placeHolder: '{assignee}', value: assignee },
        ]);
      }
      if (failureCount === selectedTicketsCount) {
        text = texts?.['spaces.actionBar.assignTicket.all.failed.assignment'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.all.failed.assignment'];

        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.partial.success'];
      return formatTranslation(text, [
        { placeHolder: '{numberOfSuccessTickets}', value: successCount },
        { placeHolder: '{numberOfFailedTickets}', value: failureCount },
      ]);
    },
    assignedSubTitleText: texts?.['spaces.actionBar.assignTickets.subTitle'],
    archiveTicketText: texts?.['spaces.actionBar.archiveTickets.archive'] || 'Archive',
    archiveTicketConfirmText: texts?.['spaces.actionBar.archiveTickets.archive.confirm'] || 'Archive',
    archiveTicketCancelText: texts?.['spaces.actionBar.archiveTickets.cancel'] || 'Cancel',
    archiveTicketWarningMessageTitle: ({ lastSelectedSrId }) => texts?.['spaces.actionBar.archiveTickets.warningMessage.title'] ||
      (ticketCount === 1 ? `Archive Service Record #${lastSelectedSrId}?` : `Archive Service Records #${lastSelectedSrId} +${ticketCount - 1}?`),
    archiveTicketWarningMessageText: texts?.['spaces.actionBar.archiveTickets.warningMessage.text'] ||
      (ticketCount === 1 ? 'This Service Record will be removed from active queue. You can find it in the "Archived" queue view' :
        'The following Service Records will be removed from active queue. You can find them in the "Archived" queue view'),
    archiveTicketMessageSuccessText: ({ numOfSuccessTickets, singleSrId }) => {
      if (numOfSuccessTickets === 1) {
        return texts?.['spaces.actionBar.archiveTickets.archive.message.success.single'] ||
          `Service Record #${singleSrId} was archived`
      }
      return texts?.['spaces.actionBar.archiveTickets.archive.message.success.multiple'] ||
        `Service Records #${singleSrId} +${numOfSuccessTickets - 1} were archived`
    },
    archiveTicketMessagePartialSuccessPermissionText: ({ numOfSuccessTickets, totalNumOfTickets }) =>
      texts?.['spaces.actionBar.archiveTickets.archive.message.partialSuccess.permission'] ||
      `${numOfSuccessTickets} of ${totalNumOfTickets} Service Records were archived. You do not have sufficient permission
       to archive the rest. Please contact an administrator.`,
    archiveTicketMessagePartialSuccessNotResolvedText: ({ numOfSuccessTickets, totalNumOfTickets }) =>
      texts?.['spaces.actionBar.archiveTickets.archive.message.partialSuccess.notResolved'] ||
      `${numOfSuccessTickets} of ${totalNumOfTickets} Service Records were archived. 
      Some service records couldn't be archived because they have not been resolved yet.`,
    archiveTicketErrorMessageText: texts?.['spaces.actionBar.archiveTickets.archive.error.text'] ||
      (ticketCount === 1 ? `Couldn't archive Service Record. Please try again` : `Couldn't archive Service Records. Please try again`),
    restoreTicketText: texts?.['spaces.actionBar.archiveTickets.restore'] || 'Restore',
    restoreTicketConfirmText: texts?.['spaces.actionBar.archiveTickets.restore.confirm'] || 'Restore',
    restoreTicketWarningMessageTitle: ({ lastSelectedSrId }) => texts?.['spaces.actionBar.archiveTickets.warningMessage.title'] ||
      (ticketCount === 1 ? `Restore Service Record #${lastSelectedSrId}?` :
        `Restore Service Records #${lastSelectedSrId} +${ticketCount - 1}?`),
    restoreTicketWarningMessageText: texts?.['spaces.actionBar.archiveTickets.warningMessage.text'] ||
      (ticketCount === 1 ? 'This Service Record will be removed from the "Archived" view. You can find it in active queue view' :
        'The following Service Records will be removed from the "Archived" view. You can find them in active queue view'),
    restoreTicketMessageSuccessText: ({ numOfSuccessTickets, singleSrId }) => {
      if (numOfSuccessTickets === 1) {
        return texts?.['spaces.actionBar.archiveTickets.restore.message.success.single'] ||
          `Service Record #${singleSrId} was restored`
      }
      return texts?.['spaces.actionBar.archiveTickets.restore.message.success.multiple'] ||
        `Service Records #${singleSrId} +${numOfSuccessTickets - 1} were restored`
    },
    restoreTicketMessagePartialSuccessPermissionText: ({ numOfSuccessTickets, totalNumOfTickets }) =>
      texts?.['spaces.actionBar.archiveTickets.restore.message.partialSuccess.permission'] ||
      `${numOfSuccessTickets} of ${totalNumOfTickets} service records were restored. You do not have sufficient permission
       to restore the rest. Please contact an administrator.`,
    restoreTicketMessagePartialSuccessNotResolvedText: ({ numOfSuccessTickets, totalNumOfTickets }) =>
      texts?.['spaces.actionBar.archiveTickets.restore.message.partialSuccess.notResolved'] ||
      `${numOfSuccessTickets} of ${totalNumOfTickets} service records were restored. Some service records couldn't be restored because they have not been resolved yet.`,
    restoreTicketErrorMessageText: texts?.['spaces.actionBar.archiveTickets.restore.error.text'] ||
      (ticketCount === 1 ? `Couldn't restore Service Record. Please try again` : `Couldn't restore Service Records. Please try again`),
    viewSR: texts?.['spaces.actionBar.archiveTickets.viewSR'] || 'View Service Record',
    copyURL: texts?.['spaces.actionBar.archiveTickets.copyURL'] || 'Copy URL',
  };

  return componentTexts;
}
