import { ButtonUnstyled } from '@mui/core';
import { styled } from '@mui/material/styles';

export const StyledLinkItemBtn = styled(ButtonUnstyled)(({ theme, isArchived }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32px',
  width: 'fit-content',
  background: '#699D8C',
  color: 'white',
  borderRadius: '60px',
  padding: '8px 16px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  gap: '2px',
  fontSize: '16px',
  lineHeight: '16px',
  alignSelf: 'end',
  position: 'absolute',
  top: '1.7%',
  zIndex: 9999,
  fontFamily: theme?.fonts?.robotoRegular,
  '& svg': {
    width: '17px',
  },

  // the tooltip span
  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  },

  ':hover': {
    backgroundColor: theme?.palette?.generic?.sysaid_default_hover,
  },

  ':disabled': {
    background: theme?.palette?.generic?.sysaid_mid,
  },

  ...(isArchived && {
    pointerEvents: 'none',
    cursor: 'default',
    position: 'relative',
    '&::before': {
      ...theme?.mixins?.archivedOverlay,
      background: theme.palette?.generic?.primary,
    },
  }),
}));
