import useTexts from './useText';

const LisenceExpirationTexts = ({ season }) => {
  const { fallText, summerText, springText, winterText, title, link, phoneNumber, thirdLineStart } = useTexts();

  const seasonalText = {
    spring: springText,
    summer: summerText,
    fall: fallText,
    winter: winterText,
  };

  return (
    <div className="seasonal-text">
      <p className="seasonal-title">{title}</p>
      <p className="seasonal-first-line">{seasonalText[season]}</p>
      <p className="seasonal-second-line-text">
        {[
          `${thirdLineStart} `,
          <a href={`mailto:${link}`} target="_blank" rel="noreferrer">
            {link}
          </a>,
          ` ${phoneNumber}`,
        ]}
      </p>
    </div>
  );
};

export default LisenceExpirationTexts;
