import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isArchived'
})(({ theme, isArchived }) => ({
  backgroundColor: theme.palette.background.pages_background,
  flexShrink: 0,
  ...(isArchived && { marginTop: '40px' }),
}));
