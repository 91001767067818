import { APP_CONSTANTS } from 'constants';
import {
  PARENT_STATUS_CHANGE_POPUP,
  SR_TO_INCIDENT_STATUS_CHANGE_POPUP,
  UNCHANGED_CHILD_STATUS_POPUP,
} from 'features/resolutionPanel/middlePanel/relatedItems/constants';

export const shouldShowParentStautsChangePopup = ({ isStatusOfParentSr }) => {
  const dontShowAgainData = JSON.parse(localStorage.getItem(APP_CONSTANTS.DONT_SHOW_AGAIN)) || {};
  const showPrompt = !dontShowAgainData?.[PARENT_STATUS_CHANGE_POPUP];
  return showPrompt && isStatusOfParentSr;
};

export const shouldShowLinkedIncidentsStautsChangePopup = ({ linkedIncidentsCount, statusSettings, value }) => {
  const dontShowAgainData = JSON.parse(localStorage.getItem(APP_CONSTANTS.DONT_SHOW_AGAIN)) || {};
  const showLinkedIncidentsStatusChangePopup =
    !!linkedIncidentsCount &&
    statusSettings?.some(
      (setting) => setting.changeStatus === value?.id && !setting.excludeStatuses.includes(setting.incidentStatus),
    ) &&
    !dontShowAgainData?.[SR_TO_INCIDENT_STATUS_CHANGE_POPUP];
  return showLinkedIncidentsStatusChangePopup;
};

export const handleLinkedIncidentsStautsChangePopupTexts = ({
  statusSettings,
  value,
  statusList,
  linkedIncidentsStatusChangeTitle,
  linkedIncidentsStatusChangeDescription,
  srTypeText,
  linkedIncidentsCount,
}) => {
  const incidentUpdatedStatusId = statusSettings?.find((setting) => setting.changeStatus === value?.id)?.incidentStatus;
  const updatedStatusCaption = statusList?.find(
    (status) => Number(status.id) === Number(incidentUpdatedStatusId),
  )?.value;
  const title = linkedIncidentsStatusChangeTitle.replace('__srType__', srTypeText);
  const description = linkedIncidentsStatusChangeDescription
    ?.replace('__srType__', srTypeText)
    ?.replace('__linkedIncidentsCount__', linkedIncidentsCount)
    ?.replace('__updatedStatus__', `"${updatedStatusCaption}"`);
  return { title, description };
};

export const changeStatusPopupStyles = {
  [PARENT_STATUS_CHANGE_POPUP]: {
    MuiPaperRoot: {
      maxWidth: '398px!important',
      width: '398px!important',
    },
    MuiDialogTitle: {
      width: '398px!important',
    },
    promptWrapper: {
      gap: '12px',
    },
  },
  [SR_TO_INCIDENT_STATUS_CHANGE_POPUP]: {
    MuiPaperRoot: {
      width: '426px !important',
      maxWidth: '426px !important',
    },
    MuiDialogTitle: {
      width: '426px !important',
    },
    promptWrapper: {
      gap: '12px',
    },
  },
  [UNCHANGED_CHILD_STATUS_POPUP]: {
    MuiDialogTitle: {
      maxWidth: '315px!important',
    },
  },
};
