export const LICENSE_CHECK = {
  EVENT_NAME: {
    LICENSE_CHECK_ERROR: 'license check error',
    INVALID_INSTANCE: 'invalid instance',
    INVALID_ACCOUNT: 'invalid account',
    LICENSE_EXPIRED: 'license expired',
  },
  MESSAGE: {
    LICENSE_CHECK_ERROR: 'failed on license check - ',
    INVALID_INSTANCE: 'failed on license check - instance not found',
    INVALID_ACCOUNT: 'failed on license check - AccountID not found',
    LICENSE_EXPIRED: 'failed on license check - license expired',
  },
};
