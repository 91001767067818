import { useEffect, useState } from 'react';
import Froalaeditor from 'froala-editor';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { buttonOverloads, customButtons } from '../buttons';
import useTexts from '../../useTexts';

const useCustomButtons = ({ refs, isActive, callbacks }) => {
  const [isConfigured, setIsConfigured] = useState(false);
  const prevIsActive = usePreviousValue(isActive);
  const { getColumnText } = useTexts();

  useEffect(() => {
    if (!isConfigured) {
      [...buttonOverloads, ...customButtons].forEach(({ name, props: buttonProps }) => {
        let propsCopy = buttonProps;
        if (buttonProps.callback) {
          propsCopy = {
            ...buttonProps,
            callback() {
              buttonProps.callback({
                editor: this,
                refs,
                callbacks,
                id: this.id,
              });
            },
          };
        }
        if (buttonProps.title) {
          propsCopy = {
            ...propsCopy,
            title: getColumnText(buttonProps.title),
          };
        }
        if (buttonProps.options) {
          propsCopy = {
            ...propsCopy,
            options: Object.fromEntries(
              Object.entries(buttonProps.options).map(([key, value]) => [key, getColumnText(value)]),
            ),
          };
        }
        Froalaeditor.RegisterCommand(name, {
          ...Froalaeditor.COMMANDS[name],
          ...propsCopy,
        });
      });
      setIsConfigured(true);
    }
  }, [callbacks, isConfigured, refs, getColumnText]);

  useEffect(() => {
    if (isActive && prevIsActive !== isActive) {
      setIsConfigured(false);
    }
  }, [isActive, prevIsActive]);
};

export { useCustomButtons };
