/*eslint-disable*/
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import Tooltip from 'common/components/tooltip';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { ReactComponent as GreyAiStars } from 'images/icons/generativeAi/grey_stars.svg';
import { categoriesFieldNames } from 'features/queue/utils';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { BarLoader } from 'features/queue/grid/defaultCellRender/SkeletonLoader';
import { Label } from './style';
import { useTexts } from '../../../common/components/dropdownSelect/hooks';
import { FIRST_LEVEL_CATEGORY_FIELD_ID } from './constants';

export const CategorySelect = ({
  list,
  initValue,
  customHeaderStyles,
  defaultTitle,
  isOpen,
  backendQueryConfig,
  disabled = false,
  onDisabledClick,
  autoInputFocus = true,
  fieldId,
  onFocus,
  onClose,
  onChange,
  handleClearValue,
  isTemplatePage,
  dataset = {},
  isSuggestedCategoryShown,
  dropdownProps,
  isNewField,
  isLoading,
  required,
  className = '',
}) => {
  const [anchor, setAnchor] = useState(null);
  const labelRef = useRef(null);
  const { categoryNoValue, mandatoryFieldError } = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const forceShowError = useSelector(
    isNewField ? selectForceFloatingTicketShowValidationError : selectForceShowValidationError,
  );

  useEffect(() => {
    if (isOpen) {
      setAnchor(labelRef.current);
    } else {
      setAnchor(null);
    }
  }, [isOpen]);

  const handleFocus = useCallback(() => {
    if (!disabled) {
      if (onFocus) {
        if (isSuggestedCategoryShown && fieldId === FIRST_LEVEL_CATEGORY_FIELD_ID)
          pendoTrackEvents(PENDO_TRACK_EVENTS.SUGGESTED_CATEGORY_CHECKED);
        onFocus(fieldId);
      }
    } else {
      onDisabledClick && onDisabledClick()
    }
  }, [disabled, onFocus, isSuggestedCategoryShown, fieldId, pendoTrackEvents]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleChange = useCallback(
    (selected, optionsMap) => {
      onChange(parseInt(selected.id, 10), Object.values(optionsMap));
      handleClose();
    },
    [onChange, handleClose],
  );

  const labelText = useMemo(() => {
    if (initValue && initValue.name != null) {
      return initValue.name === '' || initValue.name === ' ' ? categoryNoValue : initValue.name;
    }

    return defaultTitle;
  }, [defaultTitle, initValue, categoryNoValue]);

  const selection = useMemo(() => (initValue?.id || initValue?.id === 0 ? [initValue?.id] : []), [initValue?.id]);

  const isEmptyRequiredValue = useMemo(() => {
    const isInvalid = !selection.length && required;
    const newFieldError = isNewField && isInvalid && forceShowError;
    const existingFieldError = !isNewField && isInvalid;
    return newFieldError || existingFieldError;
  }, [forceShowError, isNewField, required, selection?.length]);

  if (isLoading) return <BarLoader />;

  return (
    <>
      <Label
        data-testid="select-category"
        data-cy={categoriesFieldNames[fieldId] || 'select-category'}
        ref={labelRef}
        onClick={handleFocus}
        stylingVariant={itemClassNames.mainCategory}
        customHeaderStyles={customHeaderStyles}
        value={initValue}
        disabled={disabled}
        isTemplatePage={isTemplatePage}
        {...dataset}
        className={className}
      >
        <Tooltip
          isError={isEmptyRequiredValue}
          title={isEmptyRequiredValue ? mandatoryFieldError : ''}
          forceShowOnHover={isEmptyRequiredValue}
          isTruncatedText
          text={labelText}
          placement="top"
        />
        {isSuggestedCategoryShown && fieldId === FIRST_LEVEL_CATEGORY_FIELD_ID && (
          <GreyAiStars style={{ marginLeft: '0.375rem' }} />
        )}
      </Label>
      <DropdownSelect
        autoInputFocus={autoInputFocus}
        options={list}
        selection={selection}
        handleChange={handleChange}
        stylingVariant={itemClassNames.mainCategory}
        handleClose={handleClose}
        anchor={anchor}
        captionString="name"
        keyString="id"
        handleClearValue={handleClearValue}
        backendQueryConfig={backendQueryConfig}
        sortBy="name"
        isCategory
        {...dropdownProps}
      />
    </>
  );
};
