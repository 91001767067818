import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    popupTitle: texts?.['spaces.prompt.queueView.saveViewAsTitle'],
    viewDetailsPopUpTitle: texts?.['spaces.prompt.queueView.viewDetailsPopUpTitle'],
    viewNameLabel: texts?.['spaces.prompt.queueView.viewNameLabel'] || 'View Name',
    popupInputPlaceholder: 'N/A',
    popupOkBtnText: texts?.['spaces.prompt.queueView.okBtnText'],
    popupCancelBtnText: texts?.['spaces.prompt.queueView.cancelBtnText'],
    popupViewNameErrorMessage: texts?.['spaces.prompt.queueView.popupViewNameErrorMessage'],
    uniqueNameErrorText: texts?.['spaces.prompt.queueView.uniqueNameErrorText'],
  };

  return componentTexts;
}
