import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchField from 'common/components/searchField';
import { StyledSearchFiled } from './style';
import useTexts from '../useTexts';
import { setSearchText, selectSearchText } from '../../slice';

export default function SearchText() {
  const dispatch = useDispatch();
  const queueSearchText = useSelector(selectSearchText);
  const [search, setSearch] = useState(queueSearchText);
  const { queueSearchTextPlaceholder } = useTexts();

  const handleSearch = () => {
    dispatch(setSearchText(search));
  };

  const handleReset = () => {
    setSearch('');
    dispatch(setSearchText(''));
  };

  return (
    <StyledSearchFiled>
      <SearchField
        className="queue-searchbox"
        placeholderText={queueSearchTextPlaceholder}
        handleSearch={handleSearch}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleSearch();
          }
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onClear={handleReset}
        id="queue-header-text"
        type="text"
        inputProps={{
          'data-testid': 'queue-header-search-input',
        }}
      />
    </StyledSearchFiled>
  );
}
