import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const tooltipStyle =
  () =>
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        background: theme?.palette?.grey?.tooltip_bg,
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '381px',
      textAlign: 'center',
      background: theme?.palette?.grey?.tooltip_bg,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
      padding: '12px',
      borderRadius: '10px',
      margin: 0,
    },
  });

export const StyledTooltip = styled(({ tooltipClassName, className, children, ...props }) => (
  <Tooltip {...props} data-testid="tooltip" className={tooltipClassName} classes={{ popper: className }} enterDelay={500} enterNextDelay={500}>
    <Box>{children}</Box>
  </Tooltip>
))(tooltipStyle());

export const TooltipWithEventListeners = styled(({ className, children, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const childWrapperRef = useRef(null);

  useEffect(() => {
    const mouseOverHandler = (event) => {
      if (!childWrapperRef.current) return;
      if (!childWrapperRef.current.contains(event.target)) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    };

    document.addEventListener('mouseover', mouseOverHandler, true);
    return () => document.removeEventListener('mouseover', mouseOverHandler);
  }, []);

  return (
    <Tooltip {...props} classes={{ popper: className }} open={showTooltip} enterDelay={500} enterNextDelay={500}>
      <Box ref={childWrapperRef}>{children}</Box>
    </Tooltip>
  );
})(tooltipStyle());

export const StyledTicketSummaryTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={500} enterNextDelay={500}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      backgroundColor: theme?.palette?.common?.white,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px 24px',
    maxWidth: '394px',
    fontFamily: theme.fonts.robotoRegular,
    backgroundColor: theme?.palette?.common?.white,
    borderRadius: theme.shape.borderRadius,
    filter: 'drop-shadow(0px 2px 8px rgba(118, 130, 150, 0.35))',
  },
}));
