import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@mui/material';
import { DropDownMenu } from 'common/components/dropDownMenu';
import { ReactComponent as ArrowDownIcon } from 'images/icons/chevron-down-black.svg';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';
import { ProgressWrapper } from 'containers/App/style';
import CircularProgress from 'common/components/circularProgress';
import { setToasterMessage } from 'store/globalSlice';

import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import {
  StyledButtons,
  StyledCancelBtn,
  StyledDropdownItem,
  StyledForm,
  StyledLinkBtn,
  StyledLinkItemProcess,
  StyledSubTitle,
} from './style';
import useTexts from '../hooks/useTexts';
import {
  useInitialData,
  useLinkSrRelatedAssetItem,
  useLinkSrRelatedCiItem,
  useLinkSrRelatedSrItem,
} from '../hooks/useRelatedItems';
import { DEFAULT_ITEM_TYPE, ITEM_TYPES, NEW_RELATIONSHIPS_IN_SPACES, PARENT_RELATIONSHIPS } from '../constants';
import RelationshipItemDropdowns from '../relationshipItemDropdowns';
import { InheritanceInfoPanel } from '../inheritanceInfoPanel';

const LinkItemProcess = ({
  relatedItemsToLink,
  handleChangeRelatedItems,
  handleFinishLinkProcess,
  srId,
  resetState,
  srRelatedItemsCount,
  srRelatedItems,
}) => {
  const [itemTypeMenuOpen, setItemTypeMenuOpen] = useState(false);
  const {
    componentTexts: { linkProcessSubTitle, cancelText, linkText, newRelationshipToasterMessage, moreInfoText },
    getItemTypeText,
  } = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const { data, isLoading } = useInitialData();
  const [selectedItemType, setSelectedItemType] = useState(null);
  const { mutateAsync: linkSrItems } = useLinkSrRelatedSrItem(srId);
  const { mutateAsync: linkAssetItems } = useLinkSrRelatedAssetItem(srId);
  const { mutateAsync: linkCiItems } = useLinkSrRelatedCiItem(srId);
  const dispatch = useDispatch();

  const { data: isRelatedAssetAndCiFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RELATED_ITEMS_SPACES_MVP_SR_TO_ASSET_AND_CI,
  });
  const { data: isProjectOneEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.PROJECT_ONE_ENABLED,
    defaultValue: false,
  });

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const srType = Object.values(data)?.find((type) => type.id === DEFAULT_ITEM_TYPE?.id);
      setSelectedItemType({
        id: srType?.id,
        key: srType?.key,
        name: getItemTypeText(srType?.key),
      });
    }
  }, [data, getItemTypeText]);

  const handleItemTypeChange = useCallback(
    async (type) => {
      if (selectedItemType?.id === type?.id) return;
      setSelectedItemType({ id: type?.id, key: type?.key, name: getItemTypeText(type?.key) });
      resetState();
      pendoTrackEvents(PENDO_TRACK_EVENTS.Choosing_entity_type_in_related_items_box, {
        entityId: type?.id,
        entityType: type?.name,
      });
      setItemTypeMenuOpen(false);
    },
    [getItemTypeText, pendoTrackEvents, resetState, selectedItemType?.id],
  );

  const handleOpenMenu = useCallback((val) => {
    setItemTypeMenuOpen(val);
  }, []);

  const handleCancelProcess = useCallback(() => {
    handleFinishLinkProcess();
    let linkedItemsCounts = {};
    if (selectedItemType?.id === ITEM_TYPES.SR.id) {
      linkedItemsCounts = relatedItemsToLink?.items?.reduce((acc, item) => {
        acc[item?.srTypeString] = (acc[item?.srTypeString] || 0) + 1;
        return acc;
      }, {});
    }
    linkedItemsCounts.totalLinkedItems = relatedItemsToLink?.items?.length;
    pendoTrackEvents(PENDO_TRACK_EVENTS.Cancel_new_related_item_link_click, { linkedItemsCounts });
  }, [handleFinishLinkProcess, pendoTrackEvents, relatedItemsToLink?.items, selectedItemType?.id]);

  const handleLinkItems = async () => {
    let linkedItemsCounts = {};
    const linkedRelationship = relatedItemsToLink?.relationship?.id;
    const updatedLinkedItems = relatedItemsToLink?.items?.map((item) => ({
      ...item,
      relationship: linkedRelationship,
    }));
    const linkedRelatedItems = {
      srId,
      relatedItems: updatedLinkedItems,
    };
    let status;
    switch (selectedItemType?.id) {
      case ITEM_TYPES.SR.id: {
        ({ status } = await linkSrItems({ linkedRelatedItems }));
        linkedItemsCounts = updatedLinkedItems?.reduce((acc, item) => {
          acc[item?.srTypeString] = (acc[item?.srTypeString] || 0) + 1;
          return acc;
        }, {});
        break;
      }
      case ITEM_TYPES.ASSET.id: {
        ({ status } = await linkAssetItems({ linkedRelatedItems }));
        break;
      }
      case ITEM_TYPES.CI.id: {
        ({ status } = await linkCiItems({ linkedRelatedItems }));
        break;
      }
      default:
        console.log('Invalid item type');
    }
    // new relationship toaster - check if project one customer, and if he uses a new relationship in spaces that wasn't used before
    const hasNewRelationshipInSpaces = [srRelatedItems?.serviceRequests, srRelatedItems?.assets, srRelatedItems?.cis]
      .flatMap((array) => array?.map((item) => item.relationship))
      ?.some((relationship) => NEW_RELATIONSHIPS_IN_SPACES.includes(relationship));
    const isLinkingNewRelationshipSucceeded =
      NEW_RELATIONSHIPS_IN_SPACES.includes(linkedRelationship) && status === 201;
    const showToasterForNewRelationship =
      !hasNewRelationshipInSpaces && isProjectOneEnabled && isLinkingNewRelationshipSucceeded;
    if (showToasterForNewRelationship) {
      dispatch(
        setToasterMessage({
          message: newRelationshipToasterMessage.replace(
            '__moreInfo__',
            `<a style="color: #3578DB; margin-left: 12px; cursor: pointer" onclick="window.open('https://documentation.sysaid.com/docs/service-record-related-items-in-spaces', '_blank')">${moreInfoText}</a>`,
          ),
          showFor: 15000,
          type: 'confetti',
          styleDialogOverrides: {
            '& .MuiPaper-root': {
              maxWidth: '575px!important',
            },
            '& .MuiDialogActions-root': {
              '> *': {
                margin: 0,
              },
            },
          },
        }),
      );
    }

    linkedItemsCounts.totalLinkedItems = updatedLinkedItems?.length;
    pendoTrackEvents(PENDO_TRACK_EVENTS.Link_new_related_item_click, { linkedItemsCounts });
    handleFinishLinkProcess();
  };

  return (
    <StyledLinkItemProcess>
      {isLoading ? (
        <ProgressWrapper sx={{ width: '100%', zIndex: 1, marginTop: '45px' }}>
          <CircularProgress />
        </ProgressWrapper>
      ) : (
        <>
          <StyledForm>
            <DropDownMenu
              text={selectedItemType?.name}
              icon={<ArrowDownIcon />}
              handleOpen={handleOpenMenu}
              open={itemTypeMenuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              minWidth={142}
            >
              {Object.values(data)
                ?.filter(
                  // show asset and ci only if FF is on
                  (itemType) =>
                    isRelatedAssetAndCiFFOn ||
                    (itemType.id !== ITEM_TYPES.ASSET.id && itemType.id !== ITEM_TYPES.CI.id),
                )
                ?.map((itemType) => (
                  <MenuItem
                    key={itemType.id}
                    onClick={() => handleItemTypeChange(itemType)}
                    data-testid={`entity-item-${itemType.id}`}
                  >
                    <StyledDropdownItem>{getItemTypeText(itemType.key)}</StyledDropdownItem>
                  </MenuItem>
                ))}
            </DropDownMenu>
            <StyledSubTitle>{linkProcessSubTitle}</StyledSubTitle>
            <RelationshipItemDropdowns
              data={data[selectedItemType?.key]}
              relatedItemsToLink={relatedItemsToLink}
              handleChange={handleChangeRelatedItems}
              srId={srId}
              itemType={selectedItemType?.id}
              srRelatedItemsCount={srRelatedItemsCount}
            />
            {PARENT_RELATIONSHIPS.includes(relatedItemsToLink?.relationship?.id) && <InheritanceInfoPanel />}
          </StyledForm>
          <StyledButtons>
            <StyledCancelBtn onClick={handleCancelProcess}>{cancelText}</StyledCancelBtn>
            <StyledLinkBtn
              data-cy="approve-link-btn"
              onClick={handleLinkItems}
              disabled={!relatedItemsToLink?.relationship || !relatedItemsToLink?.items?.length}
            >
              {linkText}
            </StyledLinkBtn>
          </StyledButtons>
        </>
      )}
    </StyledLinkItemProcess>
  );
};

export default LinkItemProcess;
