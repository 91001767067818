import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useTicketLockStatus, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import DataFilters from 'features/resolutionPanel/dataFilters';
import Footer from 'features/resolutionPanel/footer';
import { useTabs } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useTabs';
import { MIDDLE_PANEL_TAB_NAMES } from 'features/resolutionPanel/middlePanel/constants';
import { StyledTicketTabs, StyledTabContainer } from './style';

const TicketTabs = (props) => {
  const { children, showTicketAsTab, srType, scrollToAuditLogRecord, isArchived } = props;
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId);
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const { selectedTabName } = useTabs({ srId, showTicketAsTab });
  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
  const isJourneySelectedTab = selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY;
  const showFooter = !isTicketLocked && hasEditPermissions && isJourneySelectedTab;

  return (
    <StyledTicketTabs>
      <StyledTabContainer>
        <DataFilters srId={srId} srType={srType} showTicketAsTab={showTicketAsTab} />
        {children}
      </StyledTabContainer>
      {showFooter && (
        <Footer
          scrollToAuditLogRecord={scrollToAuditLogRecord}
          srId={srId}
          showTicketAsTab={showTicketAsTab}
          isArchived={isArchived}
        />
      )}
    </StyledTicketTabs>
  );
};

export default TicketTabs;
