import { useEffect, useState, useImperativeHandle, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash-es';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import useTexts from 'features/resolutionPanel/useTexts';
import { getProjects, getIssuesByProject, getUsers, createIssue } from 'services/jiraIntegration';
import { useTicketLockStatus, useUpdateSR } from 'remote-state/ticketServiceHooks';
import { lockSR } from 'services/ticketService';
import { selectActiveUser } from 'store/userSlice';
import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as ErrorSign } from 'images/icons/ErrorSign.svg';
import {
  jiraProjectField,
  jiraTypeField,
  jiraAssigneeField,
  jiraIssueField,
  jiraParentIssueField,
  jiraActionFieldValues,
  createJiraIssueFailuredMessage,
} from './jira.consts';
import { IntegrationFieldComponent } from '../IntegrationFieldComponent';
import { StyledFormFooter, FieldGroupWrapper, FieldsRowWrapper, StyledIntegrationFieldContainer } from '../../integrations.styles';
import { IntegrationFormRefContext } from './JiraIntegrationForm.context';

const JiraIntegrationForm = (props) => {
  // TODO move to redux
  const integrationFormRef = useContext(IntegrationFormRefContext);
  const { isExistingSR, isJiraLinked, actionField, parentFieldValue, onParentFieldChange, sr, issueKeyFieldName } = props;
  const { cancel, save } = useTexts();
  const { checkAndUpdateSr } = useUpdateSR();
  const { data: lockingDetails } = useTicketLockStatus(sr?.id);

  const [errorMessage, setErrorMessage] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [selectedFieldValues, setSelectedFieldValues] = useState({
    jiraProject: null,
    jiraType: null,
    jiraParentIssue: null,
    jiraAssignee: null,
    jiraIssue: null,
  });

  const [fieldOptionsLists, setFieldOptionsLists] = useState({
    jiraProject: [],
    jiraType: [],
    jiraParentIssue: [],
    jiraAssignee: [],
    jiraIssue: [],
  });

  const userAccount = useSelector(selectActiveUser);
  const showFormButtons = (!isJiraLinked && isExistingSR);

  const getJiraProjects = async () => {
    try {
      // TODO move to react query. Caching strategy?
      const projectsData = await getProjects();
      const projects = projectsData?.map(projectData => ({
        key: projectData.key,
        label: projectData.name,
        issueTypes: projectData.issueTypes,
      }));
      setFieldOptionsLists((prevOptions) => ({
        ...prevOptions,
        jiraProject: projects,
      }));
    } catch (error) {
      // TODO proper error handling
      console.log('Error fetching projects:', error);
    }
  };

  const getIssues = async (fieldOptionsToPopulate, key) => {
    try {
      // TODO move to react query. Caching strategy?
      const issuesData = await getIssuesByProject(key);
      const issues = issuesData.map(issueData => ({
        key: issueData.key,
        label: issueData.name,
      }));
      setFieldOptionsLists((prevOptions) => ({
        ...prevOptions,
        [fieldOptionsToPopulate]: issues,
      }));
    } catch (error) {
      // TODO proper error handling
      console.log('Error fetching issues:', error);
    }
  };

  const getAssignees = async (key) => {
    try {
      // TODO move to react query. Caching strategy?
      const usersData = await getUsers(key);
      const users = usersData.map(userData => ({
        key: userData.accountId,
        label: userData.displayName,
      }));
      setFieldOptionsLists((prevOptions) => ({
        ...prevOptions,
          jiraAssignee: users,
      }));
    } catch (error) {
      // TODO proper error handling
      console.log('Error fetching users from Jira:', error);
    }
  }

  useEffect(() => {
    if (Number(parentFieldValue) === jiraActionFieldValues.CREATE.id) {
      setFormFields([jiraProjectField, jiraTypeField, jiraParentIssueField, jiraAssigneeField]);
    } else if (Number(parentFieldValue) === jiraActionFieldValues.LINK.id) {
      setFormFields([jiraProjectField, jiraIssueField]);
    } else {
      setFormFields([]);
      return;
    }
    getJiraProjects();
  }, [parentFieldValue]);

  const handleFieldChange = (fieldName, value) => {
    setSelectedFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    const selectedProject = selectedFieldValues.jiraProject;
    if (!selectedProject) {
      return;
    }
    switch (Number(parentFieldValue)) {
      case jiraActionFieldValues.CREATE.id: {
        handleFieldChange(jiraParentIssueField.name, null);
        handleFieldChange(jiraTypeField.name, null);
        handleFieldChange(jiraAssigneeField.name, null);

        getIssues(jiraParentIssueField.name, selectedProject.key);
        getAssignees(selectedProject.key);
        const types = selectedProject.issueTypes.map((issueType) => ({
          key: Number(issueType.id),
          label: issueType.name,
        }));
        setFieldOptionsLists((prevOptions) => ({
          ...prevOptions,
          jiraType: types,
        }));
        break;
      }
      case jiraActionFieldValues.LINK.id:
        handleFieldChange(jiraIssueField.name, null);
        getIssues(jiraIssueField.name, selectedProject.key);
        break;
      default:
        setFormFields([]);
        break;
    }
  }, [parentFieldValue, selectedFieldValues.jiraProject]);

  useEffect(() => {
    setSaveDisabled(formFields.some(field =>
      field.required && !selectedFieldValues[field.name]
    ));
  }, [selectedFieldValues, formFields]);

  const handleCancel = async () => {
    if (sr.id) {
      await lockSR({ username: userAccount?.username, srId: sr.id });
    }
    await checkAndUpdateSr({
      requestParams: { [actionField.fieldName]: null },
      id: sr.id,
      queueUpdate: true,
    });
  };

  const createJiraIssue = async () => {
    const reqData = sr.id
      ? {
        srId: sr.id,
        projectKey: selectedFieldValues.jiraProject.key,
        issueTypeName: selectedFieldValues.jiraType.label,
        parentKey: selectedFieldValues.jiraParentIssue?.key,
        assigneeId: selectedFieldValues.jiraAssignee?.key,
      } : {
        projectKey: selectedFieldValues.jiraProject.key,
        issueTypeName: selectedFieldValues.jiraType.label,
        parentKey: selectedFieldValues.jiraParentIssue?.key,
        assigneeId: selectedFieldValues.jiraAssignee?.key,
        srProperties: {
          ...sr,
          requestUser: sr.requestUser?.userName,
        }
      }
    try {
      return await createIssue(reqData);
    } catch (error) {
      setErrorMessage(`${createJiraIssueFailuredMessage}:\n${error.message}`);
      // TODO proper error handling
      console.log('Error creating jira issue:', error);
      return null;
    }
  };

  const getJiraIssueKey = () =>
    (Number(parentFieldValue) === jiraActionFieldValues.CREATE.id) ? createJiraIssue() : selectedFieldValues.jiraIssue.key;

  const handleSave = async () => {
    // TODO add field validation before save
    const issueKey = await getJiraIssueKey();
    if (sr.id) {
      await lockSR({ username: userAccount?.username, srId: sr.id });
    }
    await checkAndUpdateSr({
      requestParams: { [issueKeyFieldName]: issueKey },
      id: sr.id,
      queueUpdate: true,
    });
  };

  const handleClosePrompt = () => {
    handleCancel();
    setErrorMessage(null);
  }

  // TODO move to redux
  useImperativeHandle(integrationFormRef, () => ({
    getJiraIssueKey,
  }));

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;

  return (
    <>
      <TemplateFieldsGrid
        {...props}
        isTicketLocked={isTicketLocked}
        fields={[actionField]}
        onFieldChange={onParentFieldChange}
        isJiraIntegrationSection
      />
      {!isEmpty(formFields) && (
        <FieldGroupWrapper key={actionField.id}>
          <Grid container spacing={2} justifyContent="space-between">
            {formFields.map((field) => (
              <FieldsRowWrapper
                halfRow
                key={field.name}
                id={`grid_item_${field.name}`}
              >
                <Box width="100%" data-ispropertiesfield="true">
                  <StyledIntegrationFieldContainer className="field-container" id={field.name}>
                    <IntegrationFieldComponent
                      className="field-wrapper"
                      field={field}
                      onChange={(value) => handleFieldChange(field.name, value)}
                      options={fieldOptionsLists[field.name]}
                    />
                  </StyledIntegrationFieldContainer>
                </Box>
              </FieldsRowWrapper>
            ))}
          </Grid>
          {showFormButtons && (
            <StyledFormFooter>
              <button className="cancel-btn" onClick={handleCancel} data-testid="jira-form-cancel-btn">
                {cancel}
              </button>
              <button className="ok-btn" onClick={handleSave} disabled={saveDisabled} data-testid="jira-form-save-btn">
                {save}
              </button>
            </StyledFormFooter>
          )}
        </FieldGroupWrapper>
      )}
      {errorMessage && (
        <MessagePrompt
          open
          onClose={handleClosePrompt}
          onOkClick={handleClosePrompt}
          showCancelBtn={false}
          // useTexts for next 2 lines
          title="Unable to create Jira issue"
          btnOkText="OK"
          Icon={ErrorSign}
        >
          {errorMessage}
        </MessagePrompt>
      )}
    </>
  );
};

export default JiraIntegrationForm;
