import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledSectionsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isArchived',
})(({ theme, isArchived }) => ({
  ...(isArchived && {
    position: 'relative',
    '&::before': {
      ...theme?.mixins?.archivedOverlay,
    },
  }),
}));
