import { useRbTexts } from 'remote-state/applicationHooks';

export default function useLisenceExpirationTexts() {
  const texts = useRbTexts();
  const seasonalTexts = {
    fallText: texts?.['spaces.license.expiration.fall.firstLine'] || 'Fall into renewal season',
    summerText:
      texts?.['spaces.license.expiration.summer.firstLine'] || "Don't let your license take a summer vacation!",
    springText: texts?.['spaces.license.expiration.spring.firstLine'] || "Spring into action - it's renewal time!",
    winterText: texts?.['spaces.license.expiration.winter.firstLine'] || "'Tis the season to renew - fa la la la la!",
    title: texts?.['spaces.license.expiration.title'] || 'Your SysAid license has  expired',
    thirdLineStart: texts?.['spaces.license.expiration.thirdLineStart'] || 'Renew now by emailing us at',
    link: texts?.['spaces.license.expiration.link'] || 'info@sysaid.com',
    phoneNumber: texts?.['spaces.license.expiration.phoneNumber'] || 'or call (617) 213-0124',
  };

  return seasonalTexts;
}
