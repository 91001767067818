import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledFieldHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '4px 0',
  maxHeight: '92px',
  height: '16px',
}));

export const StyledFieldLabel = styled('span')(({ theme }) => ({
  fontSize: theme.fontSize.small,
  color: theme.palette.text.secondary_txt,
}));

export const MultiSelectFieldWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isDropdownOpen', 'error', 'isSrPage', 'disabled', 'readOnly', 'skipValidation', 'isEmptyField', 'isNewField', 'isReadOnly', 'isQueue', 'isFloatingTicketPanel'].includes(prop),
})(({ theme, isDropdownOpen, error, isSrPage, disabled, readOnly, skipValidation, isEmptyField, isNewField, isReadOnly, isQueue, isFloatingTicketPanel }) => {
  const colors = {
    txt_default: theme?.palette?.text?.txt_default,
    secondary_txt: theme?.palette?.text?.secondary_txt,
    chip_grey: theme?.palette?.grey?.chip_grey,
    divider_grey_v1: theme?.palette?.grey?.divider_grey_v1,
    divider_grey_v2: theme?.palette?.grey?.divider_grey_v2,
    bg_hover_main: theme?.palette?.background?.bg_hover_main,
    bt_alt_grey_light: theme?.palette?.grey?.bt_alt_grey_light,
    bg_new_input_text: theme?.palette?.background?.bg_new_input_text,
    bt_blue_field_empty: theme?.palette?.blueLabels?.bt_blue_field_empty,
    bt_blue_field_empty_hover: theme?.palette?.blueLabels?.bt_blue_field_empty_hover,
    bt_status_reg: theme?.palette?.blueLabels?.bt_status_reg,
    bt_blue_chip_reg: theme?.palette?.blueLabels?.bt_blue_chip_reg,
    bt_blue_chip_light: theme?.palette?.blueLabels?.bt_blue_chip_light,
    bt_red_light_main: theme?.palette?.bt_red_light?.main,
    bt_red_reg: theme?.palette?.labels?.bt_red_reg,
    bt_red_mid: theme?.palette?.labels?.bt_red_mid,
    bt_red_dark: theme?.palette?.labels?.bt_red_dark,
    transparent: 'transparent',
    white: theme?.palette?.generic?.primary,
  };

  const ticketPageStyling = (!isQueue || isFloatingTicketPanel) && !disabled && isEmptyField;

  let placeholderColor = ticketPageStyling
    ? colors.bt_blue_chip_reg 
    : colors.txt_default;

  let background = ticketPageStyling 
    ? colors.bt_blue_field_empty 
    : colors.transparent;

  let backgroundHover = ticketPageStyling 
    ? colors.bt_blue_field_empty_hover 
    : colors.bg_hover_main;

  const borderDefaultColor = !disabled && isEmptyField 
    ? colors.bt_blue_field_empty_hover
    : colors.divider_grey_v1;

  let borderColor = error 
    ? colors.bt_red_reg 
    : borderDefaultColor;

  if (!isQueue && disabled) {
    backgroundHover = colors.bt_alt_grey_light;
    background = colors.bt_alt_grey_light;
    borderColor = colors.divider_grey_v1;
    placeholderColor = colors.secondary_txt;
  }

  if (!isFloatingTicketPanel && isQueue && isEmptyField) {
    backgroundHover = colors.bt_blue_field_empty_hover;
    background = colors.bt_blue_field_empty;
    borderColor = colors.bt_blue_field_empty;
    placeholderColor = colors.bt_blue_chip_reg;
  }

  if (isQueue && disabled) {
    backgroundHover = colors.transparent;
    background = colors.transparent;
    borderColor = colors.transparent;
    placeholderColor = colors.disabled_txt;
  }

  if (isNewField && isEmptyField && !disabled) {
    background = colors.bg_new_input_text;
    backgroundHover = colors.bg_hover_main;
  }

  if (error) {
    background = colors.bt_red_mid;
    backgroundHover = colors.bt_red_mid;
  }

  if (isReadOnly) {
    backgroundHover = colors.white;
    background = colors.white;
    placeholderColor = colors.chip_grey;
    borderColor = colors.divider_grey_v2;
  }

  if (isDropdownOpen) {
    background = colors.bg_hover_main;
  } else if (!isDropdownOpen && error && !skipValidation) {
    background = colors.bt_red_light_main;
    placeholderColor = `${colors.bt_red_reg} !important`;
  }

  return {
    backgroundColor: `${background} !important`,
    position: 'relative',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    border: `1px solid ${borderColor}`,
    zIndex: 111,
    '& .empty-multi-select-chip-placeholder': {
      color: placeholderColor,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& span[data-error-tooltip=true], .warning-icon': isDropdownOpen && {
      display: 'none',
    },
    '&:hover': !isDropdownOpen &&
      !disabled &&
      !readOnly && {
        backgroundColor: `${backgroundHover} !important`,
        ...(!isFloatingTicketPanel && { borderColor: colors.bt_blue_chip_light, }),
        // TODO remove this when onClick on whole multi select field is fixed (SR page)
        '& span[data-error-tooltip=true], .warning-icon': isSrPage && {
          display: 'none',
        },
        '& .warning-icon *': {
          fill: colors.bt_red_dark,
        },
        '& .empty-multi-select-chip-placeholder': {
          color: colors.bt_status_reg,
        }
      },
  };
});

export const StyledMultiSelectField = styled('div')(({ theme }) => ({
  height: '100%',
  padding: '5px 4px 5px 0',
  border: '1px solid',
  borderColor: theme?.palette?.grey?.divider_grey_v1,
  borderRadius: '4px',
}));

export const StyledListWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'listHeight' && prop !== 'isDropdownOpen' && prop !== 'noPadding' && prop !== 'skipValidation',
})(({ theme, listHeight, isDropdownOpen, noWrap, noPadding, skipValidation }) => {
  const overflowY = listHeight < 72 ? 'hidden' : 'auto';
  return {
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#FFFFFF',
    flexDirection: 'row !important',
    listStyle: 'none',
    padding: isDropdownOpen ? '0 28px 0 8px !important' : '0 28px 0 8px',
    height: 'auto',
    width: '100%',
    minHeight: '22px',
    maxHeight: '88px',
    rowGap: '8px',
    columnGap: '8px',
    overflowY,
    '.MuiButtonBase-root': {
      width: 'auto !important',
    },
    ...theme.customScrollbar,
    ...(noWrap && {
      flexWrap: 'nowrap!important',
      overflow: 'hidden!important',
      whiteSpace: 'nowrap!important',
    }),
    ...(noPadding && {
      padding: 0,
    }),
    ...(skipValidation && {
      paddingRight: '8px',
    }),
  };
});

export const StyledMultiSelectLabel = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'isQueue' && prop !== 'notInTemplate',
})(({ theme, isError, isQueue, notInTemplate }) => {
  let color = theme?.palette?.text?.title_txt;
  if (isError) {
    color = 'blue';
  }
  if (notInTemplate) {
    color = theme?.palette?.text?.disabled;
  }
  return {
    ...(!isQueue && {
      position: 'absolute',
    }),
    fontSize: theme?.fontSize?.main,
    color,
  };
});

export const StyledPlusWrapper = styled('span')(({ listHeight, disabled, readOnly }) => {
  const right = listHeight >= 98 ? '12px' : '8px';
  const pointerType = disabled || readOnly ? 'not-allowed' : 'pointer';
  return {
    position: 'absolute',
    cursor: pointerType,
    height: '20px',
    right,
    top: '7px',
    '& .warning-icon': {
      marginRight: 0,
    },
  };
});

export const StyledDropDownWrapper = styled('div')(() => ({
  width: '100%',
  '& > div': {
    width: '100%',
    '& > div:first-of-type': {
      width: 'auto',
      '& > div:first-of-type': {
        width: '100%',
      },
    },
    '& > div:last-of-type(3)': {
      maxHeight: '246px !important',
      overflow: 'hidden !important',
      minHeight: '48px !important',
      '& > div:first-of-type': {
        maxHeight: '246px !important',
      },
    },
    '.MuiButtonBase-root': {
      '.MuiListItemText-root': {
        width: '91% !important',
        '.MuiTypography-root': {
          width: '100% !important',
          maxWidth: 'none !important',
        },
      },
    },
  },
}));

export const StyledMultiSelectFieldInner = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .warning-icon': {
    marginRight: '6px',
  },
}));
