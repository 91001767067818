import { useEffect, useState, useImperativeHandle, forwardRef, useCallback, useRef, useMemo } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, debounce } from 'lodash-es';
import { getFieldAttributes, getStatusListByValueClasses } from 'services/ticketService';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import {
  COLUMN_FILTER_ASSIGNEE,
  COLUMN_FILTER_CATEGORY,
  COLUMN_FILTER_FIELDTYPE,
  COLUMN_FILTER_STATUS,
  COLUMN_FILTER_TYPES,
  FIELD_TYPES,
  STATUS_FIELD_ID,
} from 'features/queue/grid/constants';
import { AssigneeFilter } from 'features/queue/gridFilters/Assignees/assignee';
import { getValueClassesNames } from 'features/queue/utils';
import { QUEUE } from 'features/queue/constants';
import useDateTimeInfo from 'common/utils/hooks/useDateTimeInfo';
import { convertFromClientFieldId } from 'common/utils/fieldUtils';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { createPerformanceMarkWithData } from 'common/utils/performanceManager';
import useTexts from './useTitleText';
import { DropdownSelect, itemClassNames } from '../dropdownSelect';
import {
  addColumnFilter,
  selectFilterModel,
  removeColumnFilter,
  gridConfigurationChanged,
  selectIsQuickFilterApplied,
} from '../../../features/queue/slice';
import { useDateSelectOptions, getByTitleDateRange } from './hooks';
import { FilterByNumber } from './NumberFilter/FilterByNumber';
import { FilterByText } from './TextFilter/FilterByText';
import { CONSTANTS } from '../datePicker/constants';

const ForwardRefFilter = forwardRef((props, ref) => {
  // ---
  //TODO: Component needs to be cleaned up, should be using the built-in field types rather than the filterConf
  const { filterChangedCallback, colDef, filterConf, handleClosePopup } = props;
  const [filter, setFilter] = useState([]);
  const [filterType, setFilterType] = useState(filterConf?.filterType);
  const [filterOption, setFilterOption] = useState(filterConf?.type);
  const [redrawFilter, setRedrawFilter] = useState(0);
  const hidePopup = useRef(null);
  const dispatch = useDispatch();

  const filterModel = useSelector(selectFilterModel);
  const isQuickFilterApplied = useSelector(selectIsQuickFilterApplied);
  const selectedFilter = filterModel.find((qf) => qf.field === colDef.field);
  const hasHierarchy = colDef.field === COLUMN_FILTER_STATUS;
  const [selection, setSelection] = useState(
    hasHierarchy
      ? { values: selectedFilter?.values || [], valueClasses: selectedFilter?.valueClasses || [] }
      : selectedFilter?.values || [],
  );
  const [isClickedAway, setIsClickedAway] = useState(false);
  const { searchPlaceholderText, categoryNoValue, getColumnText, today, yesterday, thisWeek, thisMonth, last30Days } = useTexts()
  const fieldAttributesInput = useMemo(
    () => [{ fieldId: colDef?.fieldId, customColumn: colDef?.customColumn }],
    [colDef?.fieldId, colDef?.customColumn],
  );
  const data = useFieldAttributes(fieldAttributesInput, true)?.[0]?.data?.values;
  const { dates } = useDateSelectOptions();
  const { timezone, dateFormat } = useDateTimeInfo();

  const selectedField = selectedFilter?.field;
  const isSelectDateType =
    colDef.fieldTypeName === 'DATE' ||
    colDef.fieldTypeName === 'TIME_REMAINING' ||
    colDef.fieldTypeName === 'DATEANDTIME';

  const getDisplayedFormat = useCallback(() => {
    switch (dateFormat) {
      case CONSTANTS.BACKEND_AMERICAN_DATE_TIME_FORMAT:
      case CONSTANTS.AMERICAN_DATE_FORMAT:
        return CONSTANTS.AMERICAN_DATE_FORMAT;
      case CONSTANTS.BACKEND_EUROPIAN_DATE_TIME_FORMAT:
      case CONSTANTS.EUROPIAN_DATE_FORMAT:
        return CONSTANTS.EUROPIAN_DATE_FORMAT;
      default:
        return CONSTANTS.AMERICAN_DATE_FORMAT;
    }
  }, [dateFormat]);

  useEffect(() => {
    setSelection(
      hasHierarchy
        ? { values: selectedFilter?.values || [], valueClasses: selectedFilter?.valueClasses || [] }
        : selectedFilter?.values || [],
    );
  }, [selectedFilter, hasHierarchy]);

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return filter && filter.length !== 0;
    },
    doesFilterPass() {
      return true;
    },

    getModel() {
      if (filter && filter.length !== 0) {
        return { filter, filterType, type: filterOption };
      }
      return null;
    },
    setModel(model) {
      const localFilter = model?.values || [];

      if (localFilter.length !== 0 || filter.length !== 0) {
        setFilter(localFilter);
        setFilterType(model?.fieldType);
        setFilterOption(model?.type);
        filterChangedCallback();
      }
    },

    onNewRowsLoaded() {},

    onAnyFilterChanged() {},

    getModelAsString() {
      return filter.join(',');
    },

    afterGuiAttached(gridApi) {
      setRedrawFilter(redrawFilter + 1);
      hidePopup.current = gridApi.hidePopup;
    },
  }));

  const isCategory = Object.keys(COLUMN_FILTER_CATEGORY).includes(colDef.field);

  const getDisplayKeys = useCallback(
    (selectedItems) =>
      selectedItems
        .map((item) => item?.[colDef.captionString] || (isCategory ? categoryNoValue : undefined))
        .filter((displayKey) => displayKey)
        .join(', '),
    [colDef.captionString, isCategory, categoryNoValue],
  );

  const parseSelectValues = useCallback(
    (selectedValue, optionsMap) => {
      let type = filterConf?.type;
      let fieldType = filterConf?.filterType;
      let values;
      let displayKeys;
      if (isSelectDateType) {
        fieldType = COLUMN_FILTER_FIELDTYPE.date;
        if (isArray(selectedValue)) {
          values = selectedValue;
          const formattedStartDate = moment.tz(selectedValue[0], timezone).format(getDisplayedFormat());
          const formattedEndDate = moment.tz(selectedValue[1], timezone).format(getDisplayedFormat());
          if (selectedValue.length === 1) {
            displayKeys = [`${formattedStartDate}`];
          } else {
            type = COLUMN_FILTER_TYPES.inRange;
            displayKeys = [`from ${formattedStartDate} to ${formattedEndDate}`];
          }
        } else if (Number(selectedValue[colDef.keyString]) < dates.length + 1) {
          type = COLUMN_FILTER_TYPES.dynamicTime;
          values = [selectedValue.values];
          displayKeys = [selectedValue.valueCaption];
        } else {
          const dateRange = {today, yesterday, thisWeek, thisMonth, last30Days};
          const selectedDate = getByTitleDateRange(selectedValue,  dateRange);
          values = [selectedDate.startDate, selectedDate.endDate];
          displayKeys = [selectedDate.valueCaption];
          type = COLUMN_FILTER_TYPES.inRange;
        }
      } else if (hasHierarchy) {
        values = selectedValue.values;
        const selectedItems = values.map((value) => optionsMap[value]).filter((value) => !!value);
        displayKeys = [getDisplayKeys(selectedItems)];
      } else {
        values = isArray(selectedValue) ? selectedValue : [selectedValue];
        const selectedItems = isArray(selectedValue)
          ? selectedValue.map((value) => optionsMap[value])
          : [selectedValue];
        displayKeys = isArray(selectedValue) ? [getDisplayKeys(selectedItems)] : [selectedValue];
      }
      return {
        type,
        values,
        displayKeys,
        fieldType,
      };
    },
    [filterConf?.type, filterConf?.filterType, isSelectDateType, hasHierarchy, colDef.keyString, dates.length, timezone, getDisplayedFormat, today, yesterday, thisWeek, thisMonth, last30Days, getDisplayKeys],
  );

  const handleSelectionChange = useCallback(
    (selectedValue, optionsMap) => {
      const { type, values, displayKeys, fieldType } = parseSelectValues(selectedValue, optionsMap);
      createPerformanceMarkWithData(PERFORMANCE_MEASURE.QUEUE.FILTER_CHANGE, {
        fieldName: selectedField || colDef.field,
      });
      if (values.length > 0 || selectedValue.valueClasses?.length > 0) {
        const filter = {
          field: selectedField || colDef.field,
          fieldId: colDef?.fieldId,
          values,
          name: colDef?.headerName,
          type,
          fieldType: { typeName: fieldType, id: colDef?.fieldTypeId },
          displayKeys,
          customColumn: colDef?.customColumn,
        };
        if (hasHierarchy) {
          filter.valueClasses = selectedValue.valueClasses;
        }
        dispatch(addColumnFilter({ filter, field: colDef.field }));
        if (!colDef?.captionString) {
          hidePopup.current?.();
        }
      } else {
        dispatch(removeColumnFilter({ field: selectedField || colDef.field }));
      }
    },
    [hasHierarchy, dispatch, selectedField, colDef, hidePopup, parseSelectValues],
  );

  const handleClean = useCallback(() => {
    dispatch(
      gridConfigurationChanged({
        quickFilter: QUEUE.deselectedQuickFilter,
      }),
    );
    dispatch(
      removeColumnFilter({
        field: colDef.field,
      }),
    );
  }, [colDef.field, dispatch]);

  const clickAwayListener = useCallback(() => {
    if (handleClosePopup) {
      handleClosePopup();
    }
    setIsClickedAway(true);
  }, [handleClosePopup]);

  const clickedAwayTimeout = useRef();

  useEffect(() => {
    if (isClickedAway) {
      clickedAwayTimeout.current = setTimeout(() => {
        setIsClickedAway(false);
      }, 500);
    }
  }, [isClickedAway]);

  useEffect(() => () => clearTimeout(clickedAwayTimeout.current), []);

  const createFilterObject = useCallback(
    (value) => ({
      filter: {
        fieldId: colDef?.fieldId,
        field: colDef.field,
        values: [value.value],
        name: colDef.field === 'id' ? colDef.field : colDef.headerName,
        type: value.filterType || filterOption,
        fieldType: { id: 1, typeName: filterConf?.filterType },
        displayKeys: [value.value],
        customColumn: colDef.customColumn,
      },
      field: colDef.field,
    }),
    [colDef, filterConf, filterOption],
  );

  const handleSearch = useMemo(
    () =>
      debounce((event) => {
        if ([FIELD_TYPES[1], FIELD_TYPES[3]].includes(colDef.fieldTypeName) && !event.target.value) {
          handleClean();
          return;
        }
        dispatch(addColumnFilter(createFilterObject(event.target)));
      }, 2000),
    [colDef.fieldTypeName, handleClean, dispatch, createFilterObject],
  );

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        colDef.fieldId === STATUS_FIELD_ID
          ? getStatusListByValueClasses({ query })
          : getFieldAttributes(convertFromClientFieldId(colDef.fieldId, colDef.customColumn), {
              query,
              customColumn: colDef.customColumn,
            }),
    }),
    [colDef.fieldId, colDef.customColumn],
  );

  if (isQuickFilterApplied && colDef.field === 'srType') {
    colDef.sortable = false;
    return <></>;
  }

  const getStylingVariant = () => {
    if (Object.keys(COLUMN_FILTER_CATEGORY).includes(colDef.field)) {
      return itemClassNames.mainCategory;
    }
    return colDef.field;
  };

  switch (filterConf?.filterType) {
    case 'set':
      if (colDef.field === COLUMN_FILTER_ASSIGNEE) {
        return <AssigneeFilter disablePopover isGridFilter isQueueTableFilter />;
      }

      return (
        <ClickAwayListener onClickAway={clickAwayListener}>
          <div>
            <DropdownSelect
              isClickedAway={isClickedAway}
              isCategory={isCategory}
              options={isSelectDateType ? dates : data}
              handleChange={handleSelectionChange}
              handleClean={handleClean}
              stylingVariant={getStylingVariant()}
              isMultiple={!isSelectDateType}
              hasHierarchy={hasHierarchy}
              isWideDropdown={hasHierarchy}
              selection={selection}
              isDateType={isSelectDateType}
              fieldName={colDef.field}
              keyString="valueKeyForFilter"
              captionString={colDef.captionString}
              sortBy={colDef.sortBy}
              backendQueryConfig={backendQueryConfig}
              valueClassesNames={getValueClassesNames(colDef.field, getColumnText)}
            />
          </div>
        </ClickAwayListener>
      );
    case 'text':
      return (
        <FilterByText
          selectedFilter={selectedFilter}
          searchPlaceholderText={searchPlaceholderText}
          handleSearch={handleSearch}
          clearSearch={handleClean}
        />
      );
    case 'number':
      return <FilterByNumber selectedFilter={selectedFilter} handleSearch={handleSearch} />;
    default:
      return <></>;
  }
});

export default ForwardRefFilter;
