import useTexts from 'features/resolutionPanel/useTexts';
import OpenWindowLink from 'common/components/link/openWindowLink';
import { ReactComponent as ExternalLinkIcon } from 'images/icons/external-link.svg';
import { updateSrcDomain } from 'common/components/controls/RichTextEditor/utils';

import {
  StyledAuditLogNotification,
  StyleTitle,
  StyleSubTitle,
  StyleSubject,
  StyleExternalLink,
  ExternalLinkText,
} from './style';

export const Notification = ({ logInformation }) => {
  const { from, to, subject, link } = logInformation;
  const { sender, recipient, subject: subjectTitle, notificationView } = useTexts();

  const notificationUrl = updateSrcDomain(link);

  return (
    <StyledAuditLogNotification>
      <StyleTitle>
        <StyleSubTitle data-testid="audit-log-from">{sender}</StyleSubTitle>
        {`: ${from}`}
      </StyleTitle>
      <StyleTitle>
        <StyleSubTitle data-testid="audit-log-to">{recipient}</StyleSubTitle>
        {`: ${to}`}
      </StyleTitle>
      <StyleSubject>
        <StyleSubTitle data-testid="audit-log-subject">{subjectTitle}</StyleSubTitle>
        {`: ${subject}`}
      </StyleSubject>
      <OpenWindowLink url={notificationUrl}>
        <StyleExternalLink>
          <ExternalLinkIcon />
          <ExternalLinkText>{notificationView}</ExternalLinkText>
        </StyleExternalLink>
      </OpenWindowLink>
    </StyledAuditLogNotification>
  );
};
