import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    incident: texts?.['spaces.quickFilters.incident'],
    request: texts?.['leftmenu_sr.requests'],
    problem: texts?.['leftmenu_sr.problems'],
    change: texts?.['leftmenu_sr.rfc'],
    allText: texts?.['spaces.quickFilters.all'],
  };

  return componentTexts;
}
