import { memo, useMemo } from 'react';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import DueDateField from 'common/components/dueDate';
import DatePicker from 'common/components/datePicker';
import Tooltip from 'common/components/tooltip';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useTheme } from '@mui/styles';
import { RICH_TEXT_FIELD_IDS } from 'features/srPanel/consts';
import BooleanField from 'features/srPanel/templateFieldsGrid/booleanField';
import { Timer } from 'common/components/timer';
import { FIELD_TYPE } from 'common/components/grid/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import useShowNoEditPermissionPopup from 'common/utils/hooks/useShowNoEditPermissionPopup';
import { TO_BE_DETERMINED } from 'features/header/categories/constants';
import { QUERIES_KEYS } from 'constant';
import * as PropTypes from 'prop-types';
import { SelectRender } from '../SelectRender';
import { AssigneeRender } from '../AssigneeRender';
import { DROP_DOWN_DELAY, COLUMN_CELL_TYPES } from '../../constants';
import { CategoryRender } from '../CategoryRender/CategoryRender';
import { RichTextRender } from '../RichTextRender';
import OpenTicketCell from '../OpenTicket';
import IdFieldRender from '../IdFieldRender';
import useTexts from '../../useTexts';
import { TextAreaRender } from '../TextAreaRender';
import { CellComponentWrapper } from './styles';

TextAreaRender.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  fieldValue: PropTypes.string,
};
const CellComponent = memo((props) => {
  const {
    fieldTypeName,
    column,
    srId,
    activeField,
    hoverEffectText,
    handleFieldChange,
    handleInlineFieldChange,
    disableField,
    isNoEditPermission,
    notInTemplate,
    rowIndex,
    username,
    checkAvailableTicket,
    hoverEffectPointer,
    data,
    required,
    srStatusValue,
    gridApi,
  } = props;
  const theme = useTheme();
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI);
  const { mandatoryFieldError, notExistInTemplateMessage } = useTexts();
  const { showNoPermissionPopup } = useShowNoEditPermissionPopup();
  const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
    defaultValue: false,
  });
  const enableAiSummary = isAiEnabled && (assistiveAI?.AICaseSummarizations || assistiveAI?.AIEmotions);
  const selectRenderStyle = useMemo(
    () => ({
      fontSize: theme.fontSize.main,
      color: theme.palette.text.txt_default,
      minWidth: 90,
      minHeight: 24,
    }),
    [theme],
  );

  const categoryRenderStyle = useMemo(
    () => ({
      fontSize: theme.fontSize.main,
      lineHeight: 1.429,
      color: theme.palette.text.txt_default,
    }),
    [theme],
  );

  const richTextFieldValue = useMemo(
    () => ({
      jsonContent: activeField?.jsonContent ? JSON.parse(activeField.jsonContent) : null,
      plainTextContent: activeField?.plainTextContent || null,
    }),
    [activeField],
  );

  const field = useMemo(
    () => ({
      fieldName: column?.colId,
      fieldId: column.colDef.fieldId,
      templateFieldAttributes: {
        ticketTemplateFieldType: {
          id: column.colDef.fieldTypeId,
        },
      },
    }),
    [column],
  );

  const getCellComponent = (fieldType) => {
    let multiline;

    switch (fieldType) {
      case COLUMN_CELL_TYPES.BOOLEAN:
        return (
          <BooleanField
            field={field}
            required={required}
            fieldValue={activeField?.value}
            disabled={disableField}
            onChange={handleFieldChange}
            isTableView
          />
        );
      case COLUMN_CELL_TYPES.TEXT:
        multiline = false;
        return (
          <InlineFieldContainer
            fullwidth
            limit={250}
            delayedHover={500}
            required={required}
            multiline={multiline}
            disabled={disableField}
            fieldName={column.colId}
            hoverEffect={hoverEffectText}
            tooltipClassName="inline-field-tooltip"
            handleSaveValue={handleInlineFieldChange}
            showSummaryTooltip={column.colId === 'title' && enableAiSummary}
            text={typeof activeField?.value === 'object' ? activeField?.value?.title : activeField?.value}
            disableTooltip
            isNoEditPermission={isNoEditPermission}
            {...(isNoEditPermission ? { onClick: showNoPermissionPopup } : {})}
          />
        );
      case COLUMN_CELL_TYPES.LONGTEXT:
        multiline = true;
        if (RICH_TEXT_FIELD_IDS.includes(column.colDef.fieldId)) {
          return (
            <RichTextRender
              srId={srId}
              required={required}
              className="isQueuePage"
              disabled={disableField}
              fieldName={column.colId}
              fieldId={column.colDef.fieldId}
              dataTestId="sr-description-editor"
              handleSaveValue={handleInlineFieldChange}
              fieldValue={richTextFieldValue}
              notInTemplate={notInTemplate}
              onDisabledClick={showNoPermissionPopup}
              disableTooltip
              isNoEditPermission={isNoEditPermission}
            />
          );
        }
        return (
          <TextAreaRender
            fullwidth
            delayedHover={500}
            limit={5000}
            required={required}
            disabled={disableField}
            fieldName={column.colId}
            fieldId={column.colDef.fieldId}
            hoverEffect={hoverEffectText}
            multiline={multiline}
            handleSaveValue={handleInlineFieldChange}
            text={activeField?.value}
            notInTemplate={notInTemplate}
            disableTooltip
          />
        );
      case COLUMN_CELL_TYPES.NUMBER:
        multiline = false;
        if (column.colDef.field === 'id') {
          return <IdFieldRender srId={srId} />;
        }
        return (
          <InlineFieldContainer
            fullwidth
            limit={250}
            type="number"
            delayedHover={500}
            required={required}
            multiline={multiline}
            disabled={disableField}
            fieldName={column.colId}
            text={activeField?.value}
            hoverEffect={hoverEffectText}
            handleSaveValue={handleInlineFieldChange}
            notInTemplate={notInTemplate}
          />
        );
      case COLUMN_CELL_TYPES.DATE:
      case COLUMN_CELL_TYPES.DATEANDTIME:
        return (
          <DatePicker
            fullwidth
            delayedHover={500}
            required={required}
            disablePortal={false}
            disabled={disableField}
            fieldType={column.colDef.fieldTypeId}
            fieldName={column.colId}
            date={activeField?.value}
            onChange={handleFieldChange}
            hoverEffect={hoverEffectPointer}
            emptyValueTooltip={mandatoryFieldError}
            notInTemplate={notInTemplate}
            isSROpenedFromQueue={isSROpenedFromQueue}
          />
        );
      case COLUMN_CELL_TYPES.TIMER:
        return <Timer disabled={disableField} milliseconds={activeField?.value} />;
      case COLUMN_CELL_TYPES.TIME_REMAINING: {
        const { endDate, startDate } = data.reduce(
          (result, fields) => {
            if (fields.field === 'dueDate') {
              result.endDate = fields;
            }
            if (fields.field === 'insertTime') {
              result.startDate = fields;
            }

            return result;
          },
          { endDate: undefined, startDate: undefined },
        );

        return (
          <DueDateField
            srId={srId}
            delayedHover={500}
            required={required}
            disabled={disableField}
            notInTemplate={notInTemplate}
            delay={DROP_DOWN_DELAY}
            endDate={endDate?.value}
            fieldName={column?.colId}
            startDate={startDate?.value}
            srStatusValue={srStatusValue}
            onSaveDate={handleFieldChange}
            hoverEffect={hoverEffectPointer}
            emptyValueTooltip={mandatoryFieldError}
            templateNotFoundMessage={notExistInTemplateMessage}
          />
        );
      }
      case COLUMN_CELL_TYPES.SELECT:
      case COLUMN_CELL_TYPES.MULTISELECT: {
        const srType = data?.length > 0 ? data?.find((fields) => fields.field === 'srType')?.value : undefined;

        if (
          column.colDef.field === 'primaryCategory' ||
          column.colDef.field === 'secondaryCategory' ||
          column.colDef.field === 'thirdLevelCategory'
        ) {
          const category =
            data?.length > 0 && activeField?.categoryCaption !== TO_BE_DETERMINED
              ? data?.find((fields) => fields.field === props.column?.colId)
              : undefined;

          return (
            <CategoryRender
              srId={srId}
              srType={srType}
              delayedHover={500}
              rowData={category}
              required={required}
              disabled={disableField}
              isNoEditPermission={isNoEditPermission}
              delay={DROP_DOWN_DELAY}
              value={activeField?.value}
              fieldId={column.colDef.fieldId}
              hoverEffect={hoverEffectPointer}
              headerName={column.colDef.headerName}
              listKey={column.colDef.filterParams.listKey}
              style={categoryRenderStyle}
              rowIndex={rowIndex}
              categoryCaption={category?.categoryCaption}
            />
          );
        }
        if (column.colDef.field !== 'assignee') {
          return (
            <SelectRender
              srId={srId}
              srType={srType}
              delayedHover={500}
              required={required}
              delay={DROP_DOWN_DELAY}
              disabled={disableField}
              isNoEditPermission={isNoEditPermission}
              hoverEffect={hoverEffectPointer}
              fieldId={column?.colDef?.fieldId}
              customColumn={column?.colDef?.customColumn}
              fieldName={column?.colDef?.field}
              headerName={column.colDef.headerName}
              keyString={column.colDef.keyString}
              captionString={column.colDef.captionString}
              isMultiple={fieldTypeName === COLUMN_CELL_TYPES.MULTISELECT}
              value={activeField?.value}
              style={selectRenderStyle}
              sortBy={column.colDef.sortBy}
              rowIndex={rowIndex}
              notInTemplate={notInTemplate}
            />
          );
        }
        return (
          <AssigneeRender
            srId={srId}
            delayedHover={500}
            required={required}
            disabled={disableField}
            hoverEffect={hoverEffectPointer}
            initialAssigned={activeField?.value}
            rowIndex={rowIndex}
          />
        );
      }
      case COLUMN_CELL_TYPES.OPEN_LINK:
        return (
          <OpenTicketCell
            srId={srId}
            activeField={activeField}
            rowIndex={rowIndex}
            username={username}
            checkAvailableTicket={checkAvailableTicket}
            gridApi={gridApi}
          />
        );
      default:
        return (
          <Tooltip
            isTruncatedText
            placement="bottom"
            text={activeField?.value}
            style={{
              fontSize: theme.fontSize.main,
              lineHeight: 1.429,
              color: theme.palette.text.txt_default,
            }}
          />
        );
    }
  };
  const isSelector = [COLUMN_CELL_TYPES.SELECT, COLUMN_CELL_TYPES.MULTISELECT].includes(fieldTypeName);
  const isFullWidth =
  [COLUMN_CELL_TYPES.LONGTEXT, COLUMN_CELL_TYPES.MULTISELECT].includes(fieldTypeName) ||
  (fieldTypeName === COLUMN_CELL_TYPES.SELECT && ![FIELD_TYPE.assignee, FIELD_TYPE.priority, FIELD_TYPE.status].includes(column.colId));
  return (
    <CellComponentWrapper data-field-type={fieldTypeName} fullWidth={isFullWidth} isSelector={isSelector && isFullWidth}>
      {getCellComponent(fieldTypeName)}
    </CellComponentWrapper>
  );
});

export default CellComponent;
