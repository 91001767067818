import { memo, useCallback, useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';

import { PlaceholderValues } from 'common/utils/constants';

import { ReactComponent as ShowPasswordIcon } from 'images/icons/passwordEyeShow.svg';
import { ReactComponent as HidePasswordIcon } from 'images/icons/passwordEyeHide.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/errorIcon.svg';

import { StyledPasswordField } from '../styles';

const PasswordField = ({
   name,
   value,
   placeholder = PlaceholderValues.NotAvailable,
   type = 'password',
   InputProps,
   handleChange,
   handleBlur,
   error,
   ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleTogglePasswordVisibility = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const EndAdornment = (
    <InputAdornment position="end">
      {error && !focused ? (
        <IconButton disableRipple edge="end">
          <ErrorIcon />
        </IconButton>
      ) : (
        <IconButton
          data-testid="toggle-password-visibility"
          disableRipple
          edge="end"
          onMouseDown={(e) => e.preventDefault()}
          onMouseUp={handleTogglePasswordVisibility}
          onTouchEnd={handleTogglePasswordVisibility}
        >
          {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </IconButton>
      )}
    </InputAdornment>
  );

  const handleInputChange = useCallback((e) => {
    const { value } = e.target;

    handleChange?.(value, name);
  }, [handleChange, name]);

  const handleInputBlur = useCallback((e) => {
    setFocused(false);
    handleBlur?.(e.target.value, name);
  }, [handleBlur, name]);

  const handleInputFocus = useCallback(() => {
    setFocused(true);
  }, []);

  return (
    <StyledPasswordField
      id={`${name}-input`}
      placeholder={placeholder}
      value={value}
      error={error}
      focused={focused}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      name={name}
      autoComplete="off"
      type={showPassword ? 'text' : type}
      InputProps={{
        endAdornment: EndAdornment,
        ...InputProps,
        'data-testid': `${name}-input`,
      }}
      {...restProps}
    />
  );
};

export default memo(PasswordField);
