import { useCallback, useEffect } from 'react';
import MessagePrompt from 'common/components/messagePrompt';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import useTexts from './useTexts';
import InputWithCounter from '../components/inputWithCounter/inputWithCounter';
import PrivacySettings from '../components/privacySettings/privacySettings';
import {
  MAX_VIEW_NAME_LENGTH,
  PRIVATE_VISIBILITY_VALUE,
  PUBLIC_VISIBILITY_VALUE,
  VIEW_NAME_KEY,
  VISIBILITY_FOR_GROUPS_KEY,
  VISIBILITY_KEY,
} from './constants';
import { isArchiveQueueView } from '../../../../features/queue/utils';

function SaveViewAsModal({
  isDetailsPopUpOpen,
  view,
  open,
  onClose,
  onSaveClicked,
  onSaveDetailsClicked,
  setShowUniqueNameErrorText,
  uniqueNameError,
}) {
  const {
    popupTitle,
    viewNameLabel,
    popupOkBtnText,
    popupCancelBtnText,
    viewDetailsPopUpTitle,
    popupViewNameErrorMessage,
    uniqueNameErrorText,
  } = useTexts();
  const { data: userData } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);

  const title = isDetailsPopUpOpen ? viewDetailsPopUpTitle : popupTitle;
  const schema = yup.object().shape({
    [VIEW_NAME_KEY]: yup.string().required(popupViewNameErrorMessage).max(MAX_VIEW_NAME_LENGTH),
  });

  const controller = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [VIEW_NAME_KEY]: '',
      [VISIBILITY_KEY]: PRIVATE_VISIBILITY_VALUE,
      [VISIBILITY_FOR_GROUPS_KEY]: [],
    },
    reValidateMode: 'all',
  });
  const { setValue, reset, getValues } = controller;
  useEffect(() => {
    if (uniqueNameError) {
      controller.control.setError(VIEW_NAME_KEY, {
        message: uniqueNameErrorText,
      });
    } else {
      controller.clearErrors(VIEW_NAME_KEY);
    }
  }, [uniqueNameError, uniqueNameErrorText, controller.control, controller, setShowUniqueNameErrorText]);

  useEffect(() => {
    const visibilityData = {
      [VISIBILITY_KEY]:
        !userData.isSysAidAdmin && !isDetailsPopUpOpen ? PRIVATE_VISIBILITY_VALUE : view[VISIBILITY_KEY],
      [VISIBILITY_FOR_GROUPS_KEY]:
        view[VISIBILITY_KEY] === PUBLIC_VISIBILITY_VALUE ? JSON.parse(view[VISIBILITY_FOR_GROUPS_KEY] || '[]') : null,
    };
    if (isDetailsPopUpOpen) {
      reset({
        [VIEW_NAME_KEY]: view?.name,
        ...visibilityData,
      });
    } else {
      reset({
        [VIEW_NAME_KEY]: `${view?.name} - copy`,
        ...visibilityData,
      });
    }
  }, [isDetailsPopUpOpen, reset, view, userData.isSysAidAdmin]);

  const getDataToSave = () => {
    const updatedData = getValues();
    if (updatedData.visibility === PRIVATE_VISIBILITY_VALUE) {
      updatedData.visibleForGroupIds = null;
    }
    if (updatedData.visibility === PUBLIC_VISIBILITY_VALUE) {
      updatedData.visibleForGroupIds = JSON.stringify(updatedData.visibleForGroupIds || []);
    }
    return updatedData;
  };

  const handleOkClickInPrompt = () => {
    const dataToSave = getDataToSave();
    if (isDetailsPopUpOpen) onSaveDetailsClicked(dataToSave);
    else onSaveClicked(dataToSave);
  };

  const modalStyle = {
    '.MuiDialog-container': {
      alignItems: 'flex-start',
    },
    'div.MuiPaper-root': {
      width: '630px',
      maxWidth: '70%',
      marginTop: '48px',
      padding: '28px 24px 24px',
      overflow: 'visible ',
      '.propmt-wrapper': {
        display: 'block',
      },
      '& h2.MuiDialogTitle-root': {
        fontFamily: 'Roboto-Medium',
        lineHeight: 'normal',
        padding: '10px 8px !important',
      },
      '.MuiTextField-root': {
        backgroundColor: 'transparent',
        marginTop: '0',
        height: 'auto',
        width: '100%',
      },
      '& .MuiInputBase-root': {
        width: '100%',
        padding: 0,
      },
    },
  };

  const handleChangeValue = useCallback(
    (key, value) => {
      if (key === VISIBILITY_KEY && value === PUBLIC_VISIBILITY_VALUE && !getValues(VISIBILITY_FOR_GROUPS_KEY)) {
        setValue(VISIBILITY_FOR_GROUPS_KEY, []);
      }
      setValue(key, value);
    },
    [setValue, getValues],
  );

  return (
    <MessagePrompt
      style={modalStyle}
      open={open}
      strippedDesign
      onOkClick={handleOkClickInPrompt}
      showCancelBtn
      showIcon={false}
      onClose={() => {
        onClose();
      }}
      title={title}
      btnOkText={popupOkBtnText}
      btnCancelText={popupCancelBtnText}
      isOkButtonDisable={!controller.getValues(VIEW_NAME_KEY)}
    >
      <InputWithCounter
        label={viewNameLabel}
        controller={controller}
        inputKey={VIEW_NAME_KEY}
        max={MAX_VIEW_NAME_LENGTH}
      />
      {userData.isSysAidAdmin && (
        <PrivacySettings
          controller={controller}
          handleChangeValue={handleChangeValue}
          isArchiveView={isArchiveQueueView(view)}
        />
      )}
    </MessagePrompt>
  );
}

export default SaveViewAsModal;
