import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSeasonalPage = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: 'white',
  //-----------------------------------------------
  '.seasonal-content-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    gap: '3em',
  },
  '.seasonal-image': {
    width: '100%',
    height: 'auto',
  },
  '.seasonal-text-wrapper': {
    color: theme.palette.text.primary,
    padding: '0 16px',
    margin: '0',
    fontSize: 16,
    lineHeight: '23.234px',
    fontWeight: 400,
    fontFamily: 'Roboto-Regular',
    //-----------------------------------------------
    p: {
      margin: '0',
    },
    '.seasonal-title': {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '120%',
      marginBottom: '0.4em',
    },
  },
}));
